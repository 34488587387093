import './style.scss';

import { Article, useDeleteArticleMutation } from "./slice";

import { ArticleForm } from "./form";
import DeleteButton from "../common/confirmationDeleteButton";
import OpenFormButton from "../common/openFormButton";
import TagText from '../common/tagtext';
import Tags from "../common/tags";
import { User } from '../users/slice';
import { getURLForFile } from "../helper";
import { useAppSelector } from "../hooks";
import { useHistory } from "react-router-dom";

const ArticlesList = (props: { articles: Array<Article> }) => {
    const router = useHistory();
    const workspace_slug = useAppSelector((state) => state.app.workspace_slug);
    const [deleteArticle] = useDeleteArticleMutation();
    const currentUser: User = useAppSelector((state) => state.authentication.currentUser);

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.isDefaultPrevented()) return
        router.push(`/workspace/${workspace_slug}/article/${props.articles[0]?.id}`)
    }


    return <div className={'articles-list-wrapper'}>
        {props.articles &&
            <div className={'articles-list'}>
                {props.articles[0] &&
                    <div className='articles-left article-item'>
                        {
                            props.articles[0].visual && (typeof props.articles[0].visual !== "string") ?
                                <img src={getURLForFile(props.articles[0].visual.file)} alt="visual" /> :
                                <img src="/articles.jpg?v=1" alt="visual" />
                        }
                        <div className='article-details' onClick={handleClick}>
                            <div className="tag-wrapper">
                                {
                                    props.articles[0].generic_groups.length === 1 ?
                                        <Tags key={props.articles[0].generic_groups[0]} groupId={props.articles[0].generic_groups[0]} />
                                        : null
                                }
                                {
                                    props.articles[0].generic_groups.length > 1 ?
                                        <TagText text={`${props.articles[0].generic_groups.length} thèmes séléctionnés`} />
                                        : null
                                }
                            </div>
                            <div>
                            </div>
                            <div style={{ backgroundColor: 'rgba(255,255,255,0.85)', 'padding': '10px' }}>
                                <div className="title">{props.articles[0]?.name}</div><br />
                                <div className="description">{props.articles[0]?.description}</div>
                            </div>
                        </div>
                        <div className='relative-open-form-btn action-buttons'>
                            <OpenFormButton superuserOnly={true} action="update" model="article" header={'Éditer un article'} form={<ArticleForm article={props.articles[0]} closeModal={() => null} step={null} />} />
                            {currentUser.is_superuser ?
                                <DeleteButton model="article" deleteFunction={() => deleteArticle(props.articles[0].id)} />
                                : null
                            }
                        </div>
                    </div>
                }
                <div className='articles-right'>
                    {props.articles[1] &&
                        <div className='article-item'>
                            <div className='article-details' onClick={() => router.push(`/workspace/${workspace_slug}/article/${props.articles[1]?.id}`)}>
                                <div className="image-article-visu" style={{
                                    'backgroundImage':
                                        (props.articles[1].visual && (typeof props.articles[1].visual !== "string")) ?
                                            `url(${getURLForFile(props.articles[1].visual.file)}` :
                                            "url(/articles.jpg?v=1)",
                                }} />
                                <div className='article-content'>
                                    <div className="tag-wrapper">
                                        {
                                            props.articles[1].generic_groups.length === 1 ?
                                                <Tags key={props.articles[1].generic_groups[0]} groupId={props.articles[1].generic_groups[0]} />
                                                : null
                                        }
                                        {
                                            props.articles[1].generic_groups.length > 1 ?
                                                <TagText text={`${props.articles[1].generic_groups.length} thèmes séléctionnés`} />
                                                : null
                                        }
                                    </div>
                                    <div className=' article-title title'>
                                        {props.articles[1].name}
                                    </div>
                                    <div className='description'>
                                        {props.articles[1].description.substring(0, props.articles[1].description.substring(0, 50).lastIndexOf(' '))}
                                        {props.articles[1].description.substring(0, props.articles[1].description.substring(0, 50).lastIndexOf(' ')).length < props.articles[1].description.length ? '...' : ''}
                                    </div>
                                </div>
                            </div>
                            <div className='relative-open-form-btn action-buttons'>
                                <OpenFormButton superuserOnly={true} action="update" model="article" header={'Éditer un article'} form={<ArticleForm article={props.articles[1]} closeModal={() => null} step={null} />} />
                                {currentUser.is_superuser ?
                                    <DeleteButton model="article" deleteFunction={() => deleteArticle(props.articles[1].id)} />
                                    : null
                                }
                            </div>
                        </div>
                    }
                    {props.articles[2] &&
                        <div className='article-item'>
                            <div className='article-details' onClick={() => router.push(`/workspace/${workspace_slug}/article/${props.articles[2]?.id}`)}>
                                <div className="image-article-visu" style={{
                                    'backgroundImage':
                                        (props.articles[2].visual && (typeof props.articles[2].visual !== "string")) ?
                                            `url(${getURLForFile(props.articles[2].visual.file)}` :
                                            "url(/articles.jpg?v=1)",
                                }} />
                                <div className='article-content'>
                                    <div className="tag-wrapper">
                                        {
                                            props.articles[2].generic_groups.length === 1 ?
                                                <Tags key={props.articles[2].generic_groups[0]} groupId={props.articles[2].generic_groups[0]} />
                                                : null
                                        }
                                        {
                                            props.articles[2].generic_groups.length > 1 ?
                                                <TagText text={`${props.articles[2].generic_groups.length} thèmes séléctionnés`} />
                                                : null
                                        }
                                    </div>
                                    <div className=' article-title title'>
                                        {props.articles[2].name}
                                    </div>
                                    <div className='description'>
                                        {props.articles[2].description.substring(0, props.articles[2].description.substring(0, 50).lastIndexOf(' '))}
                                        {props.articles[2].description.substring(0, props.articles[2].description.substring(0, 50).lastIndexOf(' ')).length < props.articles[2].description.length ? '...' : ''}
                                    </div>
                                </div>
                            </div>
                            <div className='relative-open-form-btn action-buttons'>
                                <OpenFormButton superuserOnly={true} action="update" model="article" header={'Éditer un article'} form={<ArticleForm article={props.articles[2]} closeModal={() => null} step={null} />} />
                                {currentUser.is_superuser ?
                                    <DeleteButton model="article" deleteFunction={() => deleteArticle(props.articles[2].id)} />
                                    : null
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
    </div>
}

export default ArticlesList;
