import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Partner, useGetPartnersQuery } from "../../partners/slice";
import { User, UserFunctionWorkspace, useGetUsersQuery } from "../../users/slice";
import { UserFunction, useGetUserFunctionsQuery } from "../../user_functions/slice";
import { Workspace, useGetWorkspacesQuery, useUpdateWorkspaceMutation } from "../slice";
import { faCalendarDay, faEllipsisV, faHandshake, faUser } from "@fortawesome/free-solid-svg-icons";

import ActionsOverlay from './ActionsOverlay';
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from "primereact/dropdown";
import { DuplicateWorkspaceForm } from '../form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayPanel } from 'primereact/overlaypanel';
import WorkspaceForm from '../projectForm';
import { addNotification } from "../../notifications/slice";
import { confirmDialog } from 'primereact/confirmdialog';
import dayjs from 'dayjs';
import { getURLForFile } from "../../helper";
import monsterImg from '../../assets/projectslogo.png';
import { setWorkspaceInEdition } from "../slice";
import { useAppSelector } from "../../hooks";
import { useDispatch } from "react-redux";

const WorkspaceList: FunctionComponent<{}> = () => {
    const dispatch = useDispatch();

    const [showForm, setShowForm] = useState<boolean>(false);
    const { data: workspaces, error: errorGetWorkspace, refetch } = useGetWorkspacesQuery();
    const { data: partners } = useGetPartnersQuery();
    const { data: userFunctions } = useGetUserFunctionsQuery();
    const currentUser = useAppSelector(state => state.authentication.currentUser)
    const { data: users } = useGetUsersQuery();
    const [isVisible, setIsVisible] = useState(false);
    const [originWs, setoriginWs] = useState<Workspace | undefined>(undefined);
    const [partnerFilter, setPrestaFilter] = useState<string>("");
    const [showArchived, setShowArchived] = useState<boolean>(false);

    const [updateWorkspace] = useUpdateWorkspaceMutation();

    // Update alert
    useEffect(() => {
        if (errorGetWorkspace) {
            dispatch(addNotification({
                type: 'error',
                message: 'Cannot get projects'
            }));
        }
    }, [errorGetWorkspace, dispatch]);


    const createNew = () => {
        dispatch(setWorkspaceInEdition(null));
        setShowForm(true);
    }

    const showEditWorkspace = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, ws: Workspace) => {
        event.stopPropagation();
        dispatch(setWorkspaceInEdition(ws));
        setShowForm(true);
    };

    const showDuplicateWorkspace = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, ws: Workspace) => {
        event.stopPropagation();
        setoriginWs(ws);
        setIsVisible(true)
    };

    const getIcon = (ws: Workspace) => {
        let icon = monsterImg;
        if (ws?.icon && typeof ws.icon !== 'string') {
            icon = getURLForFile(ws.icon.file);
        }
        return icon;
    }

    const showUnarchivedConfirmation = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, ws: Workspace) => {
        event.stopPropagation();
        confirmDialog({
            message: `Etes vous sur de vouloir désarchiver le projet ${ws.name}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Oui',
            rejectLabel: 'Non',
            accept: () => {
                const uploadedIcon: any = ws.icon; //hacky, have to do this because icon can have different shape
                const ws_updated = Object.assign({}, ws);
                ws_updated.icon = uploadedIcon?.id;
                ws_updated.archived = false;
                updateWorkspace(ws_updated);
            },
            reject: () => { }
        });
    }

    const showArchivedConfirmation = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, ws: Workspace) => {
        event.stopPropagation();
        confirmDialog({
            message: `Etes vous sur de vouloir archiver le projet ${ws.name}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Oui',
            rejectLabel: 'Non',
            accept: () => {
                const uploadedIcon: any = ws.icon; //hacky, have to do this because icon can have different shape
                const ws_updated = Object.assign({}, ws);
                ws_updated.icon = uploadedIcon?.id;
                ws_updated.archived = true;
                updateWorkspace(ws_updated);
            },
            reject: () => { }
        });
    }

    const cdpRight = userFunctions?.results.find((f: UserFunction) => f.label === 'Chef de projet');
    const WorkspaceCard: React.FC<{ workspace: Workspace }> = props => {
        const op = useRef<OverlayPanel>(null);
        const wsUser: User = users.results?.find((u: User) => {
            if (!u.has_overmind_access) return false;
            // if (u.partner !== props.workspace.partner) return false;
            if (u.user_function_workspaces?.filter((urf: UserFunctionWorkspace) => {
                return (urf.workspace === props.workspace.slug) &&
                    (urf.function === cdpRight?.id)
            }).length > 0) return true;
            return false;
        })
        const wsPartner: Partner | undefined = partners?.results.find((p: Partner) => p.id === props.workspace.partner)

        return <div onClick={() => window.location.href = (`${window.location.protocol}//${window.location.host}/workspace/${props.workspace.slug}`)} className={`${props.workspace.archived ? 'archived' : ''} grid-container workspace-grid-card p-shadow-4`}>
            <div>
                <img src={getIcon(props.workspace)} alt="workspace" />
            </div>
            <div className="ws-info">
                <div className="title">
                    {
                        (
                            currentUser.is_superuser ||
                            currentUser.has_overmind_access
                        ) ?
                            <>
                                <div className="menu" onClick={(e) => { e.stopPropagation(); op.current?.toggle(e); }}>
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                </div>
                                <ActionsOverlay
                                    currentUser={currentUser}
                                    reff={op}
                                    workspace={props.workspace}
                                    onEdit={(e) => showEditWorkspace(e, props.workspace)}
                                    onDuplicate={(e) => showDuplicateWorkspace(e, props.workspace)}
                                    onArchived={(e) => showArchivedConfirmation(e, props.workspace)}
                                    onUnarchived={(e) => showUnarchivedConfirmation(e, props.workspace)}
                                />
                            </> : null
                    }

                    <span>{props.workspace.name}</span>
                </div>
                <div className="ws-text-wrapper">
                    <div className="info">
                        <FontAwesomeIcon icon={faCalendarDay} />
                        {props.workspace.informations?.start_date && dayjs(props.workspace.informations.start_date).format('MMM YY')}
                    </div>
                    <div className="info">
                        <FontAwesomeIcon icon={faUser} />{wsUser ? wsUser.first_name + " " + wsUser.last_name : ''}
                    </div>
                    <div className="info">
                        <FontAwesomeIcon icon={faHandshake} />{wsPartner && wsPartner.name}
                    </div>
                    <div className="info-dull p-mb-3">Crée {dayjs(props.workspace.created).format('DD/MM/YYYY')}</div>
                </div>
            </div >
        </div >;
    };

    return <div className="workspace-wrapper">
        <div className="workspace-cards">
            <div className="workspace-title">
                <div>Liste des projets</div>
                <div className="filter-list">
                    <div className="flex align-items-center">
                        <Checkbox inputId="showArchived" name="pizza" value="showArchived" onChange={() => setShowArchived(!showArchived)} checked={!showArchived} />
                        <label htmlFor="showArchived" className="ml-2">Cacher les projets archivés</label>
                    </div>
                    {currentUser.is_superuser ?
                        <Dropdown
                            value={partnerFilter}
                            onChange={e => setPrestaFilter(e.target.value)}
                            options={partners?.results.map(p => {
                                return {
                                    'label': p.name,
                                    'value': p.id
                                }
                            })}
                            placeholder="Filtrer par partenaire"
                            showClear={!!partnerFilter}
                        /> : null}
                </div>
            </div>
            <WorkspaceForm show={showForm} onClose={() => setShowForm(false)} />
            <Dialog style={{ minWidth: '40vw' }} header={'Dupliquer un projet'} visible={isVisible} draggable={false} onHide={() => { setIsVisible(false); }} >
                <DuplicateWorkspaceForm refetchWorkspaces={refetch} wsOrigin={originWs} closeModal={() => setIsVisible(false)} />
            </Dialog>
            <div className="p-grid">
                {workspaces?.results.filter((w: Workspace) => {
                    if (!showArchived && w.archived) return false;
                    if (!partnerFilter) return true;
                    return w.partner === partnerFilter;
                }).map((w: Workspace) => {
                    return <div key={w.slug} className={'p-col-12 p-md-6 p-lg-3'}>
                        <WorkspaceCard key={w.slug} workspace={w} />
                    </div>
                })}
            </div>
            {currentUser.is_superuser && <Button className="createNew p-button-rounded p-button-lg" icon="pi pi-plus" onClick={createNew} />}
        </div>
    </div>;
}

export default WorkspaceList;
