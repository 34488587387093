import './style.scss';

import { TabPanel, TabView } from 'primereact/tabview';
import { User, useGetUsersQuery } from './slice';

import { Button } from 'primereact/button';
import UserTable from './userTable';
import { useHistory } from "react-router";
import { useState } from 'react';

const UsersList = ()  => {
    const router = useHistory();
    const [activeIndex, setActiveIndex] = useState(0);
    const { data: users } = useGetUsersQuery();


    return <div style={{width: '100%', backgroundColor: '#F1F1F1', minHeight:'100%'}}>
        <div className='back-arrow'>
            <Button style={{padding:0, borderRadius:'0px'}} className="p-button-link" onClick={() => router.goBack()}>
                <i style={{padding:'12px', marginRight:'14px', borderRadius:'0px', fontSize:'12px' ,backgroundColor:'var(--primary-color)', color:'white'}} className='pi pi-arrow-left'></i>&nbsp;
                <span style={{fontWeight: '100'}}>Retour au projet</span>
            </Button>
        </div>
        <div style={{width: '100%', padding: '54px 64px 64px 64px'}}>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Équipe partenaire">
                    <UserTable users={users?.results.filter((user: User) => user.has_overmind_access)} />
                </TabPanel>
            </TabView>
        </div>
    </div>
}

export default UsersList;
