import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
// Redux
import { ready, setWorkspace } from './slice';
// Tooling
import { useAppDispatch, useAppSelector } from '../hooks';

import AcceptInvitation from '../authentication/acceptInvitation';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ConfirmPopup } from 'primereact/confirmpopup';
// Components
import LoginForm from '../authentication';
import NotificationManager from '../notifications';
import Overmind from '../overmind';
import PermissionCheck from '../permissions/permissionCheck';
// Libs
import { ProgressSpinner } from "primereact/progressspinner";
import ResetPasswordForm from '../authentication/resetpassword';
import WorkspaceHome from '../workspaces/home';
import axios from 'axios';
import { getAPIUrl } from '../helper';
import logo from '../assets/logo.png';
import { useLocation } from 'react-router';

const App: React.FC = props => {
    const dispatch = useAppDispatch();
    // isReady is redux ready and api url set
    const isReady = useAppSelector((state) => state.app.isReady);
    const { JWTaccess } = useAppSelector(state => state.authentication);
    const location = useLocation();

    // first initialisation step : set axios and defined workspace
    useEffect(() => {
        const pathname = location.pathname;
        const re = new RegExp('/workspace/([^/]+)');
        const result = pathname.match(re);
        const workspace = result ? result[1] : null;
        axios.defaults.baseURL = getAPIUrl();
        dispatch(setWorkspace(workspace));
        dispatch(ready());
    }, [dispatch, location.pathname]);

    const LoadingPage =
        <div className="app-loading">
            <img src={logo} alt="Logo" />
            <ProgressSpinner />
        </div>;

    if (!isReady) {
        return LoadingPage
    }

    return <>
        <NotificationManager key="notification-manager" />
        {!JWTaccess ?
            <Switch>
                <Route path="/change_password">
                    <ResetPasswordForm />
                </Route>
                <Route path="/accept_invitation">
                    <AcceptInvitation/>
                </Route>
                <Route path="/">
                    <LoginForm logo={logo} />
                </Route>
            </Switch>
            :
            <PermissionCheck>
                <Switch>
                    <Route path="/workspace/:workspace_slug"
                        children={<WorkspaceHome />} />
                    <Route path="/" children={<Overmind />} />
                </Switch>
                <ConfirmPopup />
                <ConfirmDialog acceptLabel="Oui" rejectLabel="Non" />
            </PermissionCheck>
        }
    </>
}

export default App;
