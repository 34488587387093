import './style.scss'

import { Article, useGetArticlesQuery } from '../articles/slice';
import { Document, useGetDocumentsQuery } from '../documents/slice';
import { DocumentType, useGetDocumentTypesQuery } from '../documents/typesSlice';
import { Event, useGetEventsQuery } from '../events/slice';
import { Intern, useGetInternsQuery } from '../interns/slice';
import { Note, useGetNotesQuery } from '../notes/slice';
import { Record, useGetRecordsQuery } from '../records/slice';
import { Task, useGetTasksQuery } from '../tasks/slice';
import { User, useGetUsersQuery } from '../users/slice';
import { useEffect, useState } from 'react';
import { useGetStepQuery, useGetStepsQuery } from '../steps/slice';

import { ArticleForm } from '../articles/form';
import ArticlesList from '../articles/list';
import { Button } from 'primereact/button';
import { CanChange } from '../helper';
import { DocumentTypeForm } from '../documents/typeForm';
import { EventForm } from '../events/form';
import EventsList from '../events/list';
import GEDDocumentsList from '../documents/filelist';
import { InternForm } from '../interns/form';
import InternsList from '../interns/list';
import { NoteForm } from '../notes/form';
import NotesList from '../notes/list';
import OpenFormButton from '../common/openFormButton';
import RecordLogs from '../common/recordsLogs';
import StepColumn from './column';
import { TaskForm } from '../tasks/form';
import TasksList from '../tasks/list';
import { useAppSelector } from '../hooks';
import { useGetPhasesQuery } from '../phases/slice';
import { useGetRolesQuery } from '../permissions/slice';
import { useHistory } from 'react-router-dom';

const Step = ({ id }: any) => {
    const { data: step } = useGetStepQuery(id?.match.params.id);
    const { data: steps } = useGetStepsQuery();
    const { data: phases } = useGetPhasesQuery();
    const { data: articles } = useGetArticlesQuery();
    const { data: documents } = useGetDocumentsQuery();
    const { data: documentTypes } = useGetDocumentTypesQuery();
    const { data: tasks } = useGetTasksQuery();
    const { data: notes } = useGetNotesQuery();
    const { data: events } = useGetEventsQuery();
    const { data: interns } = useGetInternsQuery();
    const { data: roles } = useGetRolesQuery();
    const { data: records } = useGetRecordsQuery();
    const { data: users } = useGetUsersQuery();

    const [filteredTasks, setFilteredTasks] = useState([]);
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const [filteredDropTypes, setFilteredDropTypes] = useState([]);
    const [filteredGEDTypes, setFilteredGEDTypes] = useState([]);
    const [filteredNotes, setFilteredNotes] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [filteredInterns, setFilteredInterns] = useState([]);

    const workspace_slug = useAppSelector((state) => state.app.workspace_slug);
    const currentUser: User = useAppSelector((state) => state.authentication.currentUser);
    const router = useHistory();

    useEffect(() => {
        setFilteredTasks(tasks?.results.filter((el: Task) => el.step === step?.id));
        setFilteredNotes(notes?.results.filter((el: Note) => el.step === step?.id));
        setFilteredArticles(articles?.results.filter((el: Article) => el.step === step?.id));
        setFilteredEvents(events?.results.filter((el: Event) => el.step === step?.id));
        setFilteredDocuments(documents?.results.filter((el: Document) => el.step === step?.id));
        setFilteredDropTypes(documentTypes?.results.filter((el: DocumentType) => el.step === step?.id && !el.is_ged));
        setFilteredGEDTypes(documentTypes?.results.filter((el: DocumentType) => el.step === step?.id && el.is_ged));
        setFilteredInterns(interns?.results.filter((el: Intern) => el.step === step?.id));
    }, [tasks, documents, articles, notes, events, step, documentTypes, interns]);

    const colors = ['#B1B1B1', '#FDAE19', '#1DAEB0', '#8CD483', '#6578DA', '#1DAEB0'];

    return <div className="workspace step-index">
        <div className="left-content">
            <div className='workspace-banner'>
                {/* <img src='/head.png' alt="head" /> */}
                <div className="desc">
                    <div className="blocky" style={{ backgroundColor: steps?.results.length < 7 ? colors[step?.code] : step?.color }}>
                        <div className="number">{step?.code}</div>
                    </div>
                    <h1> {step?.name}</h1>
                    <p>
                        {step?.description}
                    </p>
                </div>
            </div>
            <div className="p-shadow-4" style={{ padding: '32px 64px', position: 'relative' }}>
                <ArticlesList articles={filteredArticles} />
                {filteredArticles?.length < 3 &&
                    currentUser.is_superuser && <div className="admin_actions">
                        <OpenFormButton showLabel className="in-dom" model="article" action="create" minWidth='90vw' header={'Ajouter un article'} form={<ArticleForm article={null} closeModal={() => null} step={step?.id} />} />
                    </div>
                }
            </div>
            {([0, 1, 2].includes(step?.code) && (currentUser.is_superuser || filteredDropTypes?.length > 0)) ?
                <div className='p-shadow-4' style={{ padding: '32px 64px', position: 'relative' }}>
                    <GEDDocumentsList isDropArea={true} documents={filteredDocuments} documentTypes={filteredDropTypes} step={step?.id} />
                    {currentUser.is_superuser && <div className="admin_actions">
                        <OpenFormButton showLabel className='in-dom drop-area-add-button' action="create" model="documenttype" header={'Ajouter un type de document'} form={<DocumentTypeForm isGed={false} type={null} closeModal={() => null} step={step?.id} />} />
                    </div>}
                </div>
                : null}
            {(currentUser.is_superuser || filteredGEDTypes?.length > 0) ?
                <div className="p-shadow-4" style={{ padding: '32px 64px', position: 'relative' }}>
                    <GEDDocumentsList isDropArea={false} documents={filteredDocuments} documentTypes={filteredGEDTypes} step={step?.id} />
                    {currentUser.is_superuser && <div className="admin_actions">
                        <OpenFormButton showLabel className='in-dom' action="create" model="documenttype" header={'Ajouter un type de document'} form={<DocumentTypeForm isGed={true} type={null} closeModal={() => null} step={step?.id} />} />
                    </div>}
                </div> : null
            }
            <div className='p-shadow-4' style={{ padding: '32px 64px', position: 'relative' }}>
                <EventsList events={filteredEvents} />
                <div className="admin_actions">
                    <OpenFormButton action="create" showLabel className='in-dom' model="event" header={'Ajouter un événement'} form={<EventForm event={null} closeModal={() => null} step={step?.id} />} />
                </div>
            </div>
            {[4, 7, 10].includes(step?.code) &&
                <div className='p-shadow-4' style={{ padding: '32px 64px', position: 'relative' }}>
                    <InternsList interns={filteredInterns} />
                    <div className="admin_actions">
                        <OpenFormButton action="create" showLabel className='in-dom' model="intern" header={'Ajouter un stagiaire'} form={<InternForm intern={null} closeModal={() => null} step={step?.id} />} />
                    </div>
                </div>
            }
            <div className='p-grid'>
                <div className='p-col-6'>
                    <div style={{ padding: '32px 32px 32px 64px', position: 'relative' }}>
                        <TasksList tasks={filteredTasks} />
                        <div className="admin_actions">
                            <OpenFormButton showLabel className='in-dom' action="create" model="task" header={'Ajouter une tâche'} form={<TaskForm task={null} closeModal={() => null} step={step?.id} />} />
                        </div>
                    </div>
                </div>
                <div className='p-col-6'>
                    <div style={{ padding: '32px 32px 32px 64px', position: 'relative' }}>
                        <NotesList notes={filteredNotes} />
                        <div className="admin_actions">
                            <OpenFormButton showLabel className="in-dom" model="note" action="create" header={'Ajouter une note'} form={<NoteForm note={null} closeModal={() => null} step={step?.id} />} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='right-content' style={{ backgroundColor: steps?.results.length < 7 ? colors[step?.code] : step?.color }}>
            <StepColumn phases={phases?.results} editable={CanChange(currentUser, roles?.results, workspace_slug, 'update', 'step')} currentStep={step} steps={steps?.results} />
            {
                step?.code === 0 &&
                <div style={{ padding: '8px 32px' }}>
                    <h2 className='title' style={{ fontWeight: '400', fontSize: '16px' }}>Historique de connexion<i style={{ transform: 'translate(2px, 2px)' }} className="pi pi-clock" /></h2>
                    <RecordLogs users={users.results} logs={records?.results.filter((r: Record) => r.action === 'login') || []} />
                </div>
            }
            {
                (currentUser.is_superuser || currentUser.partner) &&
                <div style={{ padding: '32px' }}>
                    <div style={{ marginBottom: '8px' }}>
                        <Button onClick={() => router.push(`/workspace/${workspace_slug}/users`)} label="Consulter l'équipe partenaire" className="p-button-outlined" />
                    </div>
                    {/* <div style={{ marginBottom: '8px' }}>
                    <Button onClick={() => router.push(`/workspace/${workspace_slug}/users`)} label="Consulter l'équipe client" className="p-button-outlined" />
                </div> */}
                </div>
            }
            {/* <Links onlyFAQ={!currentUser.is_superuser && !currentUser.has_overmind_access} /> */}
        </div>
    </div>;
}

export default Step;