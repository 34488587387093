import { File } from '../files/slice';
import { Workspace } from '../workspaces/slice';
import { createSlice } from '@reduxjs/toolkit';
import { sliceFactory } from '../factory/slice';

export interface Intern {
    created: Date,
    step: string,
    name: string,
    firstname: string,
    comment: string,
    id: string,
    function: string,
    phone: string,
    email: string,
    avatar: string | File,
    modified: Date,
    workspace: Workspace | null
}

export interface InternState {
    internInEdition: Intern | null
}

export const initialState: InternState = {
    internInEdition: null
}

export const internSlice = createSlice({
    name: 'intern',
    initialState,
    reducers: {
        setInternInEdition: (state, payload:{payload:Intern|null}) => {
            state.internInEdition = payload.payload;
        }
    }
});

export const internAPISlice = sliceFactory<Intern>({
    reducerName: 'internAPI',
    endpoint: '/interns/',
    name: 'Intern'
});


export default internSlice.reducer;
export const { setInternInEdition } = internSlice.actions

export const {
    useGetInternsQuery,
    useGetInternQuery,
    useDeleteInternMutation,
    useUpdateInternMutation,
    useCreateInternMutation,
} = internAPISlice