import { Chip } from 'primereact/chip';
import { useGetGenericGroupQuery } from "../generic_groups/slice";

const Tags = (props: {groupId: string}) => {
    const { data: tag } = useGetGenericGroupQuery(props.groupId);

    return tag ? <div className='tag-content'>
        <Chip label={tag.label} style={{backgroundColor: '#DFF6FD', borderRadius: '0px'}}/>
    </div>
    :<div></div>
}

export default Tags;