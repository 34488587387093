import { Button } from "primereact/button";
import { CanChange } from "../helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmPopup } from 'primereact/confirmpopup';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../hooks";
import { useGetRolesQuery } from "../permissions/slice";

const DeleteButton = (props: { showLabel?:boolean, deleteFunction: Function, model: string }) => {
    const workspace_slug = useAppSelector((state) => state.app.workspace_slug);
    const currentUser = useAppSelector((state) => state.authentication.currentUser);
    const { data: roles } = useGetRolesQuery();

    if (!CanChange(currentUser, roles?.results, workspace_slug, "delete", props.model)) return <></>

    const accept = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        props.deleteFunction(e);
    };

    const reject = () => {
    };

    const confirm = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Voulez-vous vraiment continuer ? ',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept(event),
            reject,
            rejectLabel: 'Non',
            acceptLabel: 'Oui'
        });
        event.stopPropagation();
        event.preventDefault();
    };

    return <>
        <Button onClick={confirm}>
            <FontAwesomeIcon icon={faTrash} />{props.showLabel ? <>&nbsp;&nbsp;Supprimer</>:null}
        </Button>
    </>
}

export default DeleteButton;