import { Workspace } from '../workspaces/slice';
import { createSlice } from '@reduxjs/toolkit';
import { sliceFactory } from '../factory/slice';

export interface Event {
    created: Date,
    event_date: Date,
    weight: number,
    step: string,
    name: string,
    description: string,
    id: string,
    user: string,
    admin: string,
    modified: Date,
    disabled: boolean,
    hidden: boolean,
    generic_groups: string[],
    workspace: Workspace | null
}

export interface EventState {
    eventInEdition: Event | null
}

export const initialState: EventState = {
    eventInEdition: null
}

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        setEventInEdition: (state, payload:{payload:Event|null}) => {
            state.eventInEdition = payload.payload;
        }
    }
});

export const eventAPISlice = sliceFactory<Event>({
    reducerName: 'eventAPI',
    endpoint: '/events/',
    name: 'Event'
});


export default eventSlice.reducer;
export const { setEventInEdition } = eventSlice.actions

export const {
    useGetEventsQuery,
    useGetEventQuery,
    useDeleteEventMutation,
    useUpdateEventMutation,
    useCreateEventMutation,
} = eventAPISlice