import { sliceFactory } from '../factory/slice';
import { GenericGroup } from '../generic_groups/slice';

export interface UserRole extends GenericGroup {
    weight: Number,
    show_empty: Boolean
}

export const userRoleAPISlice = sliceFactory<UserRole>({
    reducerName: 'userRoleAPI',
    endpoint: '/user_roles/',
    name: 'UserRole'
});

export const {
    useGetUserRolesQuery,
    useGetUserRoleQuery,
    useDeleteUserRoleMutation,
    useUpdateUserRoleMutation,
    useCreateUserRoleMutation,
} = userRoleAPISlice
