import { Workspace } from '../workspaces/slice';
import { createSlice } from '@reduxjs/toolkit';
import { sliceFactory } from '../factory/slice';

export interface Step {
    created: Date,
    name: string,
    group_name: string,
    code: number,
    id: string,
    color: string,
    start_date: Date,
    end_date: Date,
    modified: Date,
    description: string,
    phase: string | null,
    disabled: boolean,
    hidden: boolean,
    workspace: Workspace | null
}

export interface StepState {
    stepInEdition: Step | null
}

export const initialState: StepState = {
    stepInEdition: null
}

export const stepSlice = createSlice({
    name: 'step',
    initialState,
    reducers: {
        setStepInEdition: (state, payload:{payload:Step|null}) => {
            state.stepInEdition = payload.payload;
        }
    }
});

export const stepAPISlice = sliceFactory<Step>({
    reducerName: 'stepAPI',
    endpoint: '/steps/',
    name: 'Step'
});


export default stepSlice.reducer;
export const { setStepInEdition } = stepSlice.actions

export const {
    useGetStepsQuery,
    useGetStepQuery,
    useDeleteStepMutation,
    useUpdateStepMutation,
    useCreateStepMutation,
} = stepAPISlice