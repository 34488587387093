import { Workspace } from '../workspaces/slice';
import { createSlice } from '@reduxjs/toolkit';
import { sliceFactory } from '../factory/slice';

export interface Task {
    created: Date,
    start_date: Date,
    end_date: Date,
    type: string,
    weight: number,
    step: string,
    name: string,
    description: string,
    id: string,
    state: string,
    assignee: string,
    created_by: string,
    modified: Date,
    is_high_priority: boolean,
    disabled: boolean,
    hidden: boolean,
    generic_groups: string[],
    workspace: Workspace | null
}

export interface TaskState {
    taskInEdition: Task | null
}

export const initialState: TaskState = {
    taskInEdition: null
}

export const taskSlice = createSlice({
    name: 'task',
    initialState,
    reducers: {
        setTaskInEdition: (state, payload:{payload:Task|null}) => {
            state.taskInEdition = payload.payload;
        }
    }
});

export const taskAPISlice = sliceFactory<Task>({
    reducerName: 'taskAPI',
    endpoint: '/tasks/',
    name: 'Task'
});


export default taskSlice.reducer;
export const { setTaskInEdition } = taskSlice.actions

export const {
    useGetTasksQuery,
    useGetTaskQuery,
    useDeleteTaskMutation,
    useUpdateTaskMutation,
    useCreateTaskMutation,
} = taskAPISlice