import { useHistory, useLocation } from 'react-router';

import { Button } from 'primereact/button';
import iconOvmAdmin from '../assets/icon_ovm_admin.svg';
import iconOvmDeploy from '../assets/icon_ovm_deploy.svg';
import iconOvmGraph from '../assets/icon_ovm_graph.svg';
import { useAppSelector } from '../hooks';

const OvermindNavbarCenter = () => {
    const location = useLocation();
    const history = useHistory();
    const currentUser = useAppSelector(state => state.authentication.currentUser);

    return <div className="middle-part">
        <>
            <Button onClick={() => history.push(`/workspaces`)} className={`p-button-link ${location.pathname.match(`^/workspaces$`) ? ' active' : ''}`}><img className="navbar-icon" src={iconOvmDeploy} alt="graph" />Projets</Button>
            {currentUser?.is_superuser && <Button onClick={() => history.push(`/partners`)} className={`p-button-link ${location.pathname.match(`^/partners$`) ? ' active' : ''}`}> <img className="navbar-icon" src={iconOvmGraph} alt="graph" /> Partenaire</Button>}
            <Button onClick={() => history.push(`/users`)} className={`p-button-link ${location.pathname.match(`^/users$`) ? ' active' : ''}`}> <img className="navbar-icon" src={iconOvmAdmin} alt="graph" /> Annuaire</Button>
        </>
    </div>
};

export default OvermindNavbarCenter;
