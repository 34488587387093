import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Event, useCreateEventMutation, useDeleteEventMutation, useUpdateEventMutation } from "./slice";
import { Theme, useGetThemesQuery } from "../themes/slice";
import { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from 'primereact/multiselect';
import { addNotification } from "../notifications/slice";
import moment from 'moment';
import { useAppDispatch } from "../hooks";

const initialEventState = {
    id: "",
    name: "",
    event_date: new Date(),
    generic_groups: [],
    description: "",
    step: ""
};

export const EventForm = (props: { event: Event | null, closeModal: Function, step: string | null }) => {
    const dispatch = useAppDispatch();

    const [event, setEvent] = useState<Pick<Event, 'id' | 'name' | 'step' | 'event_date' | 'description' | 'generic_groups'>>(initialEventState);
    const [createEvent, { isError: createError, isSuccess: createSuccess }] = useCreateEventMutation();
    const [updateEvent, { isError: updateError, isSuccess: updateSuccess }] = useUpdateEventMutation();
    const [, { isError: deleteError, isSuccess: deleteSuccess }] = useDeleteEventMutation();
    const { data: tags } = useGetThemesQuery();

    const handleChange = ({ target }: any | React.ChangeEvent<HTMLInputElement> | CalendarChangeEvent) => {
        setEvent((prev) => ({
            ...prev,
            [target.id]: target.value,
        }));
    }

    const handleCalendarSelect = ({ value }: any) => {
        setEvent((prev: any) => {
            const _event = {
                ...prev,
                event_date: moment(value).format('YYYY-MM-DD')
            }
            return _event;
        });
    }


    const handleCalendarBlur = ({ target }: any | React.ChangeEvent<HTMLInputElement> | CalendarChangeEvent) => {
        setEvent((prev: any) => {
            const _event = {
                ...prev,
                event_date: moment(target.value, 'DD/MM/YYYY').format('YYYY-MM-DD')
            }
            return _event;
        });
    }

    const handleCreate = () => {
        createEvent({
            ...event,
            event_date: moment(event.event_date).format('YYYY-MM-DD')
        });
    }

    useEffect(() => {
        setEvent(props.event || initialEventState);
        if (props.step !== null) {
            setEvent((prev) => ({
                ...prev,
                step: props.step ? props.step : ''
            }))
        }
    }, [props.event, props.step])

    // Update alert
    useEffect(() => {
        if (updateError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de mettre à jour l\'événement'
            }));
        }
    }, [updateError, dispatch]);

    useEffect(() => {
        if (updateSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Évenement mise à jour'
            }));
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateSuccess]);

    // Delete alert
    useEffect(() => {
        if (deleteError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de supprimer l\'événement'
            }));
        }
    }, [deleteError, dispatch]);

    useEffect(() => {
        if (deleteSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Évenement éffacer'
            }));
            setEvent(initialEventState);
        }
    }, [deleteSuccess, dispatch]);

    // Create alert
    useEffect(() => {
        if (createSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Évenement créé'
            }));
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createSuccess]);

    useEffect(() => {
        if (createError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de créer l\'événement'
            }));
        }
    }, [createError, dispatch]);


    return <div>
        <div className="p-fluid">
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="name">Nom</label>
                <div className={'p-col-12 p-md-10'}>
                    <InputText id="name" onChange={handleChange} value={event.name} /><br />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="name">Description</label>
                <div className={'p-col-12 p-md-10'}>
                    <InputTextarea rows={10} id="description" onChange={handleChange} value={event.description} /><br />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="event_date">Date</label>
                <div className="p-col-12 p-md-10">
                    <Calendar id="event_date" value={new Date(event.event_date)} onSelect={handleCalendarSelect} onBlur={handleCalendarBlur} keepInvalid dateFormat="dd/mm/yy" />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="generic_groups">Thèmes</label>
                <div className={'p-col-12 p-md-10'}>
                    <MultiSelect selectionLimit={3} selectedItemsLabel="{0} thèmes séléctionnés" maxSelectedLabels={1} id="generic_groups" value={event.generic_groups} options={tags?.results == null ? [] : tags?.results.map((theme: Theme) =>
                        ({ label: theme.label, value: theme.id })
                    )} onChange={handleChange} placeholder="Selectionner le ou les thème(s)" /><br />
                </div>
            </div>
        </div>
        <Button onClick={props.event ? () => updateEvent(event) : handleCreate} >{'Sauvegarder'} </Button>
    </div>;
}
