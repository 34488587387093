import { User, UserRoleWorkspace } from "./users/slice";

import { Role } from "./permissions/slice";

export const CanChange = (currentUser: User, roles: Role[], workspace_slug: string, action: string, model: string): boolean => {
    if (currentUser?.is_superuser) return true;

    // check by admin right, prestataire are admin from there workspace
    if (currentUser.has_overmind_access) {
        let role: Role | undefined = roles?.find((rl: Role) => rl.name=== 'Gestion');
        return role?.permissions?.find((perm) =>
            perm.action === action &&
            perm.ressource.model === model
        ) !== undefined;
    }

    // check by role
    let ws_role = currentUser?.user_role_workspaces?.find((ws_role: UserRoleWorkspace) => ws_role.workspace === workspace_slug);
    if (ws_role && roles !== null) {
        let role: Role | undefined = roles?.find((rl: Role) => rl.id === ws_role?.role);
        if (role) {
            return role.permissions?.find((perm) =>
                perm.action === action &&
                perm.ressource.model === model
            ) !== undefined;
        }
    }
    return false;
}

export const getAPIUrl = () => {
    return `${window.location.protocol}//api.${window.location.hostname}/v1`;
}

export const getURLForFile= (filePath:string) => {
    return `${window.location.protocol}//api.${window.location.hostname}${filePath}`;
}

export const getWorkspaceEndpoint = (endpoint:string) => {
    const pathname = window.location.pathname;
    const re = new RegExp('/workspace/([^/]+)');
    const result = pathname.match(re);
    const workspace = result ? result[1] : null;
    if (workspace) {
        return `/workspaces/${workspace}${endpoint}`
    } else {
        return endpoint;
    }
}