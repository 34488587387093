import './styles.scss';

import { FunctionComponent, useEffect, useState } from "react";
import { Partner, useDeletePartnerMutation, useGetPartnersQuery } from "./slice";

import { Button } from "primereact/button";
import { Column } from 'primereact/column';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MunityDataTable from '../layouts/components/MunityDataTable';
import PartnerForm from './form';
import { addNotification } from "../notifications/slice";
import { confirmPopup } from 'primereact/confirmpopup';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import { setPartnerInEdition } from "./slice";
import { useAppSelector } from "../hooks";
import { useDispatch } from "react-redux";

const PartnerList: FunctionComponent<{}> = () => {
    const dispatch = useDispatch();
    const [showForm, setShowForm] = useState<boolean>(false);
    const { data: partners, error: onErrorGetPartners } = useGetPartnersQuery();
    const currentUser = useAppSelector(state => state.authentication.currentUser)

    const [deletePartner, { isError: deleteError, isSuccess: deleteSuccess }] = useDeletePartnerMutation();

    useEffect(() => {
        if (onErrorGetPartners) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de récuperer les partenaires'
            }));
        }
    }, [onErrorGetPartners, dispatch]);
    // Delete alert

    useEffect(() => {
        if (deleteError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de supprimer les partenaires'
            }));
        }
    }, [deleteError, dispatch]);

    useEffect(() => {
        if (deleteSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Partenaire supprimé'
            }));
        }
    }, [deleteSuccess, dispatch]);

    const actions = (p: Partner) => <div className="action-list">
        <div>
            <Button onClick={() => {
                dispatch(setPartnerInEdition(p));
                setShowForm(true);
            }}>
                <FontAwesomeIcon icon={faEdit} />
            </Button>
        </div>
        <div>
            <Button onClick={() =>
                confirmPopup({
                    message: 'Etes vous sur de supprimer ce partenaire ?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => p.id && deletePartner(p.id),
                    reject: () => { }
                })}>
                <FontAwesomeIcon icon={faTrash} />
            </Button>
        </div>
    </div >;

    const createNew = () => {
        dispatch(setPartnerInEdition(null));
        setShowForm(true);
    }

    // return <div className="workspace-wrapper">
    //     <div className="workspace-cards">
    //         <div className="workspace-title">Liste des partenaires</div>
    //         {partners?.results.map(p => {
    //             return <PartnerCard key={p.id} partner={p} />
    //         })}
    //         {currentUser.is_superuser && <Button className="createNew p-button-rounded p-button-lg" icon="pi pi-plus" onClick={createNew} />}
    //     </div>
    // </div>;
    return <div className="partner-wrapper">
        <PartnerForm show={showForm} onClose={() => setShowForm(false)} />
        <MunityDataTable
            createNew={currentUser.is_superuser ? createNew : () => {}}
            value={partners?.results}
            className="data-table"
            dataKey="id"
            filters={{
                'name': { operator: "and", constraints: [{ value: null, matchMode: "contains" }] },
            }}
            filterDisplay="menu"
            globalFilterFields={['name']}
            emptyMessage={'Aucun partenaire'}
        >
            <Column field="name" header={'Nom'} filterPlaceholder="Chercher par nom" />
            <Column field="created" body={p => <div>{moment(new Date(p.created)).fromNow()}</div>} header={'Créé'} />
            {/* <Column field="is_superuser" header={t('common:is_superuser')} body={user => <div>{user.is_superuser? <FontAwesomeIcon icon={faUserShield}/> : ''}</div>}/>
            <Column field="has_overmind_access" header={t('common:has_overmind_access')} body={user => <div>{user.has_overmind_access ? <FontAwesomeIcon icon={faUserCog}/> : ''}</div>}/> */}
            <Column body={currentUser.is_superuser ? actions : <div/>} bodyStyle={{ display: 'flex', justifyContent: 'flex-end' }} />
        </MunityDataTable>
    </div>;
}

export default PartnerList;
