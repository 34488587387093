import './style.scss';

import { Phase } from "../phases/slice";
import { Step } from "./slice";
import { useAppSelector } from "../hooks";
import { useHistory } from "react-router";

const StepRow = (step: Step, currentStep: Step, router: any, workspace_slug: string, index: number | null, group_idx: number | null) => {
    return <div onClick={() => router.push(`/workspace/${workspace_slug}/step/${step.id}`)} className={step?.id === currentStep?.id ? `step-row-wrapper selected` : 'step-row-wrapper'} key={step.id}
        style={{ backgroundColor: `var(--step-${group_idx !== null ? (group_idx + 3) : (index || '0')}-color)` }}>
        <span className='step-card-code'>
            {step.code}
        </span>
        <div>
            {step.name}
        </div>
    </div>
}

const StepColumn = (props: { steps: Array<Step>, phases: Array<Phase>, currentStep: Step, editable: boolean }) => {
    const router = useHistory();
    const workspace_slug = useAppSelector((state) => state.app.workspace_slug);
    const currentUser = useAppSelector((state) => state.authentication.currentUser);

    return <div className={'steps-column-wrapper'}>
        <h3>Étapes</h3>
        <div className={'steps-column'}>
            <div onClick={() => router.push(`/workspace/${workspace_slug}`)} className={`step-row-wrapper home`}>
                <span className='step-card-code'>
                    <i className='pi pi-home'></i>
                </span>
                <div>Page d'accueil
                </div>
            </div>
            {props.steps?.filter(s => !s.phase).map((step: Step, index: number) => {
                if (step.hidden && !currentUser.has_overmind_access && !currentUser.is_superuser) return <></>
                return StepRow(step, props.currentStep, router, workspace_slug, index, null);
            })}
            {props.phases?.map((phase: Phase, index: number) => {
                return <div style={{ backgroundColor: `var(--step-${index + 3}-bg-color)`, padding: `2px 0px` }}>
                    <h4>{phase.name}</h4>
                    {props.steps?.filter(s => phase.id === s.phase).map((step: Step) => {
                        if (step.hidden && !props.editable) return <></>
                        return StepRow(step, props.currentStep, router, workspace_slug, null, index);
                    })}
                </div>
            })}
        </div>
    </div>
}

export default StepColumn;
