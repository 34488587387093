import { sliceFactory } from '../factory/slice';
import { GenericGroup } from '../generic_groups/slice';

export interface Theme extends GenericGroup {}

export const themeAPISlice = sliceFactory<Theme>({
    reducerName: 'themeAPI',
    endpoint: '/themes/',
    name: 'Theme'
});

export const {
    useGetThemesQuery,
    useGetThemeQuery,
    useDeleteThemeMutation,
    useUpdateThemeMutation,
    useCreateThemeMutation,
} = themeAPISlice
