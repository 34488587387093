import './style.scss';

import { Intern, useDeleteInternMutation } from "./slice";
import { useEffect, useState } from "react";

import { Avatar } from "primereact/avatar";
import { Column } from 'primereact/column';
import { DataTable } from "primereact/datatable";
import DeleteButton from "../common/confirmationDeleteButton";
import { InternForm } from "./form";
import OpenFormButton from "../common/openFormButton";
import { getURLForFile } from "../helper";

const InternsList = (props: {interns: Array<Intern>})  => {
    const [deleteIntern] = useDeleteInternMutation();
    const [interns, setInterns] = useState<Intern[]>();
    const actions = (intern: Intern) => {
        return <div className='relative-open-form-btn action-buttons'>
        <OpenFormButton action="update" model="intern" header={'Editer un stagiaire'} form={<InternForm intern={intern} closeModal={() => null} step={null}/>} />
        <DeleteButton model="intern" deleteFunction={() => deleteIntern(intern.id)}/>
    </div>
    }

    useEffect(() => {
        setInterns(props.interns)
    }, [props.interns])

    return <div className='interns-list-wrapper'>
        <h1 className='title'>Liste des stagiaires</h1>
        <div style={{width: '100%', padding: '0px 32px 0px 0px'}}>
            <DataTable
                value={interns}
                className="data-table"
                dataKey="id"
                emptyMessage="No users found."
            >
                <Column body={(intern:Intern) =>
                    intern?.avatar && (typeof intern.avatar !== "string") ?
                        <Avatar shape="circle" className="p-mr-2" size="large" image={getURLForFile(intern.avatar.file)} /> :
                        <Avatar label={`${intern.name.substring(0,2)}`} shape="circle" className="p-mr-2" size="large" />
                } />
                <Column field="name" header="Nom"/>
                <Column field="firstname" header="Prénom"/>
                <Column field="function" header="Fonction" />
                <Column field="email" header="Email" />
                <Column field="phone" header="Téléphone" />
                <Column field="comment" header="Commentaires" />
                <Column body={actions} bodyStyle={{ display: 'flex', justifyContent: 'flex-end' }} />
            </DataTable>
        </div>
    </div>
}

export default InternsList;
