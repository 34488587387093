import { Step, useDeleteStepMutation } from "./slice";

import DeleteButton from "../common/confirmationDeleteButton";
import OpenFormButton from "../common/openFormButton";
import { StepForm } from "./form";
import { useAppSelector } from "../hooks";
import { useHistory } from "react-router-dom";

const StepCard = (props: { idx: number | null, position: number | null, step: Step, isInline?: boolean }) => {
    const router = useHistory();
    const currentUser = useAppSelector((state) => state.authentication.currentUser);
    const workspace_slug = useAppSelector((state) => state.app.workspace_slug);
    const [deleteStep ] = useDeleteStepMutation();

    const handleClick = () => {
        router.push(`/workspace/${workspace_slug}/step/${props.step.id}`)
    }

    return <div className='step-card-wrapper' style={{ backgroundColor: props.idx === null ? `var(--step-${(props.position || 0)}-color)` : `var(--step-${props.idx + 3}-color)` }}>
        <div className='p-d-flex p-flex-column' onClick={handleClick}>
            {props.step.hidden &&
                <span style={{ position: 'absolute', right: '0', padding: '8px 16px', backgroundColor: 'rgba(255,255,255,0.3)' }}><i className='pi pi-eye-slash'></i></span>
            }
            <span className='step-card-code'>
                {props.step.code}
                {props.idx === 0 && 'A'}
                {props.idx === 1 && 'B'}
                {props.idx === 2 && 'C'}
            </span>
            <div className='step-card-title'>
                {props.step.name}
            </div>
            <pre className='step-card-description'>
                {props.step.description}
            </pre>
        </div>
        {currentUser.is_superuser &&
            <div className='relative-open-form-btn action-buttons'>
                <OpenFormButton action="update" model="step" header={'Editer une étape'} form={<StepForm stepsCount={0} closeModal={() => null} step={props.step} />} />
                <DeleteButton model="step" deleteFunction={() => deleteStep(props.step.id)} />
            </div>
        }
    </div>
}

export default StepCard;
