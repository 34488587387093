import { sliceFactory } from '../factory/slice';
import { GenericGroup } from '../generic_groups/slice';

export interface Record extends GenericGroup {
    user: string,
    action: string,
    created: Date,
    product_content_type: string,
    product_object_id: string
}

export const recordAPISlice = sliceFactory<Record>({
    reducerName: 'recordAPI',
    endpoint: '/records/',
    name: 'Record'
});

export const {
    useGetRecordsQuery,
    useGetRecordQuery,
    useDeleteRecordMutation,
    useUpdateRecordMutation,
    useCreateRecordMutation,
} = recordAPISlice