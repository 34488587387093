import { sliceFactory } from '../factory/slice';

export interface UserFunction {
    id: string,
    weight: Number,
    label : string ,
    needed: boolean,
    show_empty: Boolean
}

export const userFunctionAPISlice = sliceFactory<UserFunction>({
    reducerName: 'userFunctionAPI',
    endpoint: '/user_functions/',
    name: 'UserFunction'
});

export const {
    useGetUserFunctionsQuery,
    useGetUserFunctionQuery,
    useDeleteUserFunctionMutation,
    useUpdateUserFunctionMutation,
    useCreateUserFunctionMutation,
} = userFunctionAPISlice
