import { User, UserFunctionWorkspace, useDeleteUserMutation, useUpdateUserMutation } from "../users/slice";
import { UserFunction, useGetUserFunctionsQuery } from "../user_functions/slice";
import { faPersonWalkingArrowRight, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserForm from './form';
import { addNotification } from "../notifications/slice";
import { confirmPopup } from 'primereact/confirmpopup';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { getURLForFile } from "../helper";
import { setUserInEdition } from "../users/slice";
import { useAppDispatch } from "../hooks";
import { useGetPartnersQuery } from "../partners/slice";
import { useTranslation } from "react-i18next";

const UserTable: React.FC<{ users: User[], workspace_slug?: string, group?: Boolean }> = props => {
    const dispatch = useAppDispatch();
    const [showForm, setShowForm] = useState<boolean>(false);
    const { t } = useTranslation();

    const [deleteUser, { isError: deleteError, isSuccess: deleteSuccess }] = useDeleteUserMutation();
    const [updateUser, { isError: updateError, isSuccess: updateSuccess }] = useUpdateUserMutation();
    const { data: user_functions } = useGetUserFunctionsQuery();
    const { data: partners } = useGetPartnersQuery();

    let workspace_slug: string | null = null;
    const pathname = window.location.pathname;
    const re = new RegExp('/workspace/([^/]+)');
    const result = pathname.match(re);
    if (result) {
        workspace_slug = result[1];
    }

    const removeUserFromProject = (user: User) => {
        const _user_function_workspaces: UserFunctionWorkspace[] = [...user.user_function_workspaces];
        user.user_function_workspaces.forEach((ufw, index) => {
            if (ufw.workspace === workspace_slug) {
                _user_function_workspaces.splice(index, 1);
            }
        })
        updateUser({
            id: user.id,
            user_function_workspaces: _user_function_workspaces,
        });
    }

    useEffect(() => {
        if (updateError) {
            dispatch(addNotification({
                type: 'error',
                message: t('Impossible de mettre à jour l\'utilisateur')
            }));
        }
    }, [updateError, dispatch, t]);

    useEffect(() => {
        if (deleteError) {
            dispatch(addNotification({
                type: 'error',
                message: t('error:Cannot delete user')
            }));
        }
    }, [deleteError, dispatch, t]);

    useEffect(() => {
        if (updateSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: t('Utilisateur enlevé du projet')
            }));
        }
    }, [updateSuccess, dispatch, t]);


    useEffect(() => {
        if (deleteSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: t('Utilisateur supprimé')
            }));
        }
    }, [deleteSuccess, dispatch, t]);

    const actions = (u: User) => <div className="action-list">
        <Button onClick={() => {
            dispatch(setUserInEdition(u));
            setShowForm(true);
        }}>
            <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button onClick={() =>
            confirmPopup({
                message: `Étes-vous sur de vouloir supprimer le compte ${u.username} (${u.first_name} ${u.last_name}) ?`,
                icon: 'pi pi-exclamation-triangle',
                accept: () => deleteUser(u.id),
                reject: () => { },
                rejectLabel: "Non",
                acceptLabel: "Oui",
            })}>
            <FontAwesomeIcon icon={faTrash} />
        </Button>
        {
            (workspace_slug && u.user_function_workspaces.reduce((prev, ufw) => {
                if (ufw.workspace === workspace_slug) {
                    return true;
                }
                return prev;
            }, false)) ?
                <Button onClick={() =>
                    confirmPopup({
                        message: `Étes-vous sur de vouloir enlever le compte ${u.username} (${u.first_name} ${u.last_name}) du projet ?`,
                        icon: 'pi pi-exclamation-triangle',
                        accept: () => removeUserFromProject(u),
                        reject: () => { },
                        rejectLabel: "Non",
                        acceptLabel: "Oui",
                    })}>
                    <FontAwesomeIcon icon={faPersonWalkingArrowRight} />
                </Button>
                : null
        }
    </div >;

    return <>
        <UserForm mode={"workspace"} show={showForm} onClose={() => setShowForm(false)} />
        <DataTable stripedRows={true}
            rowGroupHeaderTemplate={u => <h3 style={{ color: 'var(--secondary-color)' }}>{u.workspace}</h3>}
            // groupRowsBy="workspace"
            sortField="username"
            value={props.users.map(u => {
                return {
                    ...u,
                    workspace: u.user_role_workspaces.length > 0 ? u.user_role_workspaces[0].workspace : ''
                }
            })}
            // responsiveLayout="scroll"
            className="data-table"
            dataKey="id"
            // filters={{
            //     'username': { operator: "and", constraints: [{ value: null, matchMode: "contains" }] },
            //     'firstname': { operator: "and", constraints: [{ value: null, matchMode: "contains" }] },
            //     'lastname': { operator: "and", constraints: [{ value: null, matchMode: "contains" }] },
            //     'email': { operator: "and", constraints: [{ value: null, matchMode: "contains" }] },
            //     'created': { operator: "and", constraints: [{ value: null, matchMode: "dateIs" }] }
            // }}
            // filterDisplay="menu"
            // globalFilterFields={['username', 'firstname', 'lastname', 'email', 'created']}
            emptyMessage="Aucun utilisateur"
        >
            <Column body={(user: User) =>
                user?.avatar && (typeof user.avatar !== "string") ?
                    <Avatar shape="circle" className="p-mr-2" size="large" image={getURLForFile(user.avatar.file)} /> :
                    <Avatar shape="circle" label={`${user?.first_name.substring(0, 1)}${user?.last_name.substring(0, 1)}`} className="p-mr-2" size="large" />
            } />
            <Column field="username" sortable header="Identifiant" />
            <Column field="first_name" sortable header="Prénom" />
            <Column field="last_name" sortable header="Nom" />
            <Column field="partner" sortable body={(user: User) => <div>
                {partners?.results.find(p => p.id === user.partner)?.name}
            </div>}
                header="Partenaire" />
            <Column header="Fonction" body={(user: User) =>
                <div>{user?.function}</div>}
            />
            {
                workspace_slug ?
                    <Column header="Rôle sur le projet" body={(user: User) => {
                        const userFunctionName: String | undefined = user_functions?.results?.find(
                            (f: UserFunction) => f.id === user?.user_function_workspaces.find(
                                (ufw: UserFunctionWorkspace) => ufw.workspace === workspace_slug
                            )?.function
                        )?.label;
                        return <div>{userFunctionName}</div>
                    }
                    } /> : null
            }
            <Column field="email" header="Email" />
            <Column field="phone_number" header="Téléphone" />
            <Column body={actions} bodyStyle={{ justifyContent: 'flex-end' }} />
        </DataTable>
    </>;
}

export default UserTable;