import './style.scss';
import WorkspaceList from './components/listing';

const WorkspacesList = () => {

    return <div style={{ width: '100%' }}>
        <div style={{ width: '100%', padding: '64px' }}>
            <WorkspaceList />
        </div>
    </div>
}

export default WorkspacesList;
