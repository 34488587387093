import { Intern, useCreateInternMutation, useDeleteInternMutation, useUpdateInternMutation } from "./slice";
import { useEffect, useState } from "react";

import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { File } from "../files/slice";
import { FileUploadUploadEvent } from 'primereact/fileupload';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import SimpleUploader from "../files/SimpleUploader";
import { addNotification } from "../notifications/slice";
import { getURLForFile } from "../helper";
import { useAppDispatch } from "../hooks";

const initialInternState = {
    id: "",
    name: "",
    firstname: "",
    comment: "",
    avatar: "",
    email: "",
    phone: "",
    function: "",
    step: ""
};

export const InternForm = (props: { intern: Intern | null, closeModal: Function, step: string | null }) => {
    const dispatch = useAppDispatch();

    const [intern, setIntern] = useState<Pick<Intern, 'id' | 'name' | 'firstname' | 'step' | 'email' | 'phone' | 'comment' | 'avatar' | 'function'>>(initialInternState);
    const [uploadedAvatar, setUploadedAvatar] = useState<File | undefined>(undefined);
    const [createIntern, { isError: createError, isSuccess: createSuccess }] = useCreateInternMutation();
    const [updateIntern, { isError: updateError, isSuccess: updateSuccess }] = useUpdateInternMutation();
    const [{ isError: deleteError, isSuccess: deleteSuccess }] = useDeleteInternMutation();

    const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIntern((prev) => ({
            ...prev,
            [target.id]: target.value,
        }));
    }

    useEffect(() => {
        setIntern(props.intern || initialInternState);
        if (props.step !== null) {
            setIntern((prev) => ({
                ...prev,
                step: props.step ? props.step : ''
            }))
        }
    }, [props.intern, props.step])

    // Update alert
    useEffect(() => {
        if (updateError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de mettre à jour le stagiaire'
            }));
        }
    }, [updateError, dispatch]);

    useEffect(() => {
        if (updateSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Stagiaire mis à jour'
            }));
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateSuccess, dispatch]);

    // Delete alert
    useEffect(() => {
        if (deleteError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de supprimer le stagiaire'
            }));
        }
    }, [deleteError, dispatch]);

    useEffect(() => {
        if (deleteSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Stagiaire effacé'
            }));
            setIntern(initialInternState);
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteSuccess, dispatch]);

    // Create alert
    useEffect(() => {
        if (createSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Stagiaire créé'
            }));
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createSuccess, dispatch]);

    useEffect(() => {
        if (createError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de créer le stagiaire'
            }));
        }
    }, [createError, dispatch]);


    return <div>
        <div className="p-d-flex p-jc-center p-m-2">
            {
                uploadedAvatar ?
                    <Avatar shape="circle" className="p-mr-2" size="xlarge" image={uploadedAvatar.file} /> :
                    intern?.avatar && (typeof intern.avatar !== "string") ?
                        <Avatar shape="circle" className="p-mr-2" size="xlarge" image={getURLForFile(intern.avatar.file)} /> :
                        <Avatar label={`${intern.name.substring(0, 2)}`} shape="circle" className="p-mr-2" size="xlarge" />
            }
            <SimpleUploader
                maxFileSize={100000000}
                onUpload={(e: FileUploadUploadEvent) => {
                    setUploadedAvatar(JSON.parse(e.xhr.response));

                    setIntern((prev) => ({
                        ...prev,
                        avatar: JSON.parse(e.xhr.response).id
                    }));
                }}
                label="Changer d'avatar (format carré)"
                auto
            />
        </div>
        <div className="p-fluid">
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="name">Nom</label>
                <div className={'p-col-12 p-md-10'}>
                    <InputText id="name" onChange={handleChange} value={intern.name} /><br />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="name">Prénom</label>
                <div className={'p-col-12 p-md-10'}>
                    <InputText id="firstname" onChange={handleChange} value={intern.firstname} /><br />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="function">Fonction</label>
                <div className={'p-col-12 p-md-10'}>
                    <InputText id="function" onChange={handleChange} value={intern.function} /><br />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="email">Email</label>
                <div className={'p-col-12 p-md-10'}>
                    <InputText id="email" onChange={handleChange} value={intern.email} /><br />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="phone">Téléphone</label>
                <div className={'p-col-12 p-md-10'}>
                    <InputText id="phone" onChange={handleChange} value={intern.phone} /><br />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="comment">Commentaires</label>
                <div className={'p-col-12 p-md-10'}>
                    <InputTextarea rows={10} id="comment" onChange={handleChange} value={intern.comment} /><br />
                </div>
            </div>
        </div>
        <Button onClick={props.intern ? () => updateIntern(intern) : () => createIntern(intern)} >Sauvegarder</Button>
    </div>;
}
