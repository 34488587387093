import './style.scss';

import { Phase, useDeletePhaseMutation } from "../phases/slice";

import { Button } from "primereact/button";
import DeleteButton from "../common/confirmationDeleteButton";
import OpenFormButton from "../common/openFormButton";
import { PhaseForm } from "../phases/form";
import { Step } from "./slice";
import StepCard from "./tile";
import axios from 'axios';
import { getAPIUrl } from "../helper";
import { useAppSelector } from "../hooks";

const StepsList = (props: { refetch: Function, steps: Array<Step>, phases: Array<Phase>, editable: boolean }) => {
    const [deletePhase] = useDeletePhaseMutation();
    const currentUser = useAppSelector((state) => state.authentication.currentUser);

    const handlePhaseDuplication = (id: string) => {
        axios.post(`${getAPIUrl()}/phases/duplicate`,
            {
                "id": id
            },
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('access_token')}`
                }
            }
        ).then(() => props.refetch())
            .catch((err: Error) => console.log(err))
    }


    return <div className={'steps-list-wrapper'}>
        <div className="title-desc-step">
            <h1 className='title'>Étapes du projet</h1>
            <p>
                Le projet de déploiement qui dure quelques mois, se décompose toujours en quatre ou cinq étapes.
                Pour chacune vous trouverez des informations, des documents à partager, et un mini suivi de projet sous la forme d’un calendrier et de tâches à réaliser.

 
            </p>
        </div>
        <div className={'steps-list'}>
            {props.steps?.filter(s => !s.phase).map((step: Step, index: number) => {
                if (step.hidden && !props.editable && !currentUser.has_overmind_access) return null;
                return <StepCard position={index} idx={null} key={step.id} step={step} isInline />
            })}
        </div>
        {props.phases?.map((phase: Phase, index: number) => {
            return <div className={'steps-list grouped-steps'} style={{ backgroundColor: `var(--step-${index + 3}-bg-color)` }}>
                <div className='steps-card'>
                    {props.steps?.filter(s => phase.id === s.phase).map((step: Step) => {
                        if (step.hidden && !props.editable) return <></>
                        return <StepCard position={null} idx={index} key={step.id} step={step} isInline />
                    })}
                </div>
                <div className="a-phase">
                    <div className="phase-name">
                        {phase.name}
                    </div>
                    <pre className="phase-desc">{phase.description}</pre>
                    {currentUser.is_superuser &&
                        <div className='relative-open-form-btn action-buttons'>
                            <Button onClick={() => handlePhaseDuplication(phase.id)}>Dupliquer la phase</Button>
                            <OpenFormButton action="update" model="phase" header={'Editer une phase'} form={<PhaseForm closeModal={() => null} phase={phase} />} />
                            <DeleteButton model="step" deleteFunction={() => deletePhase(phase.id)} />
                        </div>
                        }
                </div>
            </div>
        })}
    </div>
}

export default StepsList;
