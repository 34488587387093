import './style.scss';

import { authenticate, recoverPassword } from './slice';
import { useAppDispatch, useAppSelector } from '../hooks';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LoginForm: React.FC<{ logo: string }> = props => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const loginInProgress = useAppSelector(state => state.authentication.pending);

    const sendAuthentication = async () => {
        dispatch(authenticate({
            username: username,
            password: password,
        }))
    }

    const sendRecoverPassword = () => {
        dispatch(recoverPassword({
            email: username
        })).then((data: any) => {
            if (!data.error) {
                setShowResetPassword(false);
            }
        })
    }

    return <div className="login-form">
        {
            !showResetPassword ?
                <form onSubmit={e => {
                    e.preventDefault();
                    sendAuthentication();
                }}>
                    <div className="login-form-wrapper">
                        <img src={props.logo} alt="Logo" className="logo" />
                        <div className="welcome">{t('app:welcome')}</div>
                        <div className="welcome-text">{t('app:welcome_text')}</div>
                        <div className="p-field">
                            <label htmlFor="username" className="p-d-block">{t('common:user')}</label>
                            <InputText autoComplete="username" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="password" className="p-d-block">{t('common:password')}</label>
                            <InputText autoComplete="current-password" id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        {
                            <Button style={{ marginTop: '8px' }} disabled={loginInProgress} type="submit" onSubmit={sendAuthentication}
                                icon="pi pi-arrow-right" iconPos="right" label={t('common:login')}
                            />
                        }
                        <div style={{ marginTop: '20px' }} className="p-field">
                            <Button severity='secondary' text onClick={() => setShowResetPassword(true)}>Récuperer mon mot de passe</Button>
                        </div>
                    </div>
                </form>
                :
                <div className="login-form-wrapper">
                    <form onSubmit={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                        <img src={props.logo} alt="Logo" className="logo" />
                        <div className="welcome">{t('app:welcome')}</div>
                        <div className="welcome-text">{t('app:welcome_text')}</div>
                        <div className="p-field">
                            <label htmlFor="email" className="p-d-block">E-mail</label>
                            <InputText autoComplete="email" id="email" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </div>
                        <Button style={{ marginTop: '8px' }} type="submit" disabled={loginInProgress} onClick={sendRecoverPassword}
                            icon="pi pi-arrow-right" iconPos="right" label={'Changer mon mot de passe'}
                        />
                        <div style={{ marginTop: '20px' }} className="p-field">
                            <Button severity='secondary' type="button" onClick={() => setShowResetPassword(false)}>Retourner au login</Button>
                        </div>
                    </form>
                </div>
        }
    </div>
        ;
}

export default LoginForm;
