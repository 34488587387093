import './style.scss';
import 'dayjs/locale/fr'

import { Event, useDeleteEventMutation } from './slice';

import DeleteButton from '../common/confirmationDeleteButton';
import { EventForm } from './form';
import OpenFormButton from '../common/openFormButton';
import TagText from '../common/tagtext';
import Tags from '../common/tags';
import dayjs from 'dayjs';

dayjs.locale('fr')

const EventsList = (props: { events: Array<Event> }) => {
    const [deleteEvent] = useDeleteEventMutation();

    return <div className={'events-list-wrapper'}>
        <h1 className='title'>Événements</h1>
        <div className={'events-list'}>
            {props.events?.map((event: Event) => {
                return <div key={event.id} className='event-item p-shadow-8' style={{ position: 'relative' }}>
                    <div className='date'>
                        <span>
                            {event.event_date ? dayjs(event.event_date).format('MMM') : '...'}
                        </span>
                        <span>
                            {event.event_date ? dayjs(event.event_date).format('DD') : '..'}
                        </span>
                    </div>
                    <div className='title'>
                        {event.name}
                    </div>
                    <div className='description'>
                        <pre>
                            {event.description}
                        </pre>
                    </div>
                    <div className="tag-wrapper">
                        {
                            event.generic_groups.length === 1 ?
                                <Tags key={event.generic_groups[0]} groupId={event.generic_groups[0]} />
                                : null
                        }
                        {
                            event.generic_groups.length > 1 ?
                                <TagText text={`${event.generic_groups.length} thèmes séléctionnés`} />
                                : null
                        }
                    </div>
                    <div className='relative-open-form-btn action-buttons'>
                        <OpenFormButton action="update" model="event" header={'Editer un évènement'} form={<EventForm event={event} closeModal={() => null} step={null} />} />
                        <DeleteButton model="event" deleteFunction={() => deleteEvent(event.id)} />
                    </div>
                </div>
            })}
        </div>
    </div>
}

export default EventsList;
