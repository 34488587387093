import './style.scss';

import { Route, Switch, useParams } from "react-router";

import ArticleDetails from '../articles/details';
import Navbar from './components/Navbar';
import NavbarRight from './components/NavbarComponents/NavbarRight';
import Step from '../steps';
import Users from "../users";
import UsersList from '../users';
import Workspace from '.';
import WorkspaceNavbarLeft from '../common/WorkspaceNavbarLeft';
import { setWorkspace } from '../app/slice';
import { useAppDispatch } from "../hooks";
import { useEffect } from "react";

const WorkspaceHome = (props: {}) => {
    let { workspace_slug } = useParams<{ workspace_slug: string }>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setWorkspace(workspace_slug));
    }, [workspace_slug, dispatch])

    return <>
        <Navbar
            leftPart={WorkspaceNavbarLeft}
            centerPart={() => <div />}
            rightPart={NavbarRight}
        />
        <div className="layout-mainpage">
            <Switch>
                <Route path="/workspace/:slug/users" component={UsersList} />,
                <Route path="/workspace/:slug/article/:id" component={((id: any) => ArticleDetails({ id }))} />,
                <Route path="/workspace/:slug/step/:id" component={((id: any) => Step({ id }))} />,
                <Route path="/workspace/:slug/" component={Workspace} />,
                <Route path="/workspace/:workspace_slug/users" component={Users} />
                <Route path="/workspace/:workspace_slug/" component={Users} />
            </Switch>
        </div>
    </>
}

export default WorkspaceHome;