import { Workspace } from '../workspaces/slice';
import { createSlice } from '@reduxjs/toolkit';
import { sliceFactory } from '../factory/slice';
import { File } from '../files/slice';

export interface Article {
    created: Date,
    type: string,
    weight: number,
    step: string,
    name: string,
    id: string,
    description: string,
    content: string,
    user: string,
    admin: string,
    visual: File | string | undefined,
    modified: Date,
    disabled: boolean,
    hidden: boolean,
    generic_groups: string[],
    workspace: Workspace | null
}

export interface ArticleState {
    articleInEdition: Article | null
}

export const initialState: ArticleState = {
    articleInEdition: null
}

export const articleSlice = createSlice({
    name: 'article',
    initialState,
    reducers: {
        setArticleInEdition: (state, payload:{payload:Article|null}) => {
            state.articleInEdition = payload.payload;
        }
    }
});

export const articleAPISlice = sliceFactory<Article>({
    reducerName: 'articleAPI',
    endpoint: '/articles/',
    name: 'Article'
});


export default articleSlice.reducer;
export const { setArticleInEdition } = articleSlice.actions

export const {
    useGetArticlesQuery,
    useGetArticleQuery,
    useDeleteArticleMutation,
    useUpdateArticleMutation,
    useCreateArticleMutation,
} = articleAPISlice