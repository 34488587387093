import { Button } from 'primereact/button';
import logo from '../assets/logo.png';
import packageJson from '../../package.json';

const NavbarLeft = () => {
    return <div className="left-part p-d-flex p-ai-center">
        <Button onClick={() => window.location.href = '/'} className="p-button-link">
            <a href="/workspaces"><img src={logo} alt="logo" /></a>
        </Button> Portail version {packageJson.version}
    </div>;
}

export default NavbarLeft;