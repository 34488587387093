import { Workspace } from '../workspaces/slice';
import { createSlice } from '@reduxjs/toolkit';
import { sliceFactory } from '../factory/slice';

export interface Note {
    created: Date,
    weight: number,
    step: string,
    name: string,
    description: string,
    id: string,
    state: string,
    priority: boolean,
    assignee: string,
    created_by: string,
    modified: Date,
    disabled: boolean,
    hidden: boolean,
    generic_groups: string[],
    workspace: Workspace | null
}

export interface NoteState {
    noteInEdition: Note | null
}

export const initialState: NoteState = {
    noteInEdition: null
}

export const noteSlice = createSlice({
    name: 'note',
    initialState,
    reducers: {
        setNoteInEdition: (state, payload:{payload:Note|null}) => {
            state.noteInEdition = payload.payload;
        }
    }
});

export const noteAPISlice = sliceFactory<Note>({
    reducerName: 'noteAPI',
    endpoint: '/notes/',
    name: 'Note'
});


export default noteSlice.reducer;
export const { setNoteInEdition } = noteSlice.actions

export const {
    useGetNotesQuery,
    useGetNoteQuery,
    useDeleteNoteMutation,
    useUpdateNoteMutation,
    useCreateNoteMutation,
} = noteAPISlice