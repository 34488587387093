import './style.scss';

import { User, UserFunctionWorkspace } from "./slice";
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

import { Avatar } from 'primereact/avatar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MouseEventHandler } from 'react';
import { Tooltip } from 'primereact/tooltip';
import { UserFunction } from '../user_functions/slice';
import { getURLForFile } from '../helper';

const UsersCanvasWorkspace = (props: { showRoleOnly?: boolean, workspace_slug?: string, missingRole?: String, user?: User, functions?: UserFunction[], onClick: MouseEventHandler<HTMLDivElement> }) => {
    const user = props.user;
    const functions = props.functions;
    const workspace_slug= props.workspace_slug;

    let userFunctionName:String|undefined = functions?.find(
        f => f.id === user?.user_function_workspaces.find(
            (ufw:UserFunctionWorkspace) => ufw.workspace === workspace_slug
        )?.function
    )?.label;

    if(props.showRoleOnly && !userFunctionName) return <></>;
    return <div key={user?.id} className='user-item'
        onClick={props.onClick}
    >
        <Tooltip position="top" target={`#user-phone-${user?.id}`} content={user?.phone_number} />
        {
            user?.phone_number &&
            <a rel="noreferrer" onClick={e => e.stopPropagation()} target="_blank" href={`tel:${user?.phone_number}`}>
                <div className="phone" id={`user-phone-${user?.id}`}>
                    <FontAwesomeIcon icon={faPhone} />
                </div>
            </a>
        }
        {
            user?.email &&
            <a rel="noreferrer" onClick={e => e.stopPropagation()} target="_blank" href={`mailto:${user.email}`}>
                <div className="mail">
                    <FontAwesomeIcon icon={faEnvelope} />
                </div>
            </a>
        }
        <div className='avatar-wrapper'>
            {
                user ?
                    user?.avatar && (typeof user.avatar !== "string") ?
                        <Avatar className="p-mr-2" size="large" image={getURLForFile(user.avatar.file)} shape="circle" /> :
                        <Avatar label={`${user.first_name.substring(0, 1)}${user.last_name.substring(0, 1)}`} className="p-mr-2" size="large" shape="circle" />
                    :
                    <Avatar className="p-mr-2" icon="pi pi-user" size="large" />

            }
        </div>
        <div className='avatar-info'>
            <div>
                {user?.first_name || ''} {user?.last_name || ''}
            </div>
            <div>
                {
                    props.missingRole ? props.missingRole : userFunctionName
                }
            </div>
        </div>
    </div>
}

export default UsersCanvasWorkspace;

