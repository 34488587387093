import './style.scss';

import { Button } from "primereact/button";
import { CanChange } from "../helper";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useAppSelector } from "../hooks";
import { useGetRolesQuery } from "../permissions/slice";
import { useState } from "react";

// action are : create, update, retrieve, list and delete
const OpenFormButton:React.FC<{className?: string, form: JSX.Element, header: string, showLabel?:boolean, minWidth?: string, action:string, model:string, superuserOnly?:boolean}> = props => {
    const [isVisible, setIsVisible] = useState(false);
    const workspace_slug = useAppSelector((state) => state.app.workspace_slug);
    const currentUser = useAppSelector((state) => state.authentication.currentUser);
    const { data: roles } = useGetRolesQuery();

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        setIsVisible(true);
    }

    if(props.superuserOnly && !currentUser.is_superuser) return <></>
    if (!CanChange(currentUser, roles?.results, workspace_slug, props.action, props.model)) return <></>

    return <div className={`${props.className} open-form-btn`}>
            <Button onMouseDown={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => e.stopPropagation()} onClick={handleClick}>
                <i style={{marginRight: props.showLabel ? '8px' : '0px'}} className={`pi pi-${props.action === 'update' ? 'pencil' : 'plus'}`}></i>
                {props.showLabel ? ` ${props.header}` : ''}
            </Button>
            <Dialog maximizable style={{minWidth: props.minWidth ? props.minWidth : '40vw'}} header={props.header} visible={isVisible} draggable={false} onHide={() => {setIsVisible(false);}} >
                {React.cloneElement(props.form, { closeModal: () => setIsVisible(false) })}
            </Dialog>
    </div>;
}

export default OpenFormButton;
