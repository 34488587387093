import './style.scss';

import { Button } from "primereact/button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";
import { Record } from "../records/slice";
import { User } from "../users/slice";
import UserAvatar from "./userAvatar";
import dayjs from 'dayjs';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useAppSelector } from '../hooks';
import { useDeleteRecordMutation } from '../records/slice';

const RecordLogs: React.FC<{ users: User[], logs: Record[] }> = props => {
    const [deleteRecord] = useDeleteRecordMutation();
    const currentUser = useAppSelector((state) => state.authentication.currentUser);

    return <div className='p-d-flex p-flex-column'>
        {props.logs?.map((log: Record) => {
            const user = props.users?.find((u: User) => u.id === log.user);
            if (!user) return null;
            return <div className='log-item p-d-flex p-jc-between p-ai-center'>
                <div className='p-d-flex p-ai-center title'>
                    <UserAvatar size="large" userId={log.user} />
                    <div>{user?.username} s'est connecté</div>
                </div>
                <div className='date'>
                    <div>{dayjs(log.created).format('DD/MM/YYYY')}</div>
                    <div>{dayjs(log.created).format('HH:mm')}</div>
                </div>
                {
                    currentUser.is_superuser ?
                        <div className='action'>
                            <Button onClick={
                                () => deleteRecord(log.id)
                            }><FontAwesomeIcon icon={faTrash} /></Button>
                        </div> : null
                }
            </div>
        })}
    </div>;
}

export default RecordLogs;
