import { Workspace } from '../workspaces/slice';
import { createSlice } from '@reduxjs/toolkit';
import { sliceFactory } from '../factory/slice';
import { File } from "../files/slice";

export interface Document {
    created: Date,
    type: string,
    weight: number,
    step: string,
    name: string,
    id: string,
    link: string,
    created_by: string,
    modified: Date,
    file: string | File | null,
    sent: boolean,
    disabled: boolean,
    hidden: boolean,
    workspace: Workspace | null
}

export interface DocumentState {
    documentInEdition: Document | null
}

export const initialState: DocumentState = {
    documentInEdition: null
}

export const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        setDocumentInEdition: (state, payload:{payload:Document|null}) => {
            state.documentInEdition = payload.payload;
        }
    }
});

export const documentAPISlice = sliceFactory<Document>({
    reducerName: 'documentAPI',
    endpoint: '/documents/',
    name: 'Document'
});


export default documentSlice.reducer;
export const { setDocumentInEdition } = documentSlice.actions

export const {
    useGetDocumentsQuery,
    useGetDocumentQuery,
    useDeleteDocumentMutation,
    useUpdateDocumentMutation,
    useCreateDocumentMutation,
} = documentAPISlice