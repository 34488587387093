import './style.scss';

import { TabPanel, TabView } from 'primereact/tabview';
import { User, setUserInEdition, useGetUsersQuery } from './slice';
import { faAdd, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../hooks';

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InviteForm from './invite';
import UserForm from './form';
import UserTable from './userTable';
import { useState } from 'react';

const OvermindUsersList = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const { data: users } = useGetUsersQuery();
    const dispatch = useAppDispatch();
    const [showForm, setShowForm] = useState<boolean>(false);
    const [showInviteForm, setShowInviteForm] = useState<boolean>(false);
    const currentUser = useAppSelector(state => state.authentication.currentUser);

    const invite= () => {
        setShowInviteForm(true);
    }

    const createNew = () => {
        dispatch(setUserInEdition(null));
        setShowForm(true);
    }

    return <div style={{ width: '100%', backgroundColor: '#F1F1F1', minHeight: '100%' }}>
        <UserForm mode="overmind" show={showForm} onClose={() => setShowForm(false)} />
        <InviteForm mode="overmind" show={showInviteForm} onClose={() => setShowInviteForm(false)} />
        <div style={{ width: '100%', padding: '64px' }}>
            <div style={{ display: 'flex', gap:'8px', justifyContent: 'flex-end', marginBottom: '16px' }}>
                <Button onClick={createNew}><FontAwesomeIcon icon={faAdd}/>&nbsp;&nbsp;Créer un utilisateur</Button><br/>
                <Button onClick={invite}><FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;Inviter un utilisateur</Button>
            </div>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Équipe partenaire">
                    <UserTable group users={users?.results.filter((user: User) => user.partner)} />
                </TabPanel>
                {currentUser.is_superuser&& <TabPanel header="Équipe superviseur">
                    <UserTable users={users?.results.filter((user: User) => user.is_superuser)} />
                </TabPanel>}
            </TabView>
        </div>
    </div>
}

export default OvermindUsersList;

