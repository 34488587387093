import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { Informations, Workspace } from "./slice";
import { Partner, useGetPartnersQuery } from "../partners/slice";
import { useAppDispatch, useAppSelector } from '../hooks';
import { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { addNotification } from '../notifications/slice';
import axios from 'axios';
import { getAPIUrl } from "../helper";
import slugify from 'slugify';

const initialInfoState = {
    duplicable: false,
    start_date: new Date(),
    partenaire: "",
};

export const DuplicateWorkspaceForm = (props: { refetchWorkspaces: Function, wsOrigin: Workspace | undefined, closeModal: Function, }) => {
    const [slug, setSlug] = useState("");
    const [name, setName] = useState("");
    const [slugHasBeenSetByUser, setSlugHasBeenSetByUser] = useState<Boolean>(false);
    const [info, setInfo] = useState<Pick<Informations, 'duplicable' | 'start_date' | 'partenaire'>>(initialInfoState);
    const [partner, setPartner] = useState<string>("");
    const [creating, setCreating] = useState<boolean>(false);
    const { data: partners } = useGetPartnersQuery();
    const currentUser = useAppSelector(state => state.authentication.currentUser)
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!slugHasBeenSetByUser) {
            setSlug(slugify(name, {
                lower: true,
                strict: true,
                locale: 'fr',
                trim: true,
                replacement: '_',
            }));
        }
    }, [name, slugHasBeenSetByUser]);

    useEffect(() => {
        if (props.wsOrigin !== undefined) {
            setInfo(props.wsOrigin.informations);
        }
    }, [props.wsOrigin]);

    useEffect(() => {
    if(!currentUser.is_superuser) {
        const partner:Partner|undefined = partners?.results.find((p) => p.id === currentUser.partner);
        setPartner(partner?.id || '');
    }

    }, [currentUser, partners]);


    const handleChangeCheckbox = ({ target, checked }: CheckboxChangeEvent ) => {
        setInfo((prev) => ({
            ...prev,
            [target.id]: checked,
        }));
    }

    const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement> | CalendarChangeEvent ) => {
        setInfo((prev) => ({
            ...prev,
            [target.id]: target.value,
        }));
    }
    const handleCreate = () => {
        setCreating(true)
        axios.post(`${getAPIUrl()}/workspaces/duplicate`,
            {
                "slug": props.wsOrigin,
                "new_slug": slug,
                "new_name": name,
                "partner": partner,
                "informations": info
            },
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('access_token')}`
                }
            }
        ).then(() => {
            setCreating(false)
            dispatch(addNotification({ type: 'info', message: 'Projet dupliqué' }));
            props.refetchWorkspaces();
            props.closeModal();
        })
        .catch((err) => {
            setCreating(false)
            dispatch(addNotification({ type: 'error', message: 'Impossible de dupliquer le projet' }));
            console.log(err);
        })
    }

    const partnerOptions: { [key: string]: string |  undefined }[] = [];

    partners?.results.forEach(p => {
        partnerOptions.push({
            value: p.id,
            label: p.name
        });
    });

    return <div>
        <div className="p-fluid">
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="slug">Dupliquer </label>
                <div className={'p-col-12 p-md-10'}>
                    <InputText id="slug" value={props.wsOrigin?.slug} readOnly /><br />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="new_name">Nom</label>
                <div className={'p-col-12 p-md-10'}>
                    <InputText id="new_name" onChange={e => setName(e.target.value)} value={name} /><br />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="new_slug">Code</label>
                <div className={'p-col-12 p-md-10'}>
                    <InputText id="new_slug" onChange={(e) => { setSlugHasBeenSetByUser(true); setSlug(e.target.value) }} value={slug} /><br />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="start_date">Date de début</label>
                <div className="p-col-12 p-md-10">
                    <Calendar id="start_date" onChange={handleChange} value={info.start_date ? new Date(info.start_date) : null} dateFormat="dd/mm" />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="partenaire">Partenaire</label>
                <div className="p-col-12 p-md-10">
                    {/* <InputText id="partenaire" type="text" value={partner} onChange={setPartner} /> */}
                    <Dropdown disabled={!currentUser?.is_superuser} value={partner} options={partnerOptions} onChange={e => {
                        setPartner(e.value);
                    }} />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="duplicable">Duplicable</label>
                <div className={'p-col-12 p-md-10'}>
                    <Checkbox id='duplicable' onChange={handleChangeCheckbox} checked={info.duplicable} />
                </div>
            </div>
        </div>
        {
            creating ?
                <div className="loader-wrapper">
                    <div className="loader"></div>
                </div> 
                : <Button onClick={handleCreate} >{'Sauvegarder'} </Button>
        }
    </div>;
}
