import './style.scss';

import { authenticate, resetPassword } from './slice';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { addNotification } from '../notifications/slice';
import { useAppDispatch } from '../hooks';

const ResetPasswordForm: React.FC<{}> = props => {

    const [error, setError] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const dispatch = useAppDispatch();

    const sendResetPassword = () => {
        setError(false);
        if(confirmPassword !== password) {
            dispatch(addNotification({
                type: 'error',
                message: 'Les mots de passe sont différents'
            }));
            return;
        }
        if(password.length < 6) {
            dispatch(addNotification({
                type: 'error',
                message: ' Le mot de passe doit contenir au moins 6 caractères',
            }));
            return;
        }
        dispatch(resetPassword({
            token: token,
            password: password
        })).then((data:any) => {
            if(!data.error) {
                const email = data.payload.data.email;
                dispatch(authenticate({
                    username:email,
                    password: password
                }));
            }
        })
    }

    useEffect(() => {
        // Get the URLSearchParams object containing all the URL parameters
        const searchParams = new URLSearchParams(location.search);

        // Get the value of the 'token' parameter from the URL
        const urlToken = searchParams.get('token');

        // If the 'token' parameter exists in the URL, set it using useState
        if (urlToken) {
            setToken(urlToken);
            // Perform any actions you need to with the token here
        }
    }, [location.search]);

    return <form className="login-form" onSubmit={e => {
        e.preventDefault();
        sendResetPassword();
    }}>
        <div className="reset-password-form-wrapper">
            <div className="welcome">{'Changement de mot de passe'}</div>
            <div className="welcome-text">Merci de saisir votre nouveau mot de passe ci-dessous</div>
            <div className="p-field">
                <label htmlFor="password" className="p-d-block">Mot de passe</label>
                <Password autoFocus className={error ? 'p-invalid' : ''}feedback={false} autoComplete="password" id="password" value={password} onChange={(e) => {setError(false); setPassword(e.target.value)}} />
            </div>
            <div className="p-field">
                <label htmlFor="password" className="p-d-block">Confiramtion du mot de passe</label>
                <Password className={error ? 'p-invalid' : ''} feedback={false} autoComplete="password" id="confirm_password" value={confirmPassword} onChange={(e) => {setError(false); setConfirmPassword(e.target.value)}} />
            </div>
            <Button style={{ marginTop: '8px' }} onSubmit={sendResetPassword}
                icon="pi pi-arrow-right" iconPos="right" label={'Changer mon mot de passe'}
            />
            <div style={{ marginTop: '20px' }} className="p-field">
                <Button severity='secondary' type="button" text onClick={() => history.push("/")}>Retourner au login</Button>
            </div>
        </div>
    </form>;
}

export default ResetPasswordForm;

