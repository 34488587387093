import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { File } from '../files/slice';
import { addNotification } from '../notifications/slice';
import axios from 'axios';
import { getAPIUrl } from '../helper';
import { sliceFactory } from '../factory/slice';

export interface UserFunctionWorkspace {
    function: string,
    workspace: string,
}

export interface UserRoleWorkspace {
    role: string,
    workspace: string,
}

export interface User {
    created: Date
    email: string
    phone_number: string
    first_name: string
    function: string
    generic_groups: string[]
    id: string
    avatar: File | string | undefined
    is_superuser: boolean
    has_overmind_access: boolean
    last_name: string
    modified: Date
    roles: string[]
    username: string
    password?: string
    partner: string | null
    user_role_workspaces: UserRoleWorkspace[]
    user_function_workspaces: UserFunctionWorkspace[]
}

export interface UserState {
    userInEdition: User | null
}

export const initialState: UserState = {
    userInEdition: null
}

export  const invitation = createAsyncThunk(
    'invitation',
    async (args: {
        email: string,
        firstname: string | null,
        lastname: string | null,
        phone: string | null,
        workspace_slug: string | null,
        partner: string| null,
        role: string | null,
        function: string| null,
        function_label: string| null,
    }, { dispatch }) => {
        try {
            const response = await axios.post(`${getAPIUrl()}/invite/`, args, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('access_token')}`
                }
            });
            dispatch(addNotification({
                type: 'success',
                message: 'Invitation envoyée avec succès',
                options: {
                    draggable: true
                }
            }))
            return response
        } catch (err: any) {
            switch (err.response.status) {
                case 401:
                    dispatch(addNotification({
                        type: 'error',
                        message: err.response.data,
                        options: {
                            draggable: true
                        }
                    }))
                    break;
                default:
                    dispatch(addNotification({
                        type: 'error',
                        message: "Impossible d'envoyer l'invitation",
                        options: {
                            draggable: true
                        }
                    }))
                    throw err;
            }
        }
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserInEdition: (state, payload: { payload: User | null }) => {
            state.userInEdition = payload.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(invitation.fulfilled, () => {})
    }
});

export const userAPISlice = sliceFactory<User>({
    reducerName: 'userAPI',
    endpoint: '/users/',
    name: 'User'
});


export default userSlice.reducer;
export const { setUserInEdition } = userSlice.actions

export const {
    useGetUsersQuery,
    useGetUserQuery,
    useDeleteUserMutation,
    useUpdateUserMutation,
    useCreateUserMutation,
} = userAPISlice

