import { CanChange, getURLForFile } from "../helper";
import { FunctionComponent, useEffect, useState } from "react";
import { Role, useGetRolesQuery } from "../permissions/slice";
import { User, UserFunctionWorkspace, UserRoleWorkspace, UserState, useCreateUserMutation, useDeleteUserMutation, useUpdateUserMutation } from "./slice";
import { UserFunction, useGetUserFunctionsQuery } from "../user_functions/slice";
import { UserRole, useGetUserRolesQuery } from "../user_roles/slice";

import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { File } from '../files/slice';
import { FileUploadUploadEvent } from 'primereact/fileupload';
import { InputText } from "primereact/inputtext";
import MunityDialog from '../layouts/components/MunityDialog';
import { Password } from "primereact/password";
import { SelectButton } from "primereact/selectbutton";
import SimpleUploader from '../files/SimpleUploader';
import { addNotification } from "../notifications/slice";
import { useAppSelector } from "../hooks";
import { useDispatch } from "react-redux";
import { useGetPartnersQuery } from "../partners/slice";
import { useTranslation } from "react-i18next";

const UserForm: FunctionComponent<{ mode: "overmind" | "workspace", show: boolean, onClose: Function }> = props => {
    const dispatch = useDispatch();
    const [firstname, setFirstname] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const [userFunction, setUserFunction] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [errorField, setErrorField] = useState<string[]>([]);
    const [username, setUsername] = useState<string>("");
    const [partner, setPartner] = useState<string | null>(null);
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [uploadedAvatar, setUploadedAvatar] = useState<File | undefined>(undefined);
    const [userRoleWorkspaces, setUserRoleWorkspaces] = useState<UserRoleWorkspace[]>([]);
    const [userFunctionWorkspaces, setUserFunctionWorkspaces] = useState<UserFunctionWorkspace[]>([]);
    const [userRole, setUserRole] = useState<string | undefined>(undefined);
    const [isSuperuser, setIsSuperuser] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [askDeleteUser, setAskDeleteUser] = useState<boolean>(false);
    const [hasOvermindAccess, setHasOvermindAccess] = useState<boolean>(false);
    const { userInEdition } = useAppSelector<UserState>(state => state.user)
    const currentUser: User = useAppSelector(state => state.authentication.currentUser)
    const { t } = useTranslation();

    const currentWorkspace = useAppSelector(state => state.app.workspace_slug);

    // get workspaces on overmind
    const { data: user_functions } = useGetUserFunctionsQuery();
    const { data: partners } = useGetPartnersQuery();
    const { data: user_roles } = useGetUserRolesQuery();
    const { data: roles } = useGetRolesQuery();

    const [createUser, {error: createErrorPayload, isError: createError, isSuccess: createSuccess }] = useCreateUserMutation();
    const [updateUser, { error: updateErrorPayload, isError: updateError, isSuccess: updateSuccess }] = useUpdateUserMutation();
    const [deleteUser, { isError: deleteError, isSuccess: deleteSuccess }] = useDeleteUserMutation();

    let canDeleteUser = false;
    if (currentWorkspace) {
        canDeleteUser = (
            !!userInEdition &&
            !userInEdition.is_superuser &&
            !userInEdition.has_overmind_access &&
            CanChange(currentUser, roles?.results, currentWorkspace, 'update', 'user')
        )
    }


    useEffect(() => {
        setDisabled(!!currentWorkspace && !currentUser.partner && !CanChange(currentUser, roles?.results, currentWorkspace, 'update', 'user'));
    }, [currentUser, currentWorkspace, roles]);

    useEffect(() => {
        console.log(updateErrorPayload);
        if (updateError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de mettre à jour l\'utilisateur'
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateError, dispatch, t]);

    useEffect(() => {
        if (updateSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Utilisateur mise à jour'
            }));
        }
    }, [updateSuccess, dispatch, t]);

    // Create alert
    useEffect(() => {
        if (createSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Utilisateur créé'
            }));
        }
    }, [createSuccess, dispatch, t]);

    useEffect(() => {
        if (createError) {
            if(createErrorPayload?.data?.username[0] === "A user with that username already exists.") {
                dispatch(addNotification({
                    type: 'error',
                    message: `L'identifiant utilisateur ${username} est déjà utilisé`
                }));
            } else {
                dispatch(addNotification({
                    type: 'error',
                    message: 'Impossible de créer l\'utilisateur'
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createError, dispatch, t]);

    // Create alert
    useEffect(() => {
        if (deleteSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Utilisateur supprimé'
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteSuccess]);

    useEffect(() => {
        if (deleteError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de supprimer l\'utilisateur'
            }));
        }
    }, [deleteError, dispatch]);

    useEffect(() => {
        if (userInEdition) {
            setErrorField([]);
            setFirstname(userInEdition.first_name);
            setLastname(userInEdition.last_name);
            setUsername(userInEdition.username);
            setEmail(userInEdition.email);
            setPhone(userInEdition.phone_number);
            setPartner(userInEdition.partner);
            setUserFunction(userInEdition.function);
            setIsSuperuser(userInEdition.is_superuser);
            setUserRole(userInEdition.generic_groups.length > 0 ? userInEdition.generic_groups.find(gg => {
                const roleIds = user_roles?.results.map((r: UserRole) => r.id);
                return roleIds.includes(gg);
            }) : undefined);
            setHasOvermindAccess(userInEdition.has_overmind_access);
            let _urws = [...userInEdition.user_role_workspaces];
            let hasRight = false;
            const urws = _urws?.map((urw: UserRoleWorkspace) => {
                if (urw.workspace === currentWorkspace) {
                    hasRight = true;
                    // Force right to GESTION if user is a prestataire or a superuser
                    if (
                        (userInEdition.has_overmind_access || userInEdition.is_superuser)
                    ) {
                        return { ...urw, role: roles?.results.find((r: Role) => r.name === 'Gestion')?.id }
                    }
                }
                return urw;
            })
            
            // Add gestion right if user dont have any
            if(
                !hasRight &&
                (userInEdition.has_overmind_access || userInEdition.is_superuser)
            ) {
                urws.push({
                    role: roles?.results.find((r: Role) => r.name === 'Gestion')?.id,
                    workspace: currentWorkspace
                })
            }
            setUserRoleWorkspaces(urws);
            setUserFunctionWorkspaces(userInEdition.user_function_workspaces);
            setUploadedAvatar(undefined);
        } else {
            setErrorField([]);
            setFirstname('');
            setLastname('');
            setUsername('');
            setEmail('');
            setPhone('');
            setUserFunction('');
            if (currentUser.is_superuser || props.mode === "workspace") {
                setPartner(null);
            } else {
                setPartner(currentUser.partner)
            }
            setUserRole(undefined);
            setIsSuperuser(false);
            setHasOvermindAccess(false);
            setUploadedAvatar(undefined);
            setUserFunctionWorkspaces([{
                workspace: '',
                function: ''
            }]);

            // We create an user so set him as user of current workspace
            if (props.mode === 'workspace' && roles) {
                setUserRoleWorkspaces([{
                    workspace: currentWorkspace,
                    role: roles.results.find((role: Role) => role.name === 'Consultation').id
                }]);
            } else {
                setUserRoleWorkspaces([]);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInEdition, props.show, user_roles?.results, user_functions?.results, roles]);


    const saveUser = () => {
        const error = [];
        if (username.length === 0) {
            error.push('username');
            dispatch(addNotification({
                type: 'error',
                message: "L'identifiant est obligatoire",
            }))
        }
        if (newPassword.length > 0 && newPassword !== confirmPassword) {
            error.push('password');
            dispatch(addNotification({
                type: 'error',
                message: "Les mots de passe sont différents",
            }))
        }
        if (error.length > 0) {
            setErrorField(error);
            return false;
        }
        if (!userInEdition) {
            const generic_groups = [];
            if (userRole) generic_groups?.push(userRole);
            const user: Partial<User> = {
                first_name: firstname,
                last_name: lastname,
                username,
                function: userFunction,
                is_superuser: isSuperuser,
                has_overmind_access: hasOvermindAccess,
                user_role_workspaces: userRoleWorkspaces.filter(ws_role => ws_role.workspace !== '' && ws_role.role !== ''),
                user_function_workspaces: userFunctionWorkspaces.filter(
                    ws_role => ws_role.workspace !== '' && ws_role.function !== ''
                ),
                email,
                phone_number: phone,
                partner: partner,
                password: newPassword.length > 0 ? newPassword : undefined,
                generic_groups: generic_groups
            }
            // add fresh uploaded avatar or do not send JSON transcryption
            createUser(user);
        } else {
            const user: Partial<User> = Object.assign({}, userInEdition);
            user.first_name = firstname;
            user.last_name = lastname;
            user.username = username;
            user.function = userFunction;
            user.user_role_workspaces = userRoleWorkspaces.filter(
                ws_role => ws_role.workspace && ws_role.role && ws_role.workspace !== '' && ws_role.role !== ''
            ) || [];
            user.user_function_workspaces = userFunctionWorkspaces.filter(
                ws_role => ws_role.workspace && ws_role.function && ws_role.workspace !== '' && ws_role.function !== ''
            ) || [];
            user.is_superuser = isSuperuser;
            user.has_overmind_access = hasOvermindAccess;
            user.avatar = uploadedAvatar?.id;
            user.email = email;
            user.partner = partner;
            user.phone_number = phone;
            user.password = newPassword.length > 0 ? newPassword : undefined;

            user.generic_groups = [];
            if (userRole) user.generic_groups?.push(userRole);
            updateUser(user);
        }
        return true;
    };

    const userRoles = user_roles?.results.map((f: UserRole) => {
        return {
            value: f.id,
            label: f.label
        };
    }) || [];
    userRoles.unshift({ value: '', label: 'Aucun role' });
    const userFunctions: {
        value: null | string;
        label: string;
    }[] = [{
        value: null,
        label: '-'
    }]
    user_functions?.results.forEach((f: UserFunction) => {
        userFunctions.push({
            value: f.id,
            label: f.label
        });
    });

    const partnerOptions: [{ [key: string]: string | undefined }] = [{
        value: '',
        label: ' - '
    }];
    partners?.results.forEach(p => {
        partnerOptions.push({
            value: p.id,
            label: p.name
        });
    });

    const partnerName = partners?.results.find(p => p.id === partner)?.name;

    return <>
        <MunityDialog canChange={
            !!(!userInEdition?.has_overmind_access && CanChange(currentUser, roles?.results, currentWorkspace, 'update', 'user')) ||
            // !!(userInEdition && userInEdition.has_overmind_access && currentUser.has_overmind_access) ||
            // !!(userInEdition && userInEdition.partner && currentUser.partner) ||
            !!(currentUser.partner) ||
            !!(currentUser.is_superuser)
        } title={'Formulaire utilisateur'} visible={props.show} onSave={saveUser} onHide={props.onClose}>
            {!disabled ?
                <div className="p-d-flex p-jc-center p-m-2">
                    {
                        uploadedAvatar ?
                            <Avatar className="p-mr-2" size="xlarge" image={uploadedAvatar.file} /> :
                            userInEdition?.avatar && (typeof userInEdition.avatar !== "string") ?
                                <Avatar shape="circle" className="p-mr-2" size="xlarge" image={getURLForFile(userInEdition.avatar.file)} /> :
                                <Avatar shape="circle" label={`${userInEdition?.first_name.substring(0, 1) || ''}${userInEdition?.last_name.substring(0, 1) || ''}`} className="p-mr-2" size="large" />
                    }
                    <SimpleUploader
                        maxFileSize={100000000}
                        onUpload={(e: FileUploadUploadEvent) => {
                            setUploadedAvatar(JSON.parse(e.xhr.response));
                        }}
                        label="Changer d'avatar (format carré)"
                        auto
                    />
                </div>
                : null}
            <div className="p-fluid">
                <div className="p-field p-grid">
                    <label htmlFor="firstname4" className="p-col-12 p-md-2">Identifiant</label>
                    <div className="p-col-12 p-md-10">
                        <InputText id="username" disabled={disabled} className={errorField.includes('username') ? 'p-invalid' : ''} type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="firstname4" className="p-col-12 p-md-2">Prénom</label>
                    <div className="p-col-12 p-md-10">
                        <InputText id="firstname" disabled={disabled} type="text" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="firstname4" className="p-col-12 p-md-2">Nom</label>
                    <div className="p-col-12 p-md-10">
                        <InputText id="lastname" disabled={disabled} type="text" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                    </div>
                </div>
                {currentWorkspace &&
                    <div className="p-field p-grid">
                        <label className="p-col-12 p-md-2">Rôle</label>
                        <div className="p-col-12 p-md-10">
                            <Dropdown disabled={disabled} value={userFunctionWorkspaces.find((ufw: UserFunctionWorkspace) => ufw.workspace === currentWorkspace)?.function} options={userFunctions} onChange={e => {
                                let newUserFunctionWorkspaces = userFunctionWorkspaces.slice(0);
                                let newUserFunctionWorkspace: UserFunctionWorkspace = {
                                    function: e.value,
                                    workspace: currentWorkspace
                                };
                                const ufwExists = userInEdition?.user_function_workspaces.find((ufw: UserFunctionWorkspace) => ufw.workspace === currentWorkspace);
                                if (!ufwExists) {
                                    newUserFunctionWorkspaces.push(newUserFunctionWorkspace);
                                } else {
                                    newUserFunctionWorkspaces.forEach((ufw: UserFunctionWorkspace, index: number) => {
                                        if (ufw.workspace === currentWorkspace) {
                                            const copy: UserFunctionWorkspace = Object.assign({}, newUserFunctionWorkspaces[index]);
                                            copy.function = e.value;
                                            newUserFunctionWorkspaces[index] = copy;
                                        }
                                    })
                                }
                                setUserFunctionWorkspaces(newUserFunctionWorkspaces);
                            }} />
                        </div>
                    </div>
                }
                <div className="p-field p-grid">
                    <label htmlFor="" className="p-col-12 p-md-2">Fonction</label>
                    <div className="p-col-12 p-md-10">
                        <InputText disabled={disabled} id="lastname" type="text" value={userFunction} onChange={(e) => setUserFunction(e.target.value)} />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="firstname4" className="p-col-12 p-md-2">Email</label>
                    <div className="p-col-12 p-md-10">
                        <InputText disabled={disabled} id="email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="phone" className="p-col-12 p-md-2">Téléphone</label>
                    <div className="p-col-12 p-md-10">
                        <InputText disabled={disabled} id="phone" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="password" className="p-col-12 p-md-2">Mot de passe</label>
                    <div className="p-col-12 p-md-10">
                        <Password toggleMask disabled={disabled} id="password" className={errorField.includes('password') ? 'p-invalid' : ''} type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="confirm_password" className="p-col-12 p-md-2">Confirmer le mot de passe</label>
                    <div className="p-col-12 p-md-10">
                        <Password toggleMask disabled={disabled} id="confirm_password" className={errorField.includes('password') ? 'p-invalid' : ''} type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                    </div>
                </div>
                {props.mode === "overmind" &&
                    <div className="p-field p-grid">
                        <label className="p-col-12 p-md-2">Partenaire</label>
                        <div className="p-col-12 p-md-10">
                            {currentUser.is_superuser ?
                                <Dropdown value={partner} options={partnerOptions} onChange={e => {
                                    setPartner(e.value);
                                }} />
                                :
                                <InputText disabled id="partner" value={partnerName} />
                            }
                        </div>
                    </div>
                }
                {currentWorkspace &&
                    <div className="p-field p-grid" style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                        <label className="p-col-12 p-md-2">Droit</label>
                        <div className="p-col-12 p-md-10">
                            <SelectButton disabled={disabled || userInEdition?.has_overmind_access || userInEdition?.is_superuser} value={userRoleWorkspaces.find((urw: UserRoleWorkspace) => {
                                return urw.workspace === currentWorkspace
                            })?.role} options={roles?.results.map((r: Role) => {
                                return {
                                    name: r.name,
                                    value: r.id
                                };
                            })} itemTemplate={role => <div key={role.id}>{role.name}</div>} onChange={(e) => {
                                let newUserRoleWorkspaces = userRoleWorkspaces.slice(0);
                                let newUserRoleWorkspace = Object.assign({}, newUserRoleWorkspaces[0]);
                                newUserRoleWorkspace.role = e.value;
                                newUserRoleWorkspace.workspace = currentWorkspace;
                                newUserRoleWorkspaces[0] = newUserRoleWorkspace;
                                setUserRoleWorkspaces(newUserRoleWorkspaces);
                            }} />
                        </div>
                    </div>
                }
                {
                    currentUser.is_superuser && props.mode === "overmind" &&
                    <div className="p-field p-grid">
                        <div className="p-col-12 p-md-2">
                            <Checkbox inputId="is_superuser" value={true} onChange={() => setIsSuperuser(!isSuperuser)} checked={isSuperuser} />
                        </div>
                        <label htmlFor="is_superuser" className="p-col-12 p-md-10">Est superviseur</label>
                    </div>
                }
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row-reverse",
                marginTop: "44px"
            }}>
                {
                    canDeleteUser && <Button onClick={() => setAskDeleteUser(true)} severity="danger" raised>Supprimer l'utilisateur</Button>
                }
            </div>
        </MunityDialog >
        <ConfirmDialog acceptLabel="Oui" rejectLabel="Non" visible={askDeleteUser} onHide={() => setAskDeleteUser(false)} message="Êtes-vous sûr de vouloir supprimer l'utilisateur ?"
            header="Confirmation" icon="pi pi-exclamation-triangle" accept={() => {
                props.onClose();
                deleteUser(userInEdition?.id);
            }} reject={() => setAskDeleteUser(false)} />
    </>;
}

export default UserForm;

