import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

import { Route, Switch } from 'react-router';

import OvermindNavbar from './components/Navbar';
import OvermindNavbarCenter from '../common/OvermindNavbarCenter';
import OvermindNavbarLeft from '../common/OvermindNavbarLeft';
import OvermindNavbarRight from './components/NavbarComponents/NavbarRight';
import OvermindSidebar from '../common/overmindSidebar';
import OvermindUserList from '../users/overmindIndex';
import Partners from '../partners/list';
import WorkspacesList from '../workspaces/list';
import { setWorkspace } from '../app/slice';
import { useAppDispatch } from '../hooks';
import { useEffect } from 'react';

const Overmind = (props: {}) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setWorkspace(null));
    }, [dispatch])

    return <>
        <OvermindNavbar
            leftPart={OvermindNavbarLeft}
            centerPart={OvermindNavbarCenter}
            rightPart={OvermindNavbarRight}
        />
        <div className="layout-overmind">
            <OvermindSidebar />
            <div className="main">
                <Switch>
                    <Route path="/workspaces" component={WorkspacesList} />,
                    <Route path="/users" component={OvermindUserList} />,
                    <Route path="/partners" component={Partners} />
                    <Route path="/" component={WorkspacesList} />,
                </Switch>
            </div>
        </div>
    </>
}

export default Overmind;