import './style.scss';

import { Note, useDeleteNoteMutation } from "./slice";

import DeleteButton from "../common/confirmationDeleteButton";
import { NoteForm } from "./form";
import OpenFormButton from "../common/openFormButton";
import TagText from '../common/tagtext';
import Tags from "../common/tags";
import UserAvatar from "../common/userAvatar";

const NotesList = (props: {notes: Array<Note>})  => {
    const [deleteNote] = useDeleteNoteMutation();

    return <div className={'notes-list-wrapper'}>
        <h1 className='title'>Notes</h1>
        <div className={'notes-list'}>
            {props.notes?.map((note: Note) => {
                return <div key={note.id} className='note-item'>
                    <div className='note-title title'>{note.name}</div>
                    <div className="tag-wrapper">
                        {
                            note.generic_groups.length === 1 ?
                                <Tags key={note.generic_groups[0]} groupId={note.generic_groups[0]} />
                                : null
                        }
                        {
                            note.generic_groups.length > 1 ?
                                <TagText text={`${note.generic_groups.length} thèmes séléctionnés`} />
                                : null
                        }
                    </div>
                    <div className='note-description description'>{note.description}</div>
                    <UserAvatar userId={note.created_by} />
                    <div className='relative-open-form-btn action-buttons'>
                        <OpenFormButton action="update" model="note" header={'Editer une note'} form={<NoteForm note={note} closeModal={() => null} step={null}/>} />
                        <DeleteButton model="note" deleteFunction={() => deleteNote(note.id)}/>
                    </div>
                </div>
            })}
        </div>
    </div>
}

export default NotesList;
