import { Avatar } from "primereact/avatar";
import { getURLForFile } from "../helper";
import { useGetUserQuery } from "../users/slice";

const UserAvatar = (props: { userId: string, size?: 'normal' | 'large' | 'xlarge' | undefined}) => {
    const { data: user } = useGetUserQuery(props.userId);

    return <div className='avatar-wrapper'>
        {
            user?.avatar && (typeof user.avatar !== "string") ?
                <Avatar shape="circle" className="p-mr-2" size={props.size || "large"} image={getURLForFile(user.avatar.file)} /> :
                <Avatar shape="circle" label={`${user?.first_name.substring(0,1) || '?'}${user?.last_name.substring(0,1) || ''}`} className="p-mr-2" size="large" />
        }
    </div >
}

export default UserAvatar;