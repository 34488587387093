import { configureStore } from '@reduxjs/toolkit'
import { Reducer, AnyAction, Middleware, Dispatch } from 'redux';

import appReducer from './app/slice';
import authenticationReducer from './authentication/slice';
import notificationReducer from './notifications/slice';
import layoutReducer from './layouts/slice';
import userReducer, { userAPISlice } from './users/slice';
import { roleAPISlice } from './permissions/slice';
import { settingAPISlice } from './settings/slice';
import workspaceReducer , { workspaceAPISlice } from './workspaces/slice';
import { articleAPISlice } from './articles/slice';
import { documentAPISlice } from './documents/slice';
import { documentTypesAPISlice } from './documents/typesSlice';
import { eventAPISlice } from './events/slice';
import { noteAPISlice } from './notes/slice';
import { stepAPISlice } from './steps/slice';
import { phaseAPISlice } from './phases/slice';
import { taskAPISlice } from './tasks/slice';
import { internAPISlice } from './interns/slice';
import { userRoleAPISlice } from './user_roles/slice';
import { userFunctionAPISlice } from './user_functions/slice';
import { generic_groupAPISlice } from './generic_groups/slice';
import { themeAPISlice } from './themes/slice';
import { recordAPISlice } from './records/slice';
import partnerReducer, { partnerAPISlice } from './partners/slice';


export const munityReducer: {[key: string]:Reducer<any, AnyAction>} = {
    app: appReducer,
    layout: layoutReducer,
    authentication: authenticationReducer,
    notification: notificationReducer,
    workspace: workspaceReducer,
    partner: partnerReducer,
    user: userReducer,
    [roleAPISlice.reducerPath] : roleAPISlice.reducer,
    [userAPISlice.reducerPath] : userAPISlice.reducer,
    [workspaceAPISlice.reducerPath] : workspaceAPISlice.reducer,
    [settingAPISlice.reducerPath] : settingAPISlice.reducer
}

export const munityMiddleware: Middleware<any, any, Dispatch<AnyAction>>[] = [
    roleAPISlice.middleware,
    settingAPISlice.middleware,
    userAPISlice.middleware,
    workspaceAPISlice.middleware
]

/********************************
 * Custom reducers and middleware
********************************/

// munityReducer[storeKey] = reducer;
munityReducer[articleAPISlice.reducerPath] = articleAPISlice.reducer;
munityReducer[documentAPISlice.reducerPath] = documentAPISlice.reducer;
munityReducer[documentTypesAPISlice.reducerPath] = documentTypesAPISlice.reducer;
munityReducer[eventAPISlice.reducerPath] = eventAPISlice.reducer;
munityReducer[noteAPISlice.reducerPath] = noteAPISlice.reducer;
munityReducer[taskAPISlice.reducerPath] = taskAPISlice.reducer;
munityReducer[stepAPISlice.reducerPath] = stepAPISlice.reducer;
munityReducer[phaseAPISlice.reducerPath] = phaseAPISlice.reducer;
munityReducer[eventAPISlice.reducerPath] = eventAPISlice.reducer;
munityReducer[roleAPISlice.reducerPath] = roleAPISlice.reducer;
munityReducer[internAPISlice.reducerPath] = internAPISlice.reducer;
munityReducer[userRoleAPISlice.reducerPath] = userRoleAPISlice.reducer;
munityReducer[userFunctionAPISlice.reducerPath] = userFunctionAPISlice.reducer;
munityReducer[generic_groupAPISlice.reducerPath] = generic_groupAPISlice.reducer;
munityReducer[themeAPISlice.reducerPath] = themeAPISlice.reducer;
munityReducer[recordAPISlice.reducerPath] = recordAPISlice.reducer;
munityReducer[partnerAPISlice.reducerPath] = partnerAPISlice.reducer;

// munityMiddleware.push(middleware);
munityMiddleware.push(articleAPISlice.middleware);
munityMiddleware.push(documentAPISlice.middleware);
munityMiddleware.push(documentTypesAPISlice.middleware);
munityMiddleware.push(eventAPISlice.middleware);
munityMiddleware.push(noteAPISlice.middleware);
munityMiddleware.push(taskAPISlice.middleware);
munityMiddleware.push(stepAPISlice.middleware);
munityMiddleware.push(phaseAPISlice.middleware);
munityMiddleware.push(eventAPISlice.middleware);
munityMiddleware.push(roleAPISlice.middleware);
munityMiddleware.push(internAPISlice.middleware);
munityMiddleware.push(userFunctionAPISlice.middleware);
munityMiddleware.push(userRoleAPISlice.middleware);
munityMiddleware.push(generic_groupAPISlice.middleware);
munityMiddleware.push(themeAPISlice.middleware);
munityMiddleware.push(recordAPISlice.middleware);
munityMiddleware.push(partnerAPISlice.middleware);

/********************************
 * Custom reducers and middleware
********************************/

const store = configureStore({
    reducer: munityReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware()
            .concat(munityMiddleware)
    })

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch