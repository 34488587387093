import './style.scss';

import { Document, useDeleteDocumentMutation } from "./slice";
import { DocumentType, useDeleteDocumentTypeMutation } from "./typesSlice";
import { User, useGetUsersQuery } from "../users/slice";
import { getAPIUrl, getWorkspaceEndpoint } from '../helper';
import { useEffect, useRef, useState } from "react";

import { Button } from 'primereact/button';
import { CanChange } from '../helper';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import DeleteButton from "../common/confirmationDeleteButton";
import { Dialog } from "primereact/dialog";
import { DocumentForm } from "./form";
import { DocumentTypeForm } from "./typeForm";
import { File } from "../files/slice";
import OpenFormButton from "../common/openFormButton";
import { confirmPopup } from 'primereact/confirmpopup';
import { filesize } from "filesize";
import folderUp from '../assets/folder-up.png';
import { groupBy } from 'lodash';
//@ts-ignore
import moment from 'moment';
import { useAppSelector } from '../hooks';
import { useGetRolesQuery } from "../permissions/slice";

const GEDDocumentsList = (props: { documents: Array<Document>, documentTypes: Array<DocumentType>, step: string | null, isDropArea: boolean }) => {
    const [groupedDocument, setGroupedDocument] = useState<{ [id: string]: Document[] }>();
    const { data: roles } = useGetRolesQuery();
    const { data: users } = useGetUsersQuery();
    const [deleteType] = useDeleteDocumentTypeMutation();
    const [deleteDoc ] = useDeleteDocumentMutation();
    const [tab, setTab] = useState<number>(0);
    const [type, setType] = useState<DocumentType | null>(null);
    const [document, setDocument] = useState<Document | null>(null);
    const [showDocumentPopup, setShowDocumentPopup] = useState<boolean>(false);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [dragging, setDragging] = useState<boolean>(false);
    const inputFileButton = useRef<HTMLInputElement>(null);
    const { JWTaccess } = useAppSelector(state => state.authentication)

    const currentUser: User = useAppSelector((state) => state.authentication.currentUser);
    const workspace_slug = useAppSelector((state) => state.app.workspace_slug);
    const canUpload = (
        (!props.isDropArea && currentUser.is_superuser) ||
        (props.isDropArea && CanChange(currentUser, roles?.results, workspace_slug, 'create', 'documenttype'))
    );
    // const canUpload = currentUser.is_superuser;

    const handleDrag = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            handleUpload(files[0]);
        }
    };
    const handleUpload = ((file: any) => {
        const formData = new FormData();
        formData.append('file', file);
        fetch(`${getAPIUrl()}${getWorkspaceEndpoint('/files/')}`, {
            method: 'POST',
            body: formData,
            headers: {
                'authorization': `Bearer ${JWTaccess}`
            }
        },)
            .then(response => response.json())
            .then((data: File) => {
                setUploadedFile(data);
                setDocument(null);
                setShowDocumentPopup(true);
            });
    })

    const acceptDelete = (type_id: string) => deleteType(type_id);

    const confirmDelete = (type: DocumentType) => {
        confirmPopup({
            message: 'Voulez-vous vraiment continuer ' + type.name + '? ',
            icon: 'pi pi-exclamation-triangle',
            accept: () => acceptDelete(type.id),
            reject: () => { },
            rejectLabel: 'Non',
            acceptLabel: 'Oui'
        });
    };

    useEffect(() => {
        setGroupedDocument(groupBy(props.documents, 'type'));
    }, [props.documents]);

    useEffect(() => {
        setType(props.documentTypes ? props.documentTypes[tab] : null)
    }, [props.documentTypes, tab])

    if (!props.documentTypes) return null;
    if (!canUpload && props.documentTypes.length === 0) return null;

    return <div className={`documents-list-wrapper ${props.isDropArea ? 'drop-area' : ''}`}>
        <h1 className='title'>{
            props.isDropArea ? 'Déposez ici vos documents'
                : 'Documents à consulter (téléchargement)'
        }</h1>
        <div className={'documents-list'}>
            <div className="documents-tabs">
                {groupedDocument && props.documentTypes?.map((type: DocumentType, index: number) => {
                    return <div key={type.id}>
                        <div className={`tab ${index === tab && 'active'}`} onClick={() => setTab(index)}>
                            <div className="name">{type.name}</div>
                            {
                                currentUser.is_superuser ?
                                    <div className="cross" onClick={() => confirmDelete(type)}>
                                        x
                                    </div> : null
                            }
                            <div className="edit-icon">
                                {/* <img src={edit} /> */}
                                <OpenFormButton superuserOnly action="update" model="workspace" header={'Editer une zone d\'envoi de document'} form={<DocumentTypeForm isGed={!props.isDropArea} type={type} closeModal={() => null} step={null} />} />
                            </div>
                        </div>
                    </div>
                })}
            </div>
            {type &&
                <>
                    {(props.isDropArea || canUpload) && <div className="drag-and-drop">
                        <div className="name">
                            {props.documentTypes && props.documentTypes[tab]?.name}
                        </div>
                        <div className="description">
                            {props.documentTypes && props.documentTypes[tab]?.description}
                        </div>
                        <div className="drop-area">
                            <div className="dropZone"
                                onDragEnter={() => setDragging(true)}
                                onDragStart={() => setDragging(true)}
                                onDrop={handleDrop}
                                onDragOver={handleDrag}
                                onDragLeave={() => setDragging(false)}
                                onClick={() => {
                                    inputFileButton?.current?.click();
                                }}
                            />
                            <div className={`zone ${dragging ? 'dragging' : ''}`}
                            >
                                <div className="drop-icon">
                                    <img src={folderUp} alt="drop-icon" />
                                </div>
                                <div className="drop-text">
                                    Glisser-déposer votre document ou <strong>Parcourir</strong>
                                </div>
                                <input
                                    onChange={e => {
                                        if (e.target.files && e.target.files.length > 0) {
                                            handleUpload(e.target.files[0]);
                                        }
                                    }}
                                    type="file"
                                    ref={inputFileButton}
                                />
                            </div>
                        </div>
                    </div>}
                    <div className="document-list">
                        <DataTable emptyMessage={<div style={{ fontStyle: 'italic', textAlign: 'center' }}>Aucun fichier</div>} value={props.documents?.filter((d: Document) => d.type === type?.id)} responsiveLayout="scroll">
                            <Column body={(d: Document) => d.name} header="Document"></Column>
                            <Column body={(d: Document) => moment(d.created).format('DD/MM/YYYY HH:mm')} header="Date de création"></Column>
                            <Column body={(d: Document) => <>{(typeof d.file === 'object' && d.file?.size) ? filesize(d.file.size) : null}</>} header="Taille de fichier"></Column>
                            {
                                props.isDropArea ?
                                    <Column body={(d: Document) => {
                                        const user: User = users?.results.find((u: User) => u.id === d.created_by);
                                        return `${user?.first_name || ''} ${user?.last_name || ''}`
                                    }} header="Interlocuteur"></Column> : null
                            }
                            <Column body={(d: Document) => <div className="actions">
                                <Button className="download" onClick={e => typeof d.file === 'object' && window.open(`${window.location.protocol}//api.${window.location.hostname}` + d.file?.file, "_blank")}>
                                    Télécharger
                                </Button>
                                <OpenFormButton superuserOnly={!props.isDropArea} className="in-dom" action="update" model="document" header={'Editer un document'} form={<DocumentForm uploadedFile={null} document={d} type={type?.id || ''} closeModal={() => null} step={null} />} />
                                {canUpload ?
                                    <DeleteButton model="document" deleteFunction={() => deleteDoc(d.id)} />
                                    : null}
                            </div>}></Column>
                        </DataTable>
                    </div>
                </>
            }
            <Dialog maximizable style={{ minWidth: '40vw' }} header={'Ajouter un document de type "' + type?.name + '"'} visible={showDocumentPopup} draggable={false} onHide={() => { setShowDocumentPopup(false); }} >
                <DocumentForm uploadedFile={uploadedFile} document={document} type={type?.id || ''} closeModal={() => setShowDocumentPopup(false)} step={props.step} />
            </Dialog>
        </div>
    </div>
}

export default GEDDocumentsList;

