import React, { MouseEventHandler, useEffect } from "react";
import { Workspace, useDeleteWorkspaceMutation } from "../slice";
import { faCopy, faEdit } from '@fortawesome/free-regular-svg-icons';

import { Button } from "primereact/button";
import DeleteButton from "../../common/confirmationDeleteButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayPanel } from 'primereact/overlaypanel';
import { User } from "../../users/slice";
import { addNotification } from "../../notifications/slice";
import archived from "../../assets/archiver.png";
import unarchived from "../../assets/unarchiver.png";
import { useDispatch } from "react-redux";

const ActionsOverlay: React.FC<{
    workspace: Workspace,
    currentUser: User,
    reff: React.RefObject<OverlayPanel>,
    onEdit: MouseEventHandler<HTMLButtonElement>,
    onDuplicate: MouseEventHandler<HTMLButtonElement>,
    onArchived: MouseEventHandler<HTMLButtonElement>
    onUnarchived: MouseEventHandler<HTMLButtonElement>
}> = props => {
    const [deleteWorkspace, { isError: deleteError, isSuccess: deleteSuccess }] = useDeleteWorkspaceMutation();
    const dispatch = useDispatch();

    const is_superuser= props.currentUser.is_superuser;

    // Delete alert
    useEffect(() => {
        if (deleteError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de supprimer le projet'
            }));
        }
    }, [deleteError, dispatch]);

    useEffect(() => {
        if (deleteSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Projet supprimé'
            }));
        }
    }, [deleteSuccess, dispatch]);

    return <OverlayPanel ref={props.reff} id="overlay_panel" className="actions" >
        { is_superuser ? <DeleteButton showLabel model="workspace" deleteFunction={() => deleteWorkspace(props.workspace.slug)} /> : null }
        <Button onClick={props.onEdit}>
            <FontAwesomeIcon icon={faEdit} />&nbsp;&nbsp;Editer
        </Button>
        { !props.workspace.archived ? <Button style={{padding:'4px 11px'}} onClick={props.onArchived}>
            <img style={{width:'24px',height:'24px', fill:'white'}} src={archived} alt="Archived" />&nbsp;&nbsp;Archiver
        </Button> : null}
        { props.workspace.archived ? <Button style={{padding:'4px 11px'}} onClick={props.onUnarchived}>
            <img style={{width:'24px',height:'24px', fill:'white'}} src={unarchived} alt="Unarchived" />&nbsp;&nbsp;Désarchiver
        </Button> : null}
        {props.workspace.informations?.duplicable &&
            <Button onClick={props.onDuplicate}>
                <FontAwesomeIcon icon={faCopy} />&nbsp;&nbsp;Dupliquer
            </Button>}


    </OverlayPanel>
}

export default ActionsOverlay;