import { Document, useCreateDocumentMutation, useDeleteDocumentMutation, useUpdateDocumentMutation } from "./slice";
import { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { File } from '../files/slice';
import { InputText } from "primereact/inputtext";
import { addNotification } from "../notifications/slice";
import {filesize} from "filesize";
import { useAppDispatch } from "../hooks";

const initialDocumentState = {
    id: "",
    name: "",
    type: "",
    step: ""
};

export const DocumentForm = (props: { document: Partial<Document> | null, closeModal: Function, step: string | null, type: string, uploadedFile?: File | null }) => {
    const dispatch = useAppDispatch();

    const [document, setDocument] = useState<Partial<Document>>(initialDocumentState);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [createDocument, { isError: createError, isSuccess: createSuccess }] = useCreateDocumentMutation();
    const [updateDocument, { isError: updateError, isSuccess: updateSuccess }] = useUpdateDocumentMutation();
    const [deleteDocument, { isError: deleteError, isSuccess: deleteSuccess }] = useDeleteDocumentMutation();

    const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setDocument((prev) => ({
            ...prev,
            [target.id]: target.value,
        }));
    }

    const handleCreate = () => {
        document.type = props.type;
        document.file = uploadedFile?.id;
        if (document.name?.length === 0) {
            dispatch(addNotification({
                type: 'error',
                message: 'Le champs nom est obligatoire'
            }));
            return;
        }
        if (!uploadedFile && !document.id) {
            dispatch(addNotification({
                type: 'error',
                message: 'Aucun document envoyé'
            }));
            return;
        }
        if (document.id) {
            updateDocument(document);
        } else {
            createDocument(document);
        }
    }

    useEffect(() => {
        if(props.uploadedFile) {
            setUploadedFile(props.uploadedFile);
        }
    }, [props.uploadedFile])


    useEffect(() => {
        setDocument(props.document || initialDocumentState);
        if (props.step !== null) {
            setDocument((prev) => ({
                ...prev,
                step: props.step ? props.step : ''
            }))
        }
    }, [props.document, props.step])

    // Update alert
    useEffect(() => {
        if (updateError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de mettre à jour le document'
            }));
        }
    }, [updateError, dispatch]);

    useEffect(() => {
        if (updateSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Document mis à jour'
            }));
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateSuccess]);

    // Delete alert
    useEffect(() => {
        if (deleteError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de supprimer le document'
            }));
        }
    }, [deleteError, dispatch]);

    useEffect(() => {
        if (deleteSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Document supprimé'
            }));
            setDocument(initialDocumentState);
        }
    }, [deleteSuccess, dispatch]);

    // Create alert
    useEffect(() => {
        if (createSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Document créé'
            }));
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createSuccess]);

    useEffect(() => {
        if (createError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de créer le document'
            }));
        }
    }, [createError, dispatch]);

    useEffect(() => {
        if(uploadedFile) {
            setDocument((prev) => ({
                ...prev,
                'name': uploadedFile.name,
            }));
        }
    }, [uploadedFile]);


    return <div>
        <div className="p-fluid">
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="name">Nom</label>
                <div className={'p-col-12 p-md-10'}>
                    <InputText id="name" onChange={handleChange} value={document.name} /><br />
                </div>
            </div>
        </div>
        {
            uploadedFile ?
            <div className="document-preview">
                <div className="document-info">
                    <div>
                        Taille : <>{uploadedFile?.size && filesize(uploadedFile.size)}</>
                    </div>
                    <div>
                        Type : {uploadedFile?.type}
                    </div>
                </div>
            </div> : null
        }
        <div className="document-form">
            {/* <SimpleUploader
                maxFileSize={100000000}
                onUpload={(e: FileUploadUploadParams) => {
                    setUploadedFile(JSON.parse(e.xhr.response));
                }}
                auto
            /> */}
            <Button onClick={handleCreate} >{'Sauvegarder'} </Button>
            {props.document && <Button onClick={() => deleteDocument(document.id)} >Effacer le document </Button>}
        </div>
    </div>;
}
