import { FunctionComponent, useEffect, useState } from "react";
import { Partner, useCreatePartnerMutation, useUpdatePartnerMutation } from "./slice";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import MunityDialog from '../layouts/components/MunityDialog';
import { addNotification } from "../notifications/slice";
import { useAppSelector } from "../hooks";
import { useDispatch } from "react-redux";

const PartnerForm: FunctionComponent<{ show: boolean, onClose: Function }> = props => {
    const dispatch = useDispatch();

    const [, setId] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [errors, setErrors] = useState<Array<string>>([]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const { partnerInEdition } = useAppSelector(state => state.partner)

    const [createPartner, { isError: createError, isSuccess: createSuccess }] = useCreatePartnerMutation();
    const [updatePartner, { isError: updateError, isSuccess: updateSuccess }] = useUpdatePartnerMutation();

    useEffect(() => {
        if (updateError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de mettre à jour le partenaire'
            }));
        }
    }, [updateError, dispatch]);

    useEffect(() => {
        if (updateSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Partenaire mise à jour'
            }));
        }
    }, [updateSuccess, dispatch]);

    // Create alert
    useEffect(() => {
        if (createSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Partenaire créé'
            }));
        }
    }, [createSuccess, dispatch]);

    useEffect(() => {
        if (createError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de créer le partenaire'
            }));
        }
    }, [createError, dispatch]);

    useEffect(() => {
        if (partnerInEdition) {
            setName(partnerInEdition.name);
            setId(partnerInEdition.id);
            setDisabled(partnerInEdition.disabled);
        } else {
            setName('');
            setId('');
            setDisabled(false);
        }
    }, [partnerInEdition, props.show]);

    const savePartner = () => {
        setErrors([]);
        const errors: Array<string> = [];
        if (name.length === 0) {
            errors.push('name');
        }
        if (errors.length > 0) {
            setErrors(errors);
            dispatch(addNotification({
                type: 'error',
                message: 'Des champs requis sont manquant'
            }));
            return;
        }
        if (!partnerInEdition) {
            const p: Partner = {
                name,
                disabled
            }
            createPartner(p);
        } else {
            const p: Partner= Object.assign({}, partnerInEdition);
            p.name = name;
            p.disabled = disabled;
            updatePartner(p);
        }
        props.onClose();
    };

    return <>
        <MunityDialog title="Partenaire" visible={props.show} onSave={savePartner} onHide={props.onClose}>
            <div className="p-fluid">
                <div style={{marginTop:'32px'}} className="p-field p-grid">
                    <label htmlFor="name" className="p-col-12 p-md-2">Nom</label>
                    <div className="p-col-12 p-md-10">
                        <InputText className={errors.includes('name') ? 'p-invalid' : ''} id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                </div>
                <div style={{marginTop:'32px'}} className="p-field p-grid">
                    <div style={{display:'flex', alignItems: 'center', justifyContent: 'center', width:'100%'}}>
                        <Button style={{width: '200px'}} onClick={savePartner} >Sauvegarder</Button>
                    </div>
                </div>
                {/* <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-2'} htmlFor="disabled">Disabled</label>
                    <div className={'p-col-12 p-md-10'}>
                        <Checkbox id="disabled" onChange={() => setDisabled(!disabled)} checked={disabled} /><br />
                    </div>
                </div> */}
                {/* </div> */}
            </div>
        </MunityDialog>
    </>;
}

export default PartnerForm;
