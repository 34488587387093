import { Workspace } from '../workspaces/slice';
import { createSlice } from '@reduxjs/toolkit';
import { sliceFactory } from '../factory/slice';
import { GenericGroup } from '../generic_groups/slice';

export interface Phase extends GenericGroup{
    name: string,
    description: string,
    weight: number,
    color: string,
    disabled: boolean,
    workspace: Workspace | null
}

export interface PhaseState {
    phaseInEdition: Phase | null
}

export const initialState: PhaseState = {
    phaseInEdition: null
}

export const phaseSlice = createSlice({
    name: 'phase',
    initialState,
    reducers: {
        setPhaseInEdition: (state, payload:{payload:Phase|null}) => {
            state.phaseInEdition= payload.payload;
        }
    }
});

export const phaseAPISlice = sliceFactory<Phase>({
    reducerName: 'phaseAPI',
    endpoint: '/phases/',
    name: 'Phase'
});


export default phaseSlice.reducer;
export const { setPhaseInEdition } = phaseSlice.actions

export const {
    useGetPhasesQuery,
    useGetPhaseQuery,
    useDeletePhaseMutation,
    useUpdatePhaseMutation,
    useCreatePhaseMutation,
} = phaseAPISlice