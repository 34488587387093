import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Note, useCreateNoteMutation, useDeleteNoteMutation, useUpdateNoteMutation } from "./slice";
import { Theme, useGetThemesQuery } from "../themes/slice";
import { ToggleButton, ToggleButtonChangeEvent } from 'primereact/togglebutton';
import { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { addNotification } from "../notifications/slice";
import { useAppDispatch } from "../hooks";

const initialNoteState = {
    id: "",
    name: "",
    description: "",
    priority:false,
    step: "",
    generic_groups: []
};

export const NoteForm = (props: { note: Note | null, closeModal: Function, step: string | null }) => {
    const dispatch = useAppDispatch();

    const [note, setNote] = useState<Pick<Note, 'id' | 'name' | 'description' | 'step' | 'generic_groups' | 'priority'>>(initialNoteState);
    const [createNote, { isError: createError, isSuccess: createSuccess }] = useCreateNoteMutation();
    const [updateNote, { isError: updateError, isSuccess: updateSuccess }] = useUpdateNoteMutation();
    const [, { isError: deleteError, isSuccess: deleteSuccess }] = useDeleteNoteMutation();
    const { data: themes } = useGetThemesQuery();

    const handleChange = ({ target }: any | ToggleButtonChangeEvent | MultiSelectChangeEvent | React.ChangeEvent<HTMLInputElement>) => {
        setNote((prev) => ({
            ...prev,
            [target.id]: target.value,
        }));
    }

    useEffect(() => {
        setNote(props.note || initialNoteState);
        if (props.step !== null) {
            setNote((prev) => ({
                ...prev,
                step: props.step ? props.step : ''
            }))
        }
    }, [props.note, props.step])

    // Update alert
    useEffect(() => {
        if (updateError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de mettre à jour la note'
            }));
        }
    }, [updateError, dispatch]);

    useEffect(() => {
        if (updateSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Note mise à jour'
            }));
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateSuccess, dispatch]);

    // Delete alert
    useEffect(() => {
        if (deleteError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de supprimer la note'
            }));
        }
    }, [deleteError, dispatch]);

    useEffect(() => {
        if (deleteSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Note éffacée'
            }));
            setNote(initialNoteState);
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteSuccess, dispatch]);

    // Create alert
    useEffect(() => {
        if (createSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Note créée'
            }));
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createSuccess, dispatch]);

    useEffect(() => {
        if (createError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de créer la note'
            }));
        }
    }, [createError, dispatch]);


    return <div>
            <div className="p-fluid">
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-2'} htmlFor="name">Nom</label>
                    <div className={'p-col-12 p-md-10'}>
                        <InputText id="name" onChange={handleChange} value={note.name} /><br />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-2'} htmlFor="description">Description</label>
                    <div className={'p-col-12 p-md-10'}>
                        <InputTextarea rows={10} id="description" onChange={handleChange} value={note.description} /><br />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-2'} htmlFor="generic_groups">Thèmes</label>
                    <div className={'p-col-12 p-md-10'}>
                        <MultiSelect selectionLimit={3} selectedItemsLabel="{0} thèmes séléctionnés" maxSelectedLabels={1}  id="generic_groups" value={note.generic_groups} options={themes?.results == null ? [] : themes?.results.map((theme : Theme) => ({label: theme.label, value: theme.id}))} onChange={handleChange} placeholder="Selectionner le ou les thème(s)" /><br />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-3'} htmlFor="hidden">Mettre en avant</label>
                    <div className={'p-col-12 p-md-5'}>
                        <ToggleButton id="priority" onLabel="Oui" offLabel="Non" onIcon="pi pi-check" offIcon="pi pi-times" checked={note.priority} onChange={handleChange} />
                    </div>
                </div>
            </div>
            <Button onClick={props.note ? () => updateNote(note) : () => createNote(note)} >Sauvegarder</Button>
    </div>;
}
