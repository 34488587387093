import './style.scss'

import { Article, useGetArticlesQuery } from '../articles/slice';
import { DocumentType, useGetDocumentTypesQuery } from '../documents/typesSlice';
import { Event, useGetEventsQuery } from '../events/slice';
import { Note, useGetNotesQuery } from '../notes/slice';
import { Role, useGetRolesQuery } from '../permissions/slice';
import { Step, useGetStepsQuery } from '../steps/slice';
import { Task, useGetTasksQuery } from '../tasks/slice';
import { User, UserFunctionWorkspace, UserRoleWorkspace, setUserInEdition, useGetUsersQuery } from '../users/slice';
import { UserFunction, useGetUserFunctionsQuery } from '../user_functions/slice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { useEffect, useState } from 'react';

import { ArticleForm } from '../articles/form';
import ArticlesList from '../articles/list';
import { Button } from 'primereact/button';
import { CanChange } from '../helper';
import { DocumentTypeForm } from '../documents/typeForm';
import { EventForm } from '../events/form';
import EventsList from '../events/list';
import GEDDocumentsList from '../documents/filelist';
import InviteForm from '../users/invite';
import Links from '../common/links';
import LoadingMunity from '../layouts/components/LoadingMunity';
import { NoteForm } from '../notes/form';
import NotesList from '../notes/list';
import OpenFormButton from '../common/openFormButton';
import { StepForm } from '../steps/form';
import StepsList from '../steps/list';
import { TaskForm } from '../tasks/form';
import TasksList from '../tasks/list';
import UserForm from '../users/form';
import UsersCanvasWorkspace from '../users/userCanvasWorkspace';
import { useGetDocumentsQuery } from '../documents/slice';
import { useGetPhasesQuery } from '../phases/slice';
import { useGetWorkspacesQuery } from '../workspaces/slice';
import { useHistory } from 'react-router-dom';

const Workspace: React.FC = () => {
    const { data: steps, isLoading:stepLoading,  refetch: refetchSteps } = useGetStepsQuery();
    const { data: phases, isLoading:phasesLoading, refetch: refetchPhases } = useGetPhasesQuery();
    const { data: articles, isLoading:articleLoading,  } = useGetArticlesQuery();
    const { data: documents, isLoading:documentsLoading } = useGetDocumentsQuery();
    const { data: documentTypes, isLoading: documentTypesLoading } = useGetDocumentTypesQuery();
    const { data: tasks, isLoading: tasksLoading } = useGetTasksQuery();
    const { data: notes, isLoading: notesLoading } = useGetNotesQuery();
    const { data: users, isLoading: usersLoading } = useGetUsersQuery();
    const { data: events, isLoading: eventsLoading } = useGetEventsQuery();
    const { data: roles, isLoading: rolesLoading } = useGetRolesQuery();
    const { data: workspaces } = useGetWorkspacesQuery();
    const { data: user_functions, isLoading: userFunctionsLoading } = useGetUserFunctionsQuery();

    const loading =
        stepLoading          || 
        phasesLoading        ||
        articleLoading       ||
        documentsLoading     ||
        documentTypesLoading ||
        tasksLoading         ||
        notesLoading         ||
        usersLoading         ||
        eventsLoading        ||
        rolesLoading         ||
        userFunctionsLoading;


    const [filteredArticles, setFilteredArticles] = useState([]);
    const [, setFilteredDropTypes] = useState([]);
    const [filteredGEDTypes, setFilteredGEDTypes] = useState([]);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [showInviteForm, setShowInviteForm] = useState<boolean>(false);

    const workspace_slug = useAppSelector((state) => state.app.workspace_slug);
    const currentUser = useAppSelector((state) => state.authentication.currentUser);
    const dispatch = useAppDispatch();
    const router = useHistory();

    const ws_role = currentUser?.user_role_workspaces?.find((ws_role: UserRoleWorkspace) => ws_role.workspace === workspace_slug);
    const role = roles?.results.find((role: Role) => role.id === ws_role?.role)

    useEffect(() => {
        refetchSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phases])

    useEffect(() => {
        setFilteredArticles(articles?.results.filter((el: Article) => el.step === null));
        setFilteredDropTypes(documentTypes?.results.filter((el: DocumentType) => el.step === null && !el.is_ged));
        setFilteredGEDTypes(documentTypes?.results.filter((el: DocumentType) => el.step === null && el.is_ged));
    }, [tasks, documents, documentTypes, articles, notes]);

    if (loading) {
        return <LoadingMunity/>;
    }

    return <div key={workspace_slug} className="workspace p-grid">
        <div className="left-content p-md-12 p-lg-9">
            <div className='workspace-banner workspace-banner-img'>
                <div className="desc">
                    <div className="blocky"></div>
                    <h1>Page d'accueil : {workspaces?.results.find(w => w.slug === workspace_slug)?.name} </h1>
                    <p>
                        Ce portail permet d’organiser les échanges d’informations <br />
                        entre vous et le consultant qui vous accompagne dans la phase <br />
                        de paramétrage et de mise au point de la solution Optim’BTP. <br />
                    </p>
                </div>
            </div>
            <div className="p-shadow-4" style={{ padding: '32px 32px 32px 64px', position: 'relative' }}>
                <StepsList refetch={() => { refetchSteps(); refetchPhases(); }} phases={phases?.results} steps={steps?.results} editable={CanChange(currentUser, roles?.results, workspace_slug, 'edit', 'article')} />
                {currentUser.is_superuser && <div className="admin_actions" style={{ position: 'relative', height: '64px' }}>
                    {/* <OpenFormButton className="in-dom" showLabel model="phase" action="create" header={'Ajouter une phase'} form={<PhaseForm phase={null} closeModal={() => null} />} /> */}
                    <OpenFormButton className="in-dom" showLabel model="step" action="create" header={'Ajouter une étape'} form={<StepForm stepsCount={steps?.results.length} step={null} closeModal={() => null} />} />
                </div>}
            </div>
            <div className='p-shadow-4' style={{ padding: '32px 64px', position: 'relative' }}>
                <ArticlesList articles={filteredArticles} />
                {filteredArticles?.length < 3 &&
                    currentUser.is_superuser && <div className="admin_actions">
                        <OpenFormButton showLabel className="in-dom" superuserOnly model="workspace" action="create" minWidth='90vw' header={'Ajouter un article'} form={<ArticleForm article={null} closeModal={() => null} step={null} />} />
                    </div>
                }
            </div>
            <div className="p-shadow-4" style={{ padding: '32px 64px', position: 'relative' }}>
                <GEDDocumentsList isDropArea={false} documents={documents?.results} documentTypes={filteredGEDTypes} step={null} />
                <div className="admin_actions">
                    <OpenFormButton showLabel className="in-dom" model="article" action="documenttype" header={'Ajouter un type de document'} form={<DocumentTypeForm isGed={true} type={null} closeModal={() => null} step={null} />} />
                </div>
            </div>
            <div className='p-shadow-4' style={{ padding: '32px 64px', position: 'relative' }}>
                <EventsList events={events?.results.filter((event: Event) => {
                    const step = steps?.results.find((s: Step) =>
                        s.id === event.step
                    )
                    if (step?.code === 0) return false
                    return true;
                })} />
                <div className="admin_actions">
                    <OpenFormButton showLabel className="in-dom" model="event" action="create" header={'Ajouter un évènement'} form={<EventForm event={null} closeModal={() => null} step={null} />} />
                </div>
            </div>
            <div className='p-grid p-shadow-4'>
                <div className='p-col-6'>
                    <div style={{ padding: '32px 64px', position: 'relative' }}>
                        <TasksList tasks={tasks?.results.filter((t: Task) => {
                            const task = steps?.results.find((s: Step) =>
                                s.id === t.step
                            )
                            if (task?.code === 0) return false
                            return true;
                        })} />
                        <div className="admin_actions">
                            <OpenFormButton showLabel className="in-dom" model="task" action="create" header={'Ajouter une tâche'} form={<TaskForm task={null} closeModal={() => null} step={null} />} />
                        </div>
                    </div>
                </div>
                <div className='p-col-6'>
                    <div style={{ padding: '32px 64px' }}>
                        <NotesList notes={notes?.results.filter((n: Note) => {
                            const note = steps?.results.find((s: Step) =>
                                s.id === n.step
                            )
                            if (note?.code === 0) return false
                            return true;
                        })} />
                        {
                            CanChange(currentUser, roles?.results, workspace_slug, 'create', 'note') ?
                                <div className="admin_actions">
                                    <OpenFormButton showLabel className="in-dom" model="note" action="create" header={'Ajouter une note'} form={<NoteForm note={null} closeModal={() => null} step={null} />} />
                                </div> : null
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className='right-content p-d-flex p-flex-column p-jc-between'>
            <div>
                <div style={{ padding: '32px 40px 32px 40px' }}>
                    <h2 style={{ color: 'white', fontWeight: '200' }}>Équipe cliente</h2>
                    <div className="users-column-wrapper">
                        {user_functions?.results?.filter((f: UserFunction) => f.needed).map((f: UserFunction) => {
                            const usersWithThisRole = users?.results
                                .filter((u: User) =>
                                    (u.has_overmind_access === false) &&
                                    (u.is_superuser === false) &&
                                    u.user_function_workspaces.find((ufw: UserFunctionWorkspace) => ufw.function === f.id)
                                );
                            if (usersWithThisRole.length === 0) {
                                return <UsersCanvasWorkspace workspace_slug={workspace_slug} key={f.id} missingRole={f.label} functions={user_functions?.results} onClick={() => {
                                    setShowForm(true); dispatch(setUserInEdition(null))
                                }} />;
                            }
                            return usersWithThisRole.map((user: User) =>
                                <UsersCanvasWorkspace workspace_slug={workspace_slug} key={user.id} functions={user_functions?.results} user={user} onClick={() => {
                                    setShowForm(true); dispatch(setUserInEdition(user))
                                }} />
                            );
                        })}
                        {/* Show user without needed roles */}
                        {user_functions?.results?.filter((f: UserFunction) => !f.needed).map((f: UserFunction) => {
                            const usersWithThisRole = users?.results
                                .filter((u: User) =>
                                    (u.has_overmind_access === false) &&
                                    (u.is_superuser === false) &&
                                    u.user_function_workspaces.find((ufw: UserFunctionWorkspace) => ufw.function === f.id)
                                );
                            return usersWithThisRole.map((user: User) =>
                                <UsersCanvasWorkspace workspace_slug={workspace_slug} key={user.id} functions={user_functions?.results} user={user} onClick={() => {
                                    setShowForm(true); dispatch(setUserInEdition(user))
                                }} />
                            );
                        })}
                        {/* Show user without roles */}
                        {users?.results?.filter((u: User) => !u.has_overmind_access).filter((f: User) => !f.user_function_workspaces.find((ufw: UserFunctionWorkspace) => ufw.function)).map((user: User) =>
                            <UsersCanvasWorkspace workspace_slug={workspace_slug} key={user.id} functions={user_functions?.results} user={user} onClick={() => {
                                setShowForm(true); dispatch(setUserInEdition(user))
                            }} />
                        )}
                    </div>
                    {(currentUser.has_overmind_access || role?.name === "Gestion") && <div className="button-show-full-calendar">
                        <Button onClick={() => {
                            setShowForm(true);
                            dispatch(setUserInEdition(null))
                        }} label="Créer un utilisateur client" className="p-button-outlined annuaire" />
                        <Button style={{marginTop:'16px'}} onClick={() => {
                            setShowInviteForm(true);
                        }} label="Inviter un utilisateur client" className="p-button-outlined annuaire" />
                    </div>}
                </div>
                <div style={{ padding: '32px 40px 32px 32px' }}>
                    <h2 style={{ color: 'white', fontWeight: '200' }}>Équipe partenaire</h2>
                    <div className="users-column-wrapper">
                        {users?.results.filter((u: User) => u.has_overmind_access || u.is_superuser).map((user: User) => {
                            return <UsersCanvasWorkspace showRoleOnly workspace_slug={workspace_slug} key={user.id} functions={user_functions?.results} user={user} onClick={() => {
                                setShowForm(true); dispatch(setUserInEdition(user))
                            }} />
                        })}
                    </div>
                    {/* <div className="button-show-full-calendar">
                        <Button onClick={() => router.push(`/workspace/${workspace_slug}/users`)} label="Consulter l'annuaire complet" className="p-button-outlined annuaire" />
                        {(CanChange(currentUser, roles?.results, workspace_slug, "create", "users")) &&
                            <Button icon="pi pi-plus" className="add p-button-outlined" onClick={() => { setShowForm(true); dispatch(setUserInEdition(null)); }}></Button>
                        }
                    </div> */}
                </div>
            </div>
            {
                (currentUser.is_superuser || currentUser.partner) &&
                <div style={{ padding: '32px' }}>
                    <div style={{ marginBottom: '8px' }}>
                        <Button onClick={() => router.push(`/workspace/${workspace_slug}/users`)} label="Consulter l'équipe partenaire" className="p-button-outlined" />
                    </div>
                    {/* <div style={{ marginBottom: '8px' }}>
                    <Button onClick={() => router.push(`/workspace/${workspace_slug}/users`)} label="Consulter l'équipe client" className="p-button-outlined" />
                </div> */}
                </div>
            }
            <UserForm mode="workspace" show={showForm} onClose={() => setShowForm(false)} />
            <InviteForm show={showInviteForm} mode={'workspace'} onClose={() => setShowInviteForm(false)} />
            {/* <Dialog maximizable style={{width:'40vw'}} header="Créer une nouvelle phase" visible={showPhaseForm} draggable={false} onHide={() => {setShowPhaseForm(false);}} >
                <PhaseForm phase={null} closeModal={() => setShowPhaseForm(false)} />
            </Dialog> */}
            <Links onlyFAQ={!currentUser.is_superuser && !currentUser.has_overmind_access} />
        </div>
    </div>
}

export default Workspace;