import { Article, useCreateArticleMutation, useDeleteArticleMutation, useUpdateArticleMutation } from "./slice";
import { Editor, EditorTextChangeEvent } from 'primereact/editor';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Theme, useGetThemesQuery } from "../themes/slice";
import { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { File } from '../files/slice';
import { FileUploadUploadEvent } from 'primereact/fileupload';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import SimpleUploader from "../files/SimpleUploader";
import { addNotification } from "../notifications/slice";
import { getURLForFile } from "../helper";
import { useAppDispatch } from "../hooks";

const initialArticleState = {
    id: "",
    name: "",
    description: "",
    content: "",
    step: "",
    visual: "",
    generic_groups: []
};

export const ArticleForm = (props: { article: Article | null, closeModal: Function, step: string | null }) => {
    const dispatch = useAppDispatch();

    const [uploadedVisual, setUploadedVisual] = useState<File | undefined>(undefined);
    const [article, setArticle] = useState<Pick<Article, 'id' | 'name' | 'description' | 'step' | 'content' | 'generic_groups' | 'visual'>>(initialArticleState);
    const [createArticle, { isError: createError, isSuccess: createSuccess }] = useCreateArticleMutation();
    const [updateArticle, { isError: updateError, isSuccess: updateSuccess }] = useUpdateArticleMutation();
    const [, {isError: deleteError, isSuccess: deleteSuccess }] = useDeleteArticleMutation();
    const { data: themes } = useGetThemesQuery();

    const handleChange = ({ target }: MultiSelectChangeEvent | React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setArticle((prev) => ({
            ...prev,
            [target.id]: target.value,
        }));
    }

    const handleEditorChange = (e: EditorTextChangeEvent) => {
        setArticle((prev) => ({
            ...prev,
            content: e.htmlValue ? e.htmlValue : '',
        }));
    }

    const handleCreate = () => {
        createArticle(article);
    }

    useEffect(() => {
        setArticle(props.article || initialArticleState);
        if (props.step !== null) {
            setArticle((prev) => ({
                ...prev,
                step: props.step ? props.step : ''
            }))
        }
    }, [props.article, props.step])

    // Update alert
    useEffect(() => {
        if (updateError) {
            dispatch(addNotification({
                type: 'error',
                message: "Impossible de créer l'article"
            }));
        }
    }, [updateError, dispatch]);

    useEffect(() => {
        if (updateSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Article mise à jour'
            }));
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateSuccess]);

    // Delete alert
    useEffect(() => {
        if (deleteError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de supprimer l\'article'
            }));
        }
    }, [deleteError, dispatch]);

    useEffect(() => {
        if (deleteSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Article supprimé'
            }));
            setArticle(initialArticleState);
        }
    }, [deleteSuccess, dispatch]);

    // Create alert
    useEffect(() => {
        if (createSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Article created'
            }));
            props.closeModal();
        }
    }, [props, createSuccess, dispatch]);

    useEffect(() => {
        if (createError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de créer l\'article'
            }));
        }
    }, [createError, dispatch]);

    // const header = (
    //     <>
    //         <span className="ql-formats">
    //             <button className="ql-bold" aria-label="Bold"></button>
    //             <button className="ql-italic" aria-label="Italic"></button>
    //             <button className="ql-underline" aria-label="Underline"></button>
    //             <button className="ql-color-picker" aria-label="Color"></button>
    //         </span>
    //         <span className="ql-formats">
    //             <button className="ql-align ql-picker ql-icon-picker" aria-label="Align">
    //                 {/* <button className="ql-align-picker" aria-label="Align"></button>
    //             <button className="ql-align-options" aria-label="Align"></button> */}
    //             </button>
    //         </span>
    //         <span className="ql-formats">
    //             <button className="ql-link" aria-label="Bold"></button>
    //             <button className="ql-image" aria-label="Italic"></button>
    //         </span>
    //     </>
    // );


    return <div>
        <div className="p-fluid">
            <div className="p-d-flex p-jc-center p-m-2 p-flex-column p-ai-center p-jc-center">
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '4px' }}>
                    {uploadedVisual ?
                        <img width="50%" alt="visual" src={uploadedVisual.file} /> :
                        null
                    }
                    {!uploadedVisual && article.visual && (typeof article.visual !== "string") ?
                        <img style={{ width: "50%" }} alt="visual" src={getURLForFile(article.visual.file)} /> :
                        null
                    }
                </div>
                <br />
                <SimpleUploader
                    maxFileSize={100000000}
                    onUpload={(e: FileUploadUploadEvent )=> {
                        const _visual = JSON.parse(e.xhr.response);
                        setUploadedVisual(_visual);
                        setArticle((prev) => ({
                            ...prev,
                            visual: _visual.id,
                        }));
                    }}
                    label={"Changer d'illustration"}
                    auto
                    accept="image/*"
                />
                <div style={{ fontSize: '12px', marginTop: '4px' }}>format: (16:10, 1920x1200)</div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="name">Titre</label>
                <div className={'p-col-12 p-md-10'}>
                    <InputText placeholder="Ajouter un titre" id="name" onChange={handleChange} value={article.name} /><br />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="description">Description</label>
                <div className={'p-col-12 p-md-10'}>
                    <InputTextarea rows={10} placeholder="Ajouter une description" id="description" onChange={handleChange} value={article.description} /><br />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="generic_groups">Thèmes</label>
                <div className={'p-col-12 p-md-10'}>
                    <MultiSelect selectionLimit={3} selectedItemsLabel="{0} thèmes séléctionnés" maxSelectedLabels={1} id="generic_groups" value={article.generic_groups} options={themes?.results == null ? [] : themes?.results.map((theme: Theme) => ({ label: theme.label, value: theme.id }))} onChange={handleChange} placeholder="Sélectionner le ou les thème(s)" /><br />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="content">Contenu</label>
                <div className={'p-col-12 p-md-10'}>
                    <Editor style={{ height: '320px' }} value={article.content} onTextChange={handleEditorChange} />
                </div>
            </div>
        </div>
        <Button onClick={props.article ? () => {
            const _toSave = { ...article };
            if (typeof _toSave.visual !== "string") {
                _toSave.visual = undefined;
            }
            updateArticle(_toSave);
        } : handleCreate} >{'Sauvegarder'} </Button>
    </div>;
}
