import React, { FunctionComponent, PropsWithChildren } from 'react';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const MunityDialog:FunctionComponent<PropsWithChildren<{canChange?:boolean, visible:boolean, title:string, onSave:Function, onHide:Function, saveLabel?:string}>> = props => {
    const footerButtons = props.canChange && <Button label={props.saveLabel ? props.saveLabel : "Sauvegarder"} icon="pi pi-check" onClick={() => {
        if(props.onSave()) {
            props.onHide();
        }
    }} />;

    return <Dialog
        style={{ minWidth: '50vw' }}
        resizable={true}
        visible={props.visible}
        onHide={() => props.onHide()}
        maximizable={true}
        header={props.title}
        footer={footerButtons}
    >
        {props.children}
    </Dialog>;
}
export default MunityDialog;