import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Task, useCreateTaskMutation, useDeleteTaskMutation, useUpdateTaskMutation } from "./slice";
import { Theme, useGetThemesQuery } from "../themes/slice";
import { User, useGetUsersQuery } from "../users/slice";
import { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { addNotification } from "../notifications/slice";
import moment from 'moment';
import { useAppDispatch } from "../hooks";

const initialTaskState = {
    id: "",
    name: "",
    description: "",
    content: "",
    state: "todo",
    assignee: "",
    step: "",
    start_date: new Date(),
    end_date: new Date(),
    generic_groups: []
};

export const TaskForm = (props: { task: Task | null, closeModal: Function, step: string | null }) => {
    const dispatch = useAppDispatch();

    const [task, setTask] = useState<Pick<Task, 'id' | 'name' | 'generic_groups' | 'description' | 'state' | 'step' | 'assignee' | 'start_date' | 'end_date'>>(initialTaskState);
    const [createTask, { isError: createError, isSuccess: createSuccess }] = useCreateTaskMutation();
    const [updateTask, { isError: updateError, isSuccess: updateSuccess }] = useUpdateTaskMutation();
    const [, { isError: deleteError, isSuccess: deleteSuccess }] = useDeleteTaskMutation();
    const {data: users} = useGetUsersQuery();
    const { data: tags } = useGetThemesQuery();

    const handleChange = ({ target }: MultiSelectChangeEvent| CalendarChangeEvent| React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | DropdownChangeEvent ) => {
        setTask((prev) => ({
            ...prev,
            [target.id]: target.value,
        }));
    }

    const handleCalendarSelectStartDate = ({ value }: any) => {
        setTask((prev: any) => {
            const _event = {
                ...prev,
                start_date: moment(value).format('YYYY-MM-DD')
            }
            return _event;
        });
    }


    const handleCalendarBlurStartDate = ({ target }: any | React.ChangeEvent<HTMLInputElement> | CalendarChangeEvent) => {
        setTask((prev: any) => {
            const _event = {
                ...prev,
                start_date: moment(target.value, 'DD/MM/YYYY').format('YYYY-MM-DD')
            }
            return _event;
        });
    }

    const handleCalendarSelectEndDate = ({ value }: any) => {
        setTask((prev: any) => {
            const _event = {
                ...prev,
                end_date: moment(value).format('YYYY-MM-DD')
            }
            return _event;
        });
    }


    const handleCalendarBlurEndDate = ({ target }: any | React.ChangeEvent<HTMLInputElement> | CalendarChangeEvent) => {
        setTask((prev: any) => {
            const _event = {
                ...prev,
                end_date: moment(target.value, 'DD/MM/YYYY').format('YYYY-MM-DD')
            }
            return _event;
        });
    }


    const handleCreate = () => {
        createTask({
            ...task,
            start_date: moment(task.start_date).format('YYYY-MM-DD'),
            end_date: moment(task.end_date).format('YYYY-MM-DD')
        });
    }

    useEffect(() => {
        setTask(props.task || initialTaskState);
        if (props.step !== null) {
            setTask((prev) => ({
                ...prev,
                step: props.step ? props.step : ''
            }))
        }
    }, [props.task, props.step])

    // Update alert
    useEffect(() => {
        if (updateError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de mettre à jour la tâche'
            }));
        }
    }, [updateError, dispatch]);

    useEffect(() => {
        if (updateSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Tâche mise à jour'
            }));
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateSuccess, dispatch]);

    // Delete alert
    useEffect(() => {
        if (deleteError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de supprimer la tâche'
            }));
        }
    }, [deleteError, dispatch]);

    useEffect(() => {
        if (deleteSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Tâche effacée'
            }));
            setTask(initialTaskState);
        }
    }, [deleteSuccess, dispatch]);

    // Create alert
    useEffect(() => {
        if (createSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Tâche créée'
            }));
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createSuccess, dispatch]);

    useEffect(() => {
        if (createError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de créer la tâche'
            }));
        }
    }, [createError, dispatch]);

    const stateOptions = [{label: 'A faire', value: 'todo'}, {label: 'En cours', value: 'inprogress'}, {label: 'Fait', value: 'done'}]


    return <div>
            <div className="p-fluid">
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-2'} htmlFor="name">Nom</label>
                    <div className={'p-col-12 p-md-10'}>
                        <InputText id="name" onChange={handleChange} value={task.name} /><br />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-2'} htmlFor="description">Description</label>
                    <div className={'p-col-12 p-md-10'}>
                        <InputTextarea rows={10} id="description" onChange={handleChange} value={task.description} /><br />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-2'} htmlFor="generic_groups">Thèmes</label>
                    <div className={'p-col-12 p-md-10'}>
                        <MultiSelect selectionLimit={3} selectedItemsLabel="{0} thèmes séléctionnés" maxSelectedLabels={1}  id="generic_groups" value={task.generic_groups} options={tags?.results == null ? [] : tags?.results.map((theme: Theme) => ({label: theme.label, value: theme.id}))} onChange={handleChange} placeholder="Selectionner le ou les thème(s)" /><br />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-2'} htmlFor="start_date">Date de début</label>
                    <div className="p-col-12 p-md-10">
                        <Calendar id="start_date" locale="fr" value={new Date(task.start_date)} onSelect={handleCalendarSelectStartDate} onBlur={handleCalendarBlurStartDate} keepInvalid dateFormat="dd/mm/yy" />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-2'} htmlFor="end_date">Date de fin</label>
                    <div className="p-col-12 p-md-10">
                        <Calendar id="end_date" value={new Date(task.end_date)} onSelect={handleCalendarSelectEndDate} onBlur={handleCalendarBlurEndDate} keepInvalid dateFormat="dd/mm/yy" />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-2'} htmlFor="assignee">Assigner à </label>
                    <div className={'p-col-12 p-md-10'}>
                        <Dropdown id="assignee" value={task.assignee} options={users?.results.map((user: User) => ({label: `${user.first_name} ${user.last_name}`, value: user.id}))} onChange={handleChange} placeholder="Selectionner un utilisateur" /><br />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-2'} htmlFor="state">État </label>
                    <div className={'p-col-12 p-md-10'}>
                        <Dropdown id="state" value={task.state} options={stateOptions} onChange={handleChange} placeholder="Selectionner un état" /><br />
                    </div>
                </div>
            </div>
            <Button onClick={props.task ?() => updateTask(task) : handleCreate} >{'Sauvegarder'} </Button>
    </div>;
}
