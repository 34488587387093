import {  useGetArticleQuery } from "./slice";
import './style.scss';
import { Button } from "primereact/button";
import { useHistory } from "react-router";
import { getURLForFile } from "../helper";

const ArticleDetails = ({id}: any) => {
    const router = useHistory();
    const { data: article } = useGetArticleQuery(id?.match.params.id);

    return <div className={'article-details-wrapper'}>
        <div className='back-arrow'>
            <Button onClick={() => router.goBack()}>
                <i className='pi pi-arrow-left'></i>
                Articles
            </Button>
        </div>
        {article &&
            <div className={'article-content'}>
                <div className='article-img'>
                    {
                        article.visual && (typeof article.visual !== "string") ?
                            <img src={getURLForFile(article.visual.file)} alt="visual" /> :
                            <img src="/articles.jpg?v=1" alt="visual" />
                    }
                </div>
                <div className='p-shadow-12 article-card'>
                    <h2 className='title'>
                        {article.name}
                    </h2>
                    <div className='' dangerouslySetInnerHTML={{__html: article.content}}>
                    </div>
                </div>
            </div>
        }
    </div>
}

export default ArticleDetails;
