import { Phase, useGetPhasesQuery } from "../phases/slice";
import { Step, useCreateStepMutation, useDeleteStepMutation, useUpdateStepMutation } from "./slice";
import { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { PhaseForm } from "../phases/form";
import { ToggleButton } from 'primereact/togglebutton';
import { addNotification } from "../notifications/slice";
import { useAppDispatch } from "../hooks";

const initialStepState = {
    id: "",
    name: "",
    group_name: "",
    description: "",
    color: "",
    code: 0,
    phase: null,
    hidden: false
};

export const StepForm = (props: { step: Step | null, closeModal: Function, stepsCount: number }) => {
    const dispatch = useAppDispatch();
    const { data: phases } = useGetPhasesQuery();

    const [step, setStep] = useState<Pick<Step, 'id' | 'name' | 'description' | 'color' | 'code' | 'phase' | 'group_name' | 'hidden'>>(initialStepState);
    const [createStep, { isError: createError, isSuccess: createSuccess }] = useCreateStepMutation();
    const [updateStep, { isError: updateError, isSuccess: updateSuccess }] = useUpdateStepMutation();
    const [, { isError: deleteError, isSuccess: deleteSuccess }] = useDeleteStepMutation();

    const [isVisible, setIsVisible] = useState(false);

    const colors = ['#B1B1B1', '#FDAE19', '#1DAEB0', '#8CD483', '#8CD483', '#8CD483', '#6578DA', '#6578DA', '#6578DA', '#1DAEB0', '#1DAEB0', '#1DAEB0'];

    const handleChange = ({ target }: any | React.ChangeEvent<HTMLInputElement>) => {
        setStep((prev) => ({
            ...prev,
            [target.id]: target.value,
        }));
    }

    const handleCreate = () => {
        step.color = colors[props.stepsCount];
        createStep(step);
    }

    useEffect(() => {
        setStep(props.step || initialStepState);
    }, [props.step])

    // Update alert
    useEffect(() => {
        if (updateError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de mettre à jour l\'étape'
            }));
        }
    }, [updateError, dispatch]);

    useEffect(() => {
        if (updateSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Étape mise à jour'
            }));
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateSuccess, dispatch]);

    // Delete alert
    useEffect(() => {
        if (deleteError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de supprimer l\'étape'
            }));
        }
    }, [deleteError, dispatch]);

    useEffect(() => {
        if (deleteSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Étape effacée'
            }));
            setStep(initialStepState);
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteSuccess, dispatch]);

    // Create alert
    useEffect(() => {
        if (createSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Étape créée'
            }));
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createSuccess, dispatch]);

    useEffect(() => {
        if (createError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de créer l\'étape'
            }));
        }
    }, [createError, dispatch]);


    return <div>
            <div className="p-fluid">
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-3'} htmlFor="group_name">Numéro de l'étape</label>
                    <div className={'p-col-12 p-md-9'}>
                        <InputText id="code" onChange={handleChange} value={'' + step.code} /><br />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-3'} htmlFor="name">Nom</label>
                    <div className={'p-col-12 p-md-9'}>
                        <InputText id="name" onChange={handleChange} value={step.name} /><br />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-3'} htmlFor="description">Description</label>
                    <div className={'p-col-12 p-md-9'}>
                        <InputTextarea id="description" onChange={handleChange} rows={10} value={step.description} /><br />
                    </div>
                </div>
                {/* <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-3'} htmlFor="group_name">Couleur</label>
                    <div className={'p-col-12 p-md-9'}>
                        <InputText id="color" onChange={handleChange} value={step.color} /><br />
                    </div>
                </div> */}
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-3'} htmlFor="hidden">Masquer l'étape</label>
                    <div className={'p-col-12 p-md-5'}>
                        <ToggleButton id="hidden" onLabel="Oui" offLabel="Non" onIcon="pi pi-check" offIcon="pi pi-times" checked={step.hidden} onChange={handleChange} />
                    </div>
                </div>
                <hr style={{margin:'32px 8px'}}/>
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-3'} htmlFor="group_name">Phase</label>
                    <div className={'p-col-12 p-md-9'}>
                        <Dropdown value={step.phase} options={[{label:'', value:null},...phases?.results.map((phase: Phase) => ({label: phase.name, value: phase.id}))]} onChange={handleChange} id="phase" />
                    </div>
                    <Button className="p-button-text" onMouseDown={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => e.stopPropagation()} onClick={() => setIsVisible(true)}>
                        <i className={`pi pi-plus`}> Créer une nouvelle phase </i>
                    </Button>
                </div>
            </div>
                <hr style={{margin:'32px 8px'}}/>
            <Dialog maximizable style={{width:'40vw'}} header="Créer une nouvelle phase" visible={isVisible} draggable={false} onHide={() => {setIsVisible(false);}} >
                <PhaseForm phase={null} closeModal={() => setIsVisible(false)} />
            </Dialog>
            <Button onClick={props.step ? () => updateStep(step) : () => handleCreate()} >Sauvegarder</Button>
    </div>;
}
