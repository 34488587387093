import 'react-toastify/dist/ReactToastify.css';

// import iconOvmAdmin from '../munity/assets/icon_ovm_admin.svg';
// import iconOvmDeploy from '../munity/assets/icon_ovm_deploy.svg';
// import { Button } from 'primereact/button';
// import { useHistory, useLocation } from 'react-router';
import React from 'react';

const OvermindSidebar: React.FC<{ newMenuButton?: Partial<React.Component>[] }> = props => {

    // const location = useLocation();
    // const history = useHistory();

    // return <div className="sidebar">
    //     <div className="project">
    //         <div>
    //             <div className="legend">OptimBTP portal</div>
    //         </div>
    //     </div>
    //     <div className="menu">
    //         <Button className={`p-button-link ${location.pathname.match(/^\/workspaces/g) ? 'active' : null}`} onClick={() => history.push('/workspaces')}>
    //             <img style={{ marginRight: '30px' }} src={iconOvmDeploy} alt="graph" /> Projets
    //         </Button>
    //     </div>
    //     <div className="menu">
    //         <Button className={`p-button-link ${(location.pathname.match(/^\/users/g) || location.pathname.match(/^\/$/g)) ? 'active' : null}`} onClick={() => history.push('/users')}>
    //             <img src={iconOvmAdmin} alt="graph" /> Utilisateurs
    //         </Button>
    //     </div>
    //     {props.newMenuButton}
    // </div>
    return null;
}

export default OvermindSidebar;
