import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { FunctionComponent, useEffect, useState } from "react";
import { Informations, useCreateWorkspaceMutation, useUpdateWorkspaceMutation } from "./slice";

import { Calendar } from 'primereact/calendar';
import { Dropdown } from "primereact/dropdown";
import { File } from '../files/slice';
import { FileUploadUploadEvent } from 'primereact/fileupload';
import { InputText } from "primereact/inputtext";
import MunityDialog from '../layouts/components/MunityDialog';
import SimpleUploader from '../files/SimpleUploader';
import { addNotification } from "../notifications/slice";
import { getURLForFile } from "../helper";
import slugify from 'slugify';
import { useAppSelector } from "../hooks";
import { useDispatch } from "react-redux";
import { useGetPartnersQuery } from "../partners/slice";
import { useTranslation } from "react-i18next";

const initialInfoState = {
    duplicable: false,
    start_date: new Date() || null,
    partenaire: "",
};

const WorkspaceForm: FunctionComponent<{ show: boolean, onClose: Function }> = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation()

    const [slug, setSlug] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [startDate, setStartDate] = useState<Date|null>(new Date());
    const [errors, setErrors] = useState<Array<string>>([]);
    const [dbConnection, setDbConnection] = useState<string>("");
    const [disabled, setDisabled] = useState<boolean>(false);
    const [info, setInfo] = useState<Pick<Informations, 'duplicable' | 'start_date' | 'partenaire'>>(initialInfoState);
    const { workspaceInEdition } = useAppSelector(state => state.workspace)
    const [slugHasBeenSetByUser, setSlugHasBeenSetByUser] = useState<Boolean>(false);
    const [uploadedIcon, setUploadedIcon] = useState<File | undefined>(undefined);
    const [partner, setPartner] = useState<string | null>("");
    const { data: partners } = useGetPartnersQuery();

    const [createWorkspace, { isError: createError, isSuccess: createSuccess }] = useCreateWorkspaceMutation();
    const [updateWorkspace, { isError: updateError, isSuccess: updateSuccess }] = useUpdateWorkspaceMutation();

    const currentUser = useAppSelector((state) => state.authentication.currentUser);

    const handleChangeCheckbox = ({ target, checked }: CheckboxChangeEvent) => {
        setInfo((prev) => ({
            ...prev,
            [target.id]: checked,
        }));
    }

    useEffect(() => {
        if (!slugHasBeenSetByUser) {
            setSlug(slugify(name, {
                lower: true,
                strict: true,
                locale: 'fr',
                trim: true,
                replacement: '_',
            }));
        }
    }, [name, slugHasBeenSetByUser]);

    useEffect(() => {
        if (updateError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de modifier le projet'
            }));
        }
    }, [updateError, dispatch]);

    useEffect(() => {
        if (updateSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Projet modifié'
            }));
        }
    }, [updateSuccess, dispatch]);

    // Create alert
    useEffect(() => {
        if (createSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Projet créé'
            }));
        }
    }, [createSuccess, dispatch]);

    useEffect(() => {
        if (createError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de créer le projet'
            }));
        }
    }, [createError, dispatch]);

    useEffect(() => {
        if (workspaceInEdition) {
            setName(workspaceInEdition.name);
            setSlug(workspaceInEdition.slug);
            setDbConnection(workspaceInEdition.db_connection);
            setSlugHasBeenSetByUser(true);
            setDisabled(workspaceInEdition.disabled);
            setInfo(workspaceInEdition.informations || {});
            setPartner(workspaceInEdition.partner);
            setStartDate(workspaceInEdition.informations['start_date'] ? new Date(workspaceInEdition.informations['start_date']) : null)
        } else {
            setSlugHasBeenSetByUser(false);
            setName('');
            setSlug('');
            setDisabled(false);
            setDbConnection('');
            setInfo(initialInfoState);
            setUploadedIcon(undefined);
            setPartner(null);
        }
    }, [workspaceInEdition, props.show]);

    const saveWorkspace = () => {
        setErrors([]);
        const errors: Array<string> = [];
        if (name.length === 0) {
            errors.push('name');
        }
        if (slug.length === 0) {
            errors.push('slug');
        }
        if (errors.length > 0) {
            setErrors(errors);
            dispatch(addNotification({
                type: 'error',
                message: 'Des champs requis sont manquant'
            }));
            return;
        }
        if (!workspaceInEdition) {
            const ws = {
                name,
                slug,
                icon: uploadedIcon?.id,
                db_connection: dbConnection,
                disabled: disabled,
                informations: { ...info, start_date: startDate },
                partner: partner
            }
            createWorkspace(ws);
        } else {
            const ws = Object.assign({}, workspaceInEdition);
            ws.name = name;
            ws.icon = uploadedIcon?.id;
            ws.disabled = disabled;
            ws.db_connection = dbConnection;
            ws.informations = { ...info, start_date: startDate };
            ws.partner = partner;
            updateWorkspace(ws);
        }
        props.onClose();
    };

    const partnerOptions: [{ [key: string]: string | undefined }] = [{
        value: '',
        label: ' - '
    }];
    partners?.results.forEach(p => {
        partnerOptions.push({
            value: p.id,
            label: p.name
        });
    });

    return <>
        <MunityDialog title="Gestion de projet" visible={props.show} onSave={saveWorkspace} onHide={props.onClose} canChange={currentUser.has_overmind_access || currentUser.is_superuser}>
            <div className="p-fluid">
                <div className="p-d-flex p-jc-center p-m-2 p-flex-column p-ai-center">
                    {
                        uploadedIcon ?
                            <img alt="icon" width="200px" src={uploadedIcon.file} /> :
                            workspaceInEdition?.icon && (typeof workspaceInEdition.icon !== "string") ?
                                <img alt="icon" width="200px" src={getURLForFile(workspaceInEdition.icon.file)} /> :
                                null
                    }
                    <SimpleUploader
                        onUpload={(e: FileUploadUploadEvent) => {
                            setUploadedIcon(JSON.parse(e.xhr.response));
                        }}
                        label={t('app:change_icon')}
                        auto
                        accept="image/*"
                    />
                </div>
                <div style={{ marginTop: '32px' }} className="p-field p-grid">
                    <label htmlFor="name" className="p-col-12 p-md-2">Nom</label>
                    <div className="p-col-12 p-md-10">
                        <InputText className={errors.includes('name') ? 'p-invalid' : ''} id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="slug" className="p-col-12 p-md-2">Code</label>
                    <div className="p-col-12 p-md-10">
                        <InputText className={errors.includes('slug') ? 'p-invalid' : ''} readOnly={workspaceInEdition} id="slug" type="text" value={slug}
                            onChange={(e) => {
                                setSlugHasBeenSetByUser(true);
                                setSlug(slugify(e.target.value, {
                                    lower: true,
                                    strict: true,
                                    locale: 'fr',
                                    trim: true,
                                    replacement: '_',
                                }));
                            }}
                        />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-2'} htmlFor="start_date">Date de début</label>
                    <div className="p-col-12 p-md-10">
                        <Calendar id="start_date" value={startDate} onChange={(e:any) => setStartDate(e.target.value)} dateFormat="dd/mm/yy" />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-2'} htmlFor="partenaire">Partenaire</label>
                    <div className="p-col-12 p-md-10">
                        <Dropdown value={partner} options={partnerOptions} onChange={e => {
                            setPartner(e.value);
                        }} />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-2'} htmlFor="disabled">Désactivé</label>
                    <div className={'p-col-12 p-md-10'}>
                        <Checkbox id="disabled" onChange={() => setDisabled(!disabled)} checked={disabled} />
                    </div>
                    <label className={'p-col-12 p-md-2'} htmlFor="duplicable">Duplicable</label>
                    <div className={'p-col-12 p-md-10'}>
                        <Checkbox id='duplicable' onChange={handleChangeCheckbox} checked={info.duplicable} />
                    </div>
                </div>
            </div>
        </MunityDialog>
    </>;
}

export default WorkspaceForm;
