import './style.scss';

import { Task, useDeleteTaskMutation } from "./slice";

import { Button } from 'primereact/button';
import DeleteButton from "../common/confirmationDeleteButton";
import OpenFormButton from "../common/openFormButton";
import TagText from '../common/tagtext';
import Tags from "../common/tags";
import { TaskForm } from "./form";
import UserAvatar from "../common/userAvatar";
import dayjs from 'dayjs';
import { useState } from 'react';

const TasksList = (props: { tasks: Array<Task> }) => {
    const [deleteTask] = useDeleteTaskMutation();

    const [showAll, setShowAll] = useState<Boolean>(false);

    const maxShownTask = 8;
    const _tasks = [...props.tasks];
    if (!showAll) {
        _tasks.length = maxShownTask;
    }
    const missingTasks = props.tasks.length - maxShownTask;
    return <div className={'tasks-list-wrapper'}>
        <h1 className='title'>Tâches</h1>
        <div className={'tasks-list'}>
            {_tasks?.map((task: Task) => {
                return <div key={task.id} className='task-item'>
                    <div className={`task-state task-state-${task.state}`}></div>
                    <div className='task-content'>
                        <div className='task-title title'>{task.name}</div>
                        <div className="tag-wrapper">
                            {
                                task.generic_groups.length === 1 ?
                                    <Tags key={task.generic_groups[0]} groupId={task.generic_groups[0]} />
                                    : null
                            }
                            {
                                task.generic_groups.length > 1 ?
                                    <TagText text={`${task.generic_groups.length} thèmes séléctionnés`} />
                                    : null
                            }
                        </div>
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                            {task.assignee && <UserAvatar userId={task.assignee} />}
                            <div className='description'>
                                Du {dayjs(task.start_date ? task.start_date : task.created).format('DD.MM.YYYY')} au {dayjs(task.end_date ? task.end_date : task.created).format('DD.MM.YYYY')}
                            </div>
                        </div>
                    </div>
                    <div className='relative-open-form-btn action-buttons'>
                        <OpenFormButton action="update" model="task" header={'Editer une tâche'} form={<TaskForm task={task} closeModal={() => null} step={null} />} />
                        <DeleteButton model="task" deleteFunction={() => deleteTask(task.id)} />
                    </div>
                </div>
            })}
            {
                (!showAll && missingTasks > 0) ?
                    <div>
                        <Button onClick={() => setShowAll(true)} severity="secondary" style={{ width: '100%' }}>
                            {`Afficher toutes les tâches (${missingTasks})`}
                        </Button>
                    </div> : null
            }
            {
                (showAll && missingTasks > 0) ?
                    <div>
                        <Button onClick={() => setShowAll(false)} severity="secondary" style={{ width: '100%' }}>
                            {`Réduire la liste des tâches`}
                        </Button>
                    </div> : null
            }
        </div>
    </div>
}

export default TasksList;
