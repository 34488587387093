import { FunctionComponent, useEffect, useState } from "react";
import { Role, useGetRolesQuery } from "../permissions/slice";
import { User, UserFunctionWorkspace, UserRoleWorkspace, invitation } from "./slice";
import { UserFunction, useGetUserFunctionsQuery } from "../user_functions/slice";
import { UserRole, useGetUserRolesQuery } from "../user_roles/slice";

import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import MunityDialog from '../layouts/components/MunityDialog';
import { SelectButton } from "primereact/selectbutton";
import { addNotification } from "../notifications/slice";
import { useAppSelector } from "../hooks";
import { useDispatch } from "react-redux";
import { useGetPartnersQuery } from "../partners/slice";

const InviteForm: FunctionComponent<{ mode: "overmind" | "workspace", show: boolean, onClose: Function }> = props => {
    const dispatch = useDispatch();
    const [firstname, setFirstname] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const [userFunction, setUserFunction] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [errorField, setErrorField] = useState<string[]>([]);
    const [username, setUsername] = useState<string>("");
    const [partner, setPartner] = useState<string | null>(null);
    const [userRoleWorkspaces, setUserRoleWorkspaces] = useState<UserRoleWorkspace[]>([]);
    const [userFunctionWorkspaces, setUserFunctionWorkspaces] = useState<UserFunctionWorkspace[]>([]);
    const currentUser: User = useAppSelector(state => state.authentication.currentUser)

    const currentWorkspace = useAppSelector(state => state.app.workspace_slug);

    // get workspaces on overmind
    const { data: user_functions } = useGetUserFunctionsQuery();
    const { data: partners } = useGetPartnersQuery();
    const { data: user_roles } = useGetUserRolesQuery();
    const { data: roles } = useGetRolesQuery();

    const validateEmail = (email:string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const inviteUser = () => {
        setErrorField([]);
        if (!validateEmail(username)){
            setErrorField(['username']);
            dispatch(addNotification({
                type: 'error',
                message: 'L’adresse mail est obligatoire',
            }));
            return;
        }
        if (firstname.length === 0) {
            setErrorField(['firstname']);
            dispatch(addNotification({
                type: 'error',
                message: 'Le prénom est obligatoire.',
            }));
            return;
        }
        if (lastname.length === 0) {
            setErrorField(['lastname']);
            dispatch(addNotification({
                type: 'error',
                message: 'Le nom est obligatoire.',
            }));
            return;
        }
        if (props.mode === "overmind" && !partner){
            setErrorField(['partner']);
            dispatch(addNotification({
                type: 'error',
                message: 'Le partenaire est obligatoire',
            }));
            return;
        }
        if (currentWorkspace && userRoleWorkspaces.length === 0){
            setErrorField(['right']);
            dispatch(addNotification({
                type: 'error',
                message: 'La sélection des droits est obligatoire',
            }));
            return;
        }
        dispatch(invitation({
            email: username,
            firstname: firstname || null,
            lastname: lastname || null,
            phone: phone || null,
            partner: partner || null,
            workspace_slug: currentWorkspace || null,
            role: userRoleWorkspaces.length > 0 ? userRoleWorkspaces[0].role : null,
            function_label: userFunction,
            function: userFunctionWorkspaces.length > 0 ? userFunctionWorkspaces[0].function : null,
        }));
    };

    const userRoles = user_roles?.results.map((f: UserRole) => {
        return {
            value: f.id,
            label: f.label
        };
    }) || [];
    userRoles.unshift({ value: '', label: 'Aucun role' });

    const userFunctions: {
        value: null | string;
        label: string;
    }[] = [{
        value: null,
        label: '-'
    }]
    user_functions?.results.forEach((f: UserFunction) => {
        userFunctions.push({
            value: f.id,
            label: f.label
        });
    });

    const partnerOptions: [{ [key: string]: string | undefined }] = [{
        value: '',
        label: ' - '
    }];
    partners?.results.forEach(p => {
        partnerOptions.push({
            value: p.id,
            label: p.name
        });
    });

    const partnerName = partners?.results.find(p => p.id === partner)?.name;

    useEffect(() => {
        if (props.show) {
            setFirstname('');
            setLastname('');
            setUserFunction('');
            setPhone('');
            setErrorField([]);
            setUsername('');
            setPartner('');
            setUserRoleWorkspaces([]);
            setUserFunctionWorkspaces([]);
            if (currentUser.is_superuser || props.mode === "workspace") {
                setPartner(null);
            } else {
                setPartner(currentUser.partner)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    return <>
        <MunityDialog saveLabel="Inviter" canChange={true} title={'Inviter un utilisateur'} visible={props.show} onSave={inviteUser} onHide={props.onClose}>
            <div className="p-fluid">
                <div className="p-field p-grid">
                    <label htmlFor="firstname4" className="p-col-12 p-md-2">E-mail</label>
                    <div className="p-col-12 p-md-10">
                        <InputText id="username" className={errorField.includes('username') ? 'p-invalid' : ''} type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="firstname4" className="p-col-12 p-md-2">Prénom</label>
                    <div className="p-col-12 p-md-10">
                        <InputText id="firstname" className={errorField.includes('firstname') ? 'p-invalid' : ''} type="text" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="firstname4" className="p-col-12 p-md-2">Nom</label>
                    <div className="p-col-12 p-md-10">
                        <InputText id="lastname" className={errorField.includes('lastname') ? 'p-invalid' : ''} type="text" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                    </div>
                </div>
                {currentWorkspace &&
                    <div className="p-field p-grid">
                        <label className="p-col-12 p-md-2">Rôle</label>
                        <div className="p-col-12 p-md-10">
                            <Dropdown value={userFunctionWorkspaces.find((ufw: UserFunctionWorkspace) => ufw.workspace === currentWorkspace)?.function} options={userFunctions} onChange={e => {
                                let newUserFunctionWorkspaces = userFunctionWorkspaces.slice(0);
                                let newUserFunctionWorkspace: UserFunctionWorkspace = {
                                    function: e.value,
                                    workspace: currentWorkspace
                                };
                                newUserFunctionWorkspaces.push(newUserFunctionWorkspace);
                                setUserFunctionWorkspaces(newUserFunctionWorkspaces);
                            }} />
                        </div>
                    </div>
                }
                <div className="p-field p-grid">
                    <label htmlFor="" className="p-col-12 p-md-2">Fonction</label>
                    <div className="p-col-12 p-md-10">
                        <InputText id="lastname" type="text" value={userFunction} onChange={(e) => setUserFunction(e.target.value)} />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="phone" className="p-col-12 p-md-2">Téléphone</label>
                    <div className="p-col-12 p-md-10">
                        <InputText id="phone" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                </div>
                {props.mode === "overmind" &&
                    <div className="p-field p-grid">
                        <label className="p-col-12 p-md-2">Partenaire</label>
                        <div className="p-col-12 p-md-10">
                            {currentUser.is_superuser ?
                                <Dropdown className={errorField.includes('partner') ? 'p-invalid' : ''} value={partner} options={partnerOptions} onChange={e => {
                                    setPartner(e.value);
                                }} />
                                :
                                <InputText disabled id="partner" value={partnerName} />
                            }
                        </div>
                    </div>
                }
                {currentWorkspace &&
                    <div className="p-field p-grid" style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                        <label className="p-col-12 p-md-2">Droit</label>
                        <div className="p-col-12 p-md-10">
                            <SelectButton className={errorField.includes('right') ? 'p-invalid' : ''}  value={userRoleWorkspaces.find((urw: UserRoleWorkspace) => {
                                return urw.workspace === currentWorkspace
                            })?.role} options={roles?.results.map((r: Role) => {
                                return {
                                    name: r.name,
                                    value: r.id
                                };
                            })} itemTemplate={role => <div key={role.id}>{role.name}</div>} onChange={(e) => {
                                let newUserRoleWorkspaces = userRoleWorkspaces.slice(0);
                                let newUserRoleWorkspace = Object.assign({}, newUserRoleWorkspaces[0]);
                                newUserRoleWorkspace.role = e.value;
                                newUserRoleWorkspace.workspace = currentWorkspace;
                                newUserRoleWorkspaces[0] = newUserRoleWorkspace;
                                setUserRoleWorkspaces(newUserRoleWorkspaces);
                            }} />
                        </div>
                    </div>
                }
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row-reverse",
                marginTop: "44px"
            }}>
            </div>
        </MunityDialog >
    </>;
}

export default InviteForm;

