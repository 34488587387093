import { DocumentType, useCreateDocumentTypeMutation, useDeleteDocumentTypeMutation, useUpdateDocumentTypeMutation } from "./typesSlice";
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Theme, useGetThemesQuery } from "../themes/slice";
import { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { addNotification } from "../notifications/slice";
import { useAppDispatch } from "../hooks";

const initialDocumentTypeState = {
    id: "",
    name: "",
    description: "",
    is_ged: false,
    step: "",
    visual: "",
    generic_groups: []
};

export const DocumentTypeForm = (props: { type: DocumentType | null, closeModal: Function, step: string | null, isGed: boolean }) => {
    const dispatch = useAppDispatch();

    // const [uploadedVisual, setUploadedVisual] = useState<File | undefined>(undefined);
    const [type, setDocumentType] = useState<Pick<DocumentType, 'id' | 'name' | 'generic_groups' | 'step' | 'description' | 'is_ged' | 'visual'>>(initialDocumentTypeState);
    const [createDocumentType, { isError: createError, isSuccess: createSuccess }] = useCreateDocumentTypeMutation();
    const [updateDocumentType, { isError: updateError, isSuccess: updateSuccess }] = useUpdateDocumentTypeMutation();
    const [, {isError: deleteError, isSuccess: deleteSuccess }] = useDeleteDocumentTypeMutation();
    const { data: themes } = useGetThemesQuery();

    const handleChange = ({ target }: any | MultiSelectChangeEvent | React.ChangeEvent<HTMLInputElement>) => {
        setDocumentType((prev) => ({
            ...prev,
            [target.id]: target.value,
        }));
    }

    useEffect(() => {
        setDocumentType(props.type || initialDocumentTypeState);
        setDocumentType((prev) => ({
            ...prev,
            is_ged: props.isGed,
        }))
        if (props.step !== null) {
            setDocumentType((prev) => ({
                ...prev,
                step: props.step ? props.step : '',
            }))
        }
    }, [props.type, props.step, props.isGed])

    // Update alert
    useEffect(() => {
        if (updateError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de mettre à jour la zone de téléchargement'
            }));
        }
    }, [updateError, dispatch]);

    useEffect(() => {
        if (updateSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Zone de téléchargement mise à jour'
            }));
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateSuccess]);

    // Delete alert
    useEffect(() => {
        if (deleteError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de supprimer la zone de téléchargement'
            }));
        }
    }, [deleteError, dispatch]);

    useEffect(() => {
        if (deleteSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Zone de téléchargement supprimée'
            }));
            setDocumentType(initialDocumentTypeState);
        }
    }, [deleteSuccess, dispatch]);

    // Create alert
    useEffect(() => {
        if (createSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Zone de téléchargement créée'
            }));
            props.closeModal();
        }
    }, [createSuccess, dispatch, props]);

    useEffect(() => {
        if (createError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de créer la zone de téléchargement'
            }));
        }
    }, [createError, dispatch]);


    return <div>
        <div className="p-fluid">
            {/* <div className="p-d-flex p-jc-center p-m-2 p-flex-column p-ai-center p-jc-center">
                <div style={{ display:'flex',justifyContent:'center',width:'100%',marginBottom: '4px' }}>
                    {uploadedVisual ?
                        <img width="50%" alt="visual" src={uploadedVisual.file} /> :
                        null
                    }
                    {!uploadedVisual && type.visual && (typeof type.visual !== "string") ?
                        <img style={{ width: "50%" }} alt="visual" src={getURLForFile(type.visual.file)} /> :
                        null
                    }
                </div>
                <SimpleUploader
                    maxFileSize={100000000}
                    onUpload={(e: FileUploadUploadParams) => {
                        const _visual = JSON.parse(e.xhr.response);
                        setUploadedVisual(_visual);
                        setDocumentType((prev) => ({
                            ...prev,
                            visual: _visual.id,
                        }));
                    }}
                    label={"Changer d'illustration"}
                    auto
                    accept="image/*"
                />
            </div> */}
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="name">Nom</label>
                <div className={'p-col-12 p-md-10'}>
                    <InputText id="name" onChange={handleChange} value={type.name} /><br />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="description">Description</label>
                <div className={'p-col-12 p-md-10'}>
                    <InputTextarea rows={10} id="description" onChange={handleChange} value={type.description} /><br />
                </div>
            </div>
            <div className="p-field p-grid">
                <label className={'p-col-12 p-md-2'} htmlFor="generic_groups">Thèmes</label>
                <div className={'p-col-12 p-md-10'}>
                    <MultiSelect selectionLimit={3} selectedItemsLabel="{0} thèmes séléctionnés" maxSelectedLabels={1} id="generic_groups" value={type.generic_groups} options={themes?.results == null ? [] : themes?.results.map((tag: Theme) => ({ label: tag.label, value: tag.id }))} onChange={handleChange} placeholder="Sélectionner le ou les thème(s)" /><br />
                </div>
            </div>
        </div>
        <Button onClick={props.type ? () => {
            const _toSave = { ...type };
            if (typeof _toSave.visual !== "string") {
                _toSave.visual = undefined;
            }
            updateDocumentType(_toSave)
        } : () => createDocumentType(type)} >{'Sauvegarder'} </Button>
    </div>;
}
