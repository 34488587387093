import { Workspace } from '../workspaces/slice';
import { createSlice } from '@reduxjs/toolkit';
import { sliceFactory } from '../factory/slice';
import { File } from '../files/slice';

export interface DocumentType {
    created: Date,
    weight: number,
    step: string,
    name: string,
    description: string,
    id: string,
    modified: Date,
    sent: boolean,
    disabled: boolean,
    hidden: boolean,
    is_ged: boolean,
    generic_groups: string[],
    visual: File | string | undefined,
    workspace: Workspace | null
}

export interface DocumentTypeState {
    documentTypesInEdition: DocumentType | null
}

export const initialState: DocumentTypeState = {
    documentTypesInEdition: null
}

export const documentTypesSlice = createSlice({
    name: 'documentTypes',
    initialState,
    reducers: {
        setDocumentTypeInEdition: (state, payload:{payload:DocumentType|null}) => {
            state.documentTypesInEdition = payload.payload;
        }
    }
});

export const documentTypesAPISlice = sliceFactory<DocumentType>({
    reducerName: 'documentTypesAPI',
    endpoint: '/document-types/',
    name: 'DocumentType'
});


export default documentTypesSlice.reducer;
export const { setDocumentTypeInEdition } = documentTypesSlice.actions

export const {
    useGetDocumentTypesQuery,
    useGetDocumentTypeQuery,
    useDeleteDocumentTypeMutation,
    useUpdateDocumentTypeMutation,
    useCreateDocumentTypeMutation,
} = documentTypesAPISlice