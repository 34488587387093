import { Phase, useCreatePhaseMutation, useDeletePhaseMutation, useUpdatePhaseMutation } from "./slice";
import { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ToggleButtonChangeEvent } from 'primereact/togglebutton';
import { addNotification } from "../notifications/slice";
import { useAppDispatch } from "../hooks";

const initialPhaseState = {
    id: "",
    name: "",
    description: "",
    weight: 0,
    color: "",
};

export const PhaseForm = (props: { phase: Phase | null, closeModal: Function}) => {
    const dispatch = useAppDispatch();

    const [phase, setPhase] = useState<Pick<Phase, 'id' | 'name' | 'description' | 'color' | 'weight' >>(initialPhaseState);
    const [createPhase, { isError: createError, isSuccess: createSuccess }] = useCreatePhaseMutation();
    const [updatePhase, { isError: updateError, isSuccess: updateSuccess }] = useUpdatePhaseMutation();
    const [, { isError: deleteError, isSuccess: deleteSuccess }] = useDeletePhaseMutation();

    // const colors = ['#8CD483', '#6578DA', '#1DAEB0'];

    const handleChange = ({ target }: ToggleButtonChangeEvent | React.ChangeEvent<HTMLInputElement>) => {
        setPhase((prev) => ({
            ...prev,
            [target.id]: target.value,
        }));
    }

    const handleCreate = () => {
        createPhase(phase);
    }

    useEffect(() => {
        setPhase(props.phase || initialPhaseState);
    }, [props.phase])

    // Update alert
    useEffect(() => {
        if (updateError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de mettre à jour la phase'
            }));
        }
    }, [updateError, dispatch]);

    useEffect(() => {
        if (updateSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Phase mise à jour'
            }));
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateSuccess, dispatch]);

    // Delete alert
    useEffect(() => {
        if (deleteError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de supprimer la phase'
            }));
        }
    }, [deleteError, dispatch]);

    useEffect(() => {
        if (deleteSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Phase effacée'
            }));
            setPhase(initialPhaseState);
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteSuccess, dispatch]);

    // Create alert
    useEffect(() => {
        if (createSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Phase créée'
            }));
            props.closeModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createSuccess, dispatch]);

    useEffect(() => {
        if (createError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Impossible de créer la phase'
            }));
        }
    }, [createError, dispatch]);


    return <div>
            <div className="p-fluid">
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-3'} htmlFor="name">Nom</label>
                    <div className={'p-col-12 p-md-9'}>
                        <InputText id="name" onChange={handleChange} value={phase.name} /><br />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label className={'p-col-12 p-md-3'} htmlFor="description">Description</label>
                    <div className={'p-col-12 p-md-9'}>
                        <InputText id="description" onChange={handleChange} value={phase.description} /><br />
                    </div>
                </div>
            </div>
            <Button onClick={props.phase? () => updatePhase(phase) : () => handleCreate()} >Sauvegarder</Button>
    </div>;
}
