import './style.scss';

import { authenticate, validInvitation } from './slice';
import { notReady, ready } from '../app/slice';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { addNotification } from '../notifications/slice';
import { useAppDispatch } from '../hooks';

const ResetPasswordForm: React.FC<{}> = props => {
    const history = useHistory();
    const location = useLocation();
    const [error, setError] = useState<boolean>(false);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const dispatch = useAppDispatch();

    const acceptInvitation = () => {
        setError(false);
        if (confirmPassword !== password) {
            dispatch(addNotification({
                type: 'error',
                message: 'Les mots de passe sont différents'
            }));
            return;
        }
        if (password.length < 6) {
            dispatch(addNotification({
                type: 'error',
                message: ' Le mot de passe doit contenir au moins 6 caractères',
            }));
            return;
        }
        dispatch(validInvitation({
            token: token,
            password: password
        })).then((data: any) => {
            setTimeout(() => {
                dispatch(ready());
                if (!data.error) {
                    const email = data.payload.data.email;
                    dispatch(authenticate({
                        username: email,
                        password: password
                    }));
                }
            }, 2000);
        })
    }

    useEffect(() => {
        // Get the URLSearchParams object containing all the URL parameters
        const searchParams = new URLSearchParams(location.search);

        // Get the value of the 'token' parameter from the URL
        const urlToken = searchParams.get('token');

        // If the 'token' parameter exists in the URL, set it using useState
        if (urlToken) {
            setToken(urlToken);
            // Perform any actions you need to with the token here
            const payload = urlToken.split('.')[1];

            // Decode the payload
            const decodedPayload = JSON.parse(atob(payload));
            setEmail(decodedPayload.email);
        }
    }, [location.search]);

    return <form className="login-form" onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        acceptInvitation();
    }}>
        <div className="reset-password-form-wrapper">
            <div className="welcome">{'Création du compte'}</div>
            <div className="welcome-text">Veuillez renseigner votre mot de passe ci-dessous</div>
            <div className="p-field" style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                {email}
            </div>
            <div className="p-field">
                <label htmlFor="password" className="p-d-block">Mot de passe</label>
                <Password className={error ? 'p-invalid' : ''} feedback={false} autoComplete="password" id="password" value={password} onChange={(e) => { setError(false); setPassword(e.target.value) }} />
            </div>
            <div className="p-field">
                <label htmlFor="password" className="p-d-block">Confiramtion du mot de passe</label>
                <Password className={error ? 'p-invalid' : ''} feedback={false} autoComplete="password" id="confirm_password" value={confirmPassword} onChange={(e) => { setError(false); setConfirmPassword(e.target.value) }} />
            </div>
            <Button style={{ marginTop: '8px' }} type="submit" onSubmit={() => {}}
                icon="pi pi-arrow-right" iconPos="right" label={'Créer mon compte'}
            />
            <div style={{ marginTop: '20px' }} className="p-field">
                <Button severity='secondary' type="button" onClick={() => history.push("/")}>Annuler</Button>
            </div>
        </div>
    </form>;
}

export default ResetPasswordForm;


